import { RequestTabEnum } from '@/lib/enum/requests';
import { userCan } from '@/lib/permission/userCan';
import { Entity } from '@/lib/realtime/RealtimeEntity';
import { routes } from '@/router/routes';
import { Location } from 'vue-router';
import { Notification, NotificationTypeEnum } from '../../../api/v1';

export const NotificationRouteMap = (): {
  [key in NotificationTypeEnum]: (notification?: Notification) => Location;
} => ({
  // IMPORTANT: Make sure that all items in NotificationTypeEnum are covered...
  [NotificationTypeEnum.ClockInLate]: routes.timeClock.route,
  [NotificationTypeEnum.ClockInMissed]: routes.timeClock.route,
  [NotificationTypeEnum.ClockInPinAssigned]: (notification: Notification) =>
    routes.profileSummary.route(notification.recipientId),
  [NotificationTypeEnum.CompanyEnabledClockInPortals]: (
    notification: Notification,
  ) => routes.profileSummary.route(notification.recipientId),
  [NotificationTypeEnum.ClockInPinUpdated]: (notification: Notification) =>
    routes.profileSummary.route(notification.recipientId),
  [NotificationTypeEnum.ClockInReminder]: routes.dashboardV2.route,
  [NotificationTypeEnum.ClockOutReminder]: routes.dashboardV2.route,
  [NotificationTypeEnum.CreditsRenewed]: routes.invoices.route(),
  [NotificationTypeEnum.InvoicePaid]: routes.invoices.route,
  [NotificationTypeEnum.DocumentAssigned]: (notification: Notification) =>
    routes.myDocuments.route(notification.recipientId),
  [NotificationTypeEnum.LeaveApproved]: (notification: Notification) =>
    routes.leave.route(notification.recipientId),
  [NotificationTypeEnum.LeaveApprovedManager]: (notification: Notification) =>
    routes.leave.route(notification.associatedEmployee?.id),
  [NotificationTypeEnum.LeaveDenied]: (notification: Notification) =>
    routes.leave.route(notification.recipientId),
  [NotificationTypeEnum.LeaveRequested]: (notification: Notification) =>
    routes.leave.route(notification.associatedEmployee?.id),
  [NotificationTypeEnum.LeaveCancellationRequested]: (
    notification: Notification,
  ) => routes.leave.route(notification.associatedEmployee?.id),
  [NotificationTypeEnum.LeaveCancellationApproved]: (
    notification: Notification,
  ) => routes.leave.route(notification.recipientId),
  [NotificationTypeEnum.LeaveCancellationDenied]: (
    notification: Notification,
  ) => routes.leave.route(notification.recipientId),
  [NotificationTypeEnum.OpenShiftAutoAssigned]: (notification: Notification) =>
    routes.viewShift.route({
      id: notification.entityId,
    }),
  [NotificationTypeEnum.OpenShiftAvailable]: routes.availableOpenShifts.route,
  [NotificationTypeEnum.OpenShiftResponseRejected]: routes.schedule.route,
  [NotificationTypeEnum.OpenShiftResponseReceived]: (
    notification: Notification,
  ) => routes.assignShift.route({ id: notification.entityId }),
  [NotificationTypeEnum.ShiftAssigned]: (notification: Notification) =>
    routes.viewShift.route({ id: notification.entityId }),
  [NotificationTypeEnum.ShiftAssigneeUnavailableAssignee]: (
    notification: Notification,
  ) => routes.viewShift.route({ id: notification.entityId }),
  [NotificationTypeEnum.ShiftAssigneeUnavailableManager]: (
    notification: Notification,
  ) =>
    userCan.manageShifts()
      ? routes.reassignShift.route({ id: notification.entityId })
      : routes.viewShift.route({ id: notification.entityId }),
  [NotificationTypeEnum.ShiftNotAcknowledged]: (notification: Notification) =>
    routes.viewShift.route(notification.entityId),
  [NotificationTypeEnum.ShiftStarting]: routes.dashboardV2.route,
  [NotificationTypeEnum.ShiftSwapAccepted]: routes.schedule.route,
  [NotificationTypeEnum.ShiftSwapApproved]: routes.schedule.route,
  [NotificationTypeEnum.ShiftSwapCancelled]: routes.schedule.route,
  [NotificationTypeEnum.ShiftSwapShiftChanged]: routes.schedule.route,
  [NotificationTypeEnum.ShiftSwapDeclined]: routes.schedule.route,
  [NotificationTypeEnum.ShiftSwapRejected]: routes.schedule.route,
  [NotificationTypeEnum.ShiftSwapRequest]: (notification: Notification) =>
    routes.requests.route(RequestTabEnum.ShiftRequests, {
      entity: Entity.ShiftSwap,
      id: notification.entityId,
    }),
  [NotificationTypeEnum.ShiftSwapReviewRequired]: routes.requests.route(
    RequestTabEnum.ShiftRequests,
  ),
  [NotificationTypeEnum.ShiftUnassigned]: routes.schedule.route,
  [NotificationTypeEnum.MultipleShiftsAssigned]: routes.schedule.route,
  [NotificationTypeEnum.ShiftUpdated]: (notification: Notification) =>
    routes.viewShift.route({ id: notification.entityId }),
  [NotificationTypeEnum.AbsenceDeleted]: (notification: Notification) =>
    routes.absence.route(notification.associatedEmployee?.id),
  [NotificationTypeEnum.AbsenceReported]: (notification: Notification) =>
    routes.absence.route(notification.associatedEmployee?.id),
  [NotificationTypeEnum.AbsenceUpdated]: (notification: Notification) =>
    routes.absence.route(notification.associatedEmployee?.id),
  [NotificationTypeEnum.OvertimeWarning]: routes.timesheets.route,
  [NotificationTypeEnum.ScheduledHoursWillCauseOvertimeWarning]:
    routes.schedule.route,
  [NotificationTypeEnum.SubscriptionUpgraded]: routes.invoices.route,
  [NotificationTypeEnum.PayCyclePending]: routes.timesheets.route,
});
