import { Employee, EmployeeStatusEnum } from '../../../api/v1';

export const jobRoleFilterMultiple =
  (selectedIds: number[]) =>
  ({ jobRoleIds }: { jobRoleIds: number[] }) =>
    !selectedIds.length ||
    // Often used as an "all" id
    selectedIds.includes(0) ||
    selectedIds.some((id) => jobRoleIds.includes(id));
export const accessRoleFilter =
  (selectedIds: number[]) =>
  ({ accessRoleIds }: { accessRoleIds: number[] }) =>
    !selectedIds.length || selectedIds.some((id) => accessRoleIds.includes(id));
export const employeeStatusFilter =
  (selectedStatuses: EmployeeStatusEnum[]) =>
  ({ status }: { status: EmployeeStatusEnum }) =>
    !selectedStatuses.length || selectedStatuses.some((s) => s === status);

export const employeeGroupFilter =
  (selectedIds: number[]) =>
  (employee: Employee): boolean => {
    return (
      !selectedIds.length || selectedIds.includes(employee.employeeGroupId)
    );
  };

export const locationFilter =
  (activeLocationIds: number[], selectedId: number | null) =>
  ({ locationIds }: { locationIds: number[] | null }) =>
    !selectedId || locationIds.includes(selectedId);
