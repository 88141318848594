
import {
  ScheduledBreak,
  ScheduleSettings,
  TimesheetEntry,
  TimesheetEntryStatusEnum,
} from '@/../api/v1';
import { getTotalBreakSeconds } from '@/lib/clock-in/clockInFunctions';
import store from '@/store';
import { sortBy } from '@/util/arrayFunctions';
import { PropType } from 'vue';

export default {
  name: 'ClockInTimer',

  props: {
    timesheetEntry: {
      type: Object as PropType<TimesheetEntry | null>,
      default: null,
    },

    scheduledBreak: {
      type: Object as PropType<ScheduledBreak | null>,
      default: null,
    },

    // Boolean to only show the calculated time string without any styling
    timeOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      interval: null as number, // ms
      elapsedTimeInSeconds: 0, // seconds
    };
  },

  computed: {
    timezone: (): string => store.getters.timezone,
    scheduleSettings: (): ScheduleSettings =>
      store.state.settings.scheduleSettings,

    paidBreakName(): string {
      return this.scheduleSettings.paidBreakName ?? this.$tc('label.paidBreak');
    },
    unpaidBreakName(): string {
      return (
        this.scheduleSettings.unpaidBreakName ?? this.$tc('label.unpaidBreak')
      );
    },

    breakMinutesRemaining(): number {
      if (
        this.timesheetEntry?.status === TimesheetEntryStatusEnum.Break &&
        this.scheduledBreak
      ) {
        return (
          this.scheduledBreak.durationInMinutes -
          Math.ceil(this.elapsedTimeInSeconds / 60)
        );
      }
      return 0;
    },

    label(): string {
      if (!this.timesheetEntry) {
        // Although technically, we don't know that there is not a scheduled shift,
        // in practice, this component is only displayed in its full version when there isn't.
        return this.$tc('clockIn.noScheduledShifts');
      }

      if (this.timesheetEntry.status === TimesheetEntryStatusEnum.Break) {
        // If a scheduled break has been provided, calculate how many minutes are remaining
        if (this.scheduledBreak) {
          const breakName = this.scheduledBreak.paid
            ? this.paidBreakName
            : this.unpaidBreakName;
          const timePhrase =
            this.breakMinutesRemaining >= 0
              ? this.$tc(
                  'unitWithAmount.minutesRemaining',
                  this.breakMinutesRemaining,
                )
              : this.$tc(
                  'unitWithAmount.youHaveOverrunByMinutes',
                  this.breakMinutesRemaining * -1,
                ).toLowerCase();

          return `${breakName}: ${timePhrase}`;
        }
        return this.$tc('clockIn.onUnscheduledBreak');
      }

      if (this.timesheetEntry.status === TimesheetEntryStatusEnum.Started) {
        return '';
      }

      return '';
    },

    timer(): string {
      const secs = Math.max(0, this.elapsedTimeInSeconds);

      const hours = Math.floor(secs / 3600);
      const minutes = Math.floor((secs - hours * 3600) / 60);
      const seconds = Math.floor(secs - (hours * 3600 + minutes * 60));

      const pad = (n: number) => n.toString(10).padStart(2, '0');

      return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
    },
  },

  mounted() {
    this.initTimer();
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },

  methods: {
    initTimer() {
      this.interval = setInterval(this.updateTimer, 100);
    },

    updateTimer(): void {
      const now = new Date();

      this.elapsedTimeInSeconds = ((): number => {
        if (
          !this.timesheetEntry ||
          this.timesheetEntry.status === TimesheetEntryStatusEnum.Completed
        ) {
          return 0;
        }

        if (this.timesheetEntry.status === TimesheetEntryStatusEnum.Break) {
          const { timesheetBreaks } = this.timesheetEntry;
          const latestBreak = sortBy(timesheetBreaks, '-startedAt')[0];
          const start = latestBreak.startedAt;
          return (now.getTime() - start.getTime()) / 1000;
        }

        const start = this.timesheetEntry.startedAt;
        const durationInSeconds = (now.getTime() - start.getTime()) / 1000;

        // Deduct any breaks
        const totalBreakInSeconds = getTotalBreakSeconds(
          this.timesheetEntry.timesheetBreaks,
          this.timezone,
        );

        return durationInSeconds - totalBreakInSeconds;
      })();
    },
  },
};
