import i18n from '@/i18n';
import {
  isNavSectionOpen,
  NavSectionInterface,
  toSectionMap,
} from '@/lib/navigation/helpers';
import { navItems } from '@/lib/navigation/navItems';
import { userCan } from '@/lib/permission/userCan';
import { Icon } from '../enum/Icon';

export const reportsNav = toSectionMap({
  shiftCost: (): NavSectionInterface => ({
    name: i18n.tc('nav.reports.menu.hoursAndCosts'),
    icon: Icon.CalendarWeek,
    open: isNavSectionOpen(reportsNav, 'shiftCost'),
    hide: !userCan.viewShiftCostReports(),
    children: [
      navItems.shiftDailyTotals(),
      navItems.shiftTotalsByEmployee(),
      navItems.shiftTotalsByJobRole(),
      navItems.shiftTotalsByLocation(),
      navItems.shiftTotalsByGroup(),
      navItems.shiftTotalsByEmploymentType(),
    ],
  }),
  logs: (): NavSectionInterface => ({
    name: i18n.tc('nav.reports.menu.logs'),
    icon: Icon.ClipboardList,
    open: isNavSectionOpen(reportsNav, 'logs'),
    hide: !userCan.viewMessageLogs(), // might be changed if 'children' array would have more items
    children: [navItems.messageHistory()],
  }),
});
