<template>
  <div
    class="all:bg-white lg:bg-inherit all:p-0 all:pl-4 all:pb-4 lg:px-6 lg:pt-16"
  >
    <Search
      v-if="viewport.lg"
      :value="query"
      :placeholder="$tc('placeholder.searchSettings')"
      background-colour="#fff"
      class="mb-4"
      @submit="query = $event"
    />
    <MobileHeaderInner v-else>
      <template #mobileHeaderAppend>
        <MobileHeaderSearch
          :placeholder="$tc('placeholder.searchSettings')"
          :query="query"
          @queryChange="query = $event"
        />
      </template>
    </MobileHeaderInner>
    <CollapsibleNav
      :nav="nav"
      :query="query"
    />
  </div>
</template>

<script>
import MobileHeaderInner from '@/components/mobile/MobileHeaderInner.vue';
import MobileHeaderSearch from '@/components/MobileHeaderSearch.vue';
import CollapsibleNav from '@/components/nav/common/CollapsibleNav.vue';
import Search from '@/components/search/Search.vue';
import { Icon } from '@/lib/enum/Icon';
import { StorageEnum } from '@/lib/enum/StorageEnum';
import { settingsNav } from '@/lib/navigation/settingsNav';
import { userCan } from '@/lib/permission/userCan';
import { Entity } from '@/lib/realtime/RealtimeEntity';
import { singleEntityRealtimeQuery } from '@/lib/realtime/weak/realtimeFunctions';
import store from '@/store';
import { setLocalStorageItem } from '@/util/storageFunctions';
import { viewport } from '@/util/windowFunctions';

export default {
  name: 'SettingsNav',

  components: { MobileHeaderInner, MobileHeaderSearch, Search, CollapsibleNav },

  beforeRouteEnter(to, from, next) {
    setLocalStorageItem(StorageEnum.FromRoute, from.name);
    next();
  },

  data() {
    return {
      Icon,
      viewport,
      featuresEnabled: singleEntityRealtimeQuery(Entity.FeaturesEnabled),
      billingDetails: null,
      query: '',
    };
  },

  computed: {
    currentSubscription: () => store.state.billing.currentSubscription,
    isTrialVersion: () => store.getters['billing/isTrialVersion'],
    currentCompany: () => store.getters.currentCompany,

    nav() {
      return [
        settingsNav.general(this.featuresEnabled.data?.smsEnabled || false),
        settingsNav.scheduling(),
        settingsNav.team(),
        settingsNav.timeOff(),
        settingsNav.timeAndAttendance(),
        settingsNav.integrations(),
        settingsNav.billing(
          this.currentSubscription,
          this.isTrialVersion,
          this.billingDetails,
        ),
      ];
    },
  },

  async mounted() {
    if (userCan.manageBilling()) {
      const billingDetailsQuery = singleEntityRealtimeQuery(
        Entity.BillingDetail,
      );
      await billingDetailsQuery.promise;
      this.billingDetails = billingDetailsQuery.data;
    }
  },
};
</script>
