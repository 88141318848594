/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveAllowanceChunk } from './LeaveAllowanceChunk';
import {
    LeaveAllowanceChunkFromJSON,
    LeaveAllowanceChunkFromJSONTyped,
    LeaveAllowanceChunkToJSON,
    LeaveAllowanceChunkToJSONTyped,
} from './LeaveAllowanceChunk';

/**
 * Various usage calculations for a potential leave request
 * @export
 * @interface LeaveRequestUsageCalculation
 */
export interface LeaveRequestUsageCalculation {
    /**
     * 
     * @type {number}
     * @memberof LeaveRequestUsageCalculation
     */
    employeeId: number;
    /**
     * The total number of days this leave request would cover.
     * @type {number}
     * @memberof LeaveRequestUsageCalculation
     */
    totalDurationInDays: number;
    /**
     * The total number of hours this leave request would cover.
     * @type {number}
     * @memberof LeaveRequestUsageCalculation
     */
    totalDurationInHours: number;
    /**
     * The number of days that would be taken from the employee's leave balance.
     * @type {number}
     * @memberof LeaveRequestUsageCalculation
     */
    leaveAllowanceUsedInDays: number;
    /**
     * The number of hours that would be taken from the employee's leave balance.
     * @type {number}
     * @memberof LeaveRequestUsageCalculation
     */
    leaveAllowanceUsedInHours: number;
    /**
     * 
     * @type {Array<LeaveAllowanceChunk>}
     * @memberof LeaveRequestUsageCalculation
     */
    leaveAllowanceChunks: Array<LeaveAllowanceChunk>;
}

/**
 * Check if a given object implements the LeaveRequestUsageCalculation interface.
 */
export function instanceOfLeaveRequestUsageCalculation(value: object): value is LeaveRequestUsageCalculation {
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('totalDurationInDays' in value) || value['totalDurationInDays'] === undefined) return false;
    if (!('totalDurationInHours' in value) || value['totalDurationInHours'] === undefined) return false;
    if (!('leaveAllowanceUsedInDays' in value) || value['leaveAllowanceUsedInDays'] === undefined) return false;
    if (!('leaveAllowanceUsedInHours' in value) || value['leaveAllowanceUsedInHours'] === undefined) return false;
    if (!('leaveAllowanceChunks' in value) || value['leaveAllowanceChunks'] === undefined) return false;
    return true;
}

export function LeaveRequestUsageCalculationFromJSON(json: any): LeaveRequestUsageCalculation {
    return LeaveRequestUsageCalculationFromJSONTyped(json, false);
}

export function LeaveRequestUsageCalculationFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaveRequestUsageCalculation {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'totalDurationInDays': json['totalDurationInDays'],
        'totalDurationInHours': json['totalDurationInHours'],
        'leaveAllowanceUsedInDays': json['leaveAllowanceUsedInDays'],
        'leaveAllowanceUsedInHours': json['leaveAllowanceUsedInHours'],
        'leaveAllowanceChunks': ((json['leaveAllowanceChunks'] as Array<any>).map(LeaveAllowanceChunkFromJSON)),
    };
}

  export function LeaveRequestUsageCalculationToJSON(json: any): LeaveRequestUsageCalculation {
      return LeaveRequestUsageCalculationToJSONTyped(json, false);
  }

  export function LeaveRequestUsageCalculationToJSONTyped(value?: LeaveRequestUsageCalculation | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'employeeId': value['employeeId'],
        'totalDurationInDays': value['totalDurationInDays'],
        'totalDurationInHours': value['totalDurationInHours'],
        'leaveAllowanceUsedInDays': value['leaveAllowanceUsedInDays'],
        'leaveAllowanceUsedInHours': value['leaveAllowanceUsedInHours'],
        'leaveAllowanceChunks': ((value['leaveAllowanceChunks'] as Array<any>).map(LeaveAllowanceChunkToJSON)),
    };
}

