import { PlainDate } from '@/lib/date-time/PlainDate';
import {
  GetFieldType,
  NestedKeyOf,
  TypeMatchingKeys,
} from '@/util/objectFunctions';

type Model = object;

type Flat<T> = T extends Array<infer U> ? U : T;
export type Flatten<T> = {
  [K in keyof T]: Flat<T[K]>;
};

type NestedKeyedObject<T extends object> = {
  [K in NestedKeyOf<Flatten<T>>]: GetFieldType<Flatten<T>, K>;
};

type Prop<T> = keyof T;
type StringProp<T> = Prop<T> & string;

type Operator =
  | 'eq'
  | 'neq'
  | 'lt'
  | 'lte'
  | 'gt'
  | 'gte'
  | 'in'
  | 'notIn'
  | 'isNull'
  | 'contains'
  | 'startsWith'
  | 'endsWith';
type Value<T, P extends Prop<T>> = T[P] | ReadonlyArray<T[P]>;

type PropOfType<E extends Model, T> = TypeMatchingKeys<E, T>;

type TypedSortProp<E extends Model, T> =
  | (TypeMatchingKeys<E, T> & string)
  | `-${TypeMatchingKeys<E, T> & string}`;

type SortProp<E extends Model> = StringProp<E> | `-${StringProp<E>}`;

export class ConditionBuilder<T extends Model> {
  #conditions = {} as Record<keyof T, {}>;

  #orderOptions = [] as SortProp<T>[];

  static #getPossibleKeys(prop: string): string[] {
    const cleanValue = prop.replace(/^-/, '');
    return [cleanValue, `-${cleanValue}`];
  }

  #addSort(prop: SortProp<T>): this {
    const keys = ConditionBuilder.#getPossibleKeys(prop);
    this.#orderOptions = this.#orderOptions.filter((v) => !keys.includes(v));
    this.#orderOptions.push(prop);
    return this;
  }

  #addCondition(prop: keyof T, operator: Operator, value: any): this {
    // Handle serialisation of common value types
    if (value instanceof Date) {
      value = value.toISOString();
    } else if (value instanceof PlainDate) {
      value = value.toString();
    }
    this.#conditions = {
      ...this.conditions,
      [prop]: {
        ...this.conditions[prop],
        [operator]: value,
      },
    };
    return this;
  }

  where<P extends Prop<T>, V extends Value<T, P>>(
    prop: P,
    operator: Operator,
    value: V,
  ): this {
    return this.#addCondition(prop, operator, value);
  }

  /**
   * Only works with relations directly on the entity model
   * e.g. tags.id will be converted to tagIds
   * then checked if it exists on the entity
   */
  whereRelation<OB extends NestedKeyedObject<T>, K extends keyof OB>(
    prop: K,
    operator: Operator,
    value: Value<OB, K>,
  ): this {
    return this.#addCondition(prop as keyof T & never, operator, value);
  }

  whereIn<P extends Prop<T>, V extends Value<T, P>[]>(prop: P, value: V): this {
    return this.#addCondition(prop, 'in', value);
  }

  whereNotIn<P extends Prop<T>, V extends Value<T, P>[]>(
    prop: P,
    value: V,
  ): this {
    return this.#addCondition(prop, 'notIn', value);
  }

  whereNull(prop: PropOfType<T, null>): this {
    return this.#addCondition(prop, 'isNull', true);
  }

  whereNotNull(prop: PropOfType<T, null>): this {
    return this.#addCondition(prop, 'isNull', false);
  }

  whereDateInPeriod(
    prop: PropOfType<T, PlainDate>,
    start: PlainDate,
    end: PlainDate,
  ): this {
    return this.#addCondition(prop, 'gte', start.toString()).#addCondition(
      prop,
      'lte',
      end.toString(),
    );
  }

  whereDateTimeInPeriod(
    prop: PropOfType<T, Date>,
    start: Date,
    end: Date,
  ): this {
    return this.#addCondition(prop, 'gte', start.toISOString()).#addCondition(
      prop,
      'lt',
      end.toISOString(),
    );
  }

  whereDatesOverlap(
    startProp: PropOfType<T, PlainDate>,
    endProp: PropOfType<T, PlainDate>,
    start: PlainDate,
    end: PlainDate,
  ): this {
    return this.#addCondition(startProp, 'lte', end.toString()).#addCondition(
      endProp,
      'gte',
      start.toString(),
    );
  }

  whereDatesTimesOverlap(
    startProp: PropOfType<T, Date>,
    endProp: PropOfType<T, Date>,
    start: Date,
    end: Date,
  ): this {
    return this.#addCondition(startProp, 'lt', end.toISOString()).#addCondition(
      endProp,
      'gt',
      start.toISOString(),
    );
  }

  // Shortcuts
  whereActive(): this {
    return this.#addCondition('status' as keyof T, 'eq', 'Active');
  }

  whereNotDeleted(): this {
    return this.#addCondition('status' as keyof T, 'neq', 'Deleted');
  }

  orderBy<V extends TypedSortProp<T, string | number | Date>>(field: V): this {
    return this.#addSort(field);
  }

  get conditions() {
    return this.#conditions;
  }

  get orderOptions(): SortProp<T>[] {
    return this.#orderOptions;
  }
}
