import i18n from '@/i18n';
import { links } from '@/lang/urlLinks';
import { EventsEnum } from '@/lib/enum/events';
import { Icon } from '@/lib/enum/Icon';
import { NavItemInterface, toTypedMap } from '@/lib/navigation/helpers';
import {
  companyCanAccess,
  Feature,
} from '@/lib/permission/companyAccessFeatures';
import { userCan } from '@/lib/permission/userCan';
import { openFreshdeskWidget } from '@/plugins/freshdesk';
import { goToLink } from '@/router/router';
import { routes } from '@/router/routes';
import { Permission, userHasPermission } from '@/util/permissionFunctions';
import { limitStat } from '@/util/presenters';
import { viewport } from '@/util/windowFunctions';
import {
  BillingDetails,
  BillingPlan,
  BillingSubscription,
  Employee,
} from '../../../api/v1';
import store from '../../store';

const attachRouteMeta = <T extends NavItemInterface>(navItem: T): T => {
  const routeItem = routes[navItem.id];
  if (!routeItem || !navItem.to) {
    return navItem;
  }
  return {
    ...navItem,
    featureFlag: routeItem.meta?.featureFlag,
    feature: routeItem.meta?.featureName,
  };
};

// Define each navItem
export const navItems = toTypedMap({
  dashboardV2: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.dashboardV2.name,
      label: viewport.lg ? i18n.tc('nav.dashboard') : i18n.tc('nav.home'),
      icon: viewport.lg ? Icon.Gauge : Icon.House,
      to: routes.dashboardV2.route(),
      enabled: true,
    }),
  scheduleV2: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.schedule.name,
      label: i18n.tc('nav.schedule'),
      icon: Icon.CalendarWeek,
      to: routes.schedule.route(),
      enabled: companyCanAccess(Feature.Scheduler),
    }),
  timeClock: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.timeClock.name,
      label: i18n.tc('nav.timeClock'),
      icon: viewport.lg ? Icon.StopWatch : Icon.Clock,
      to: routes.timeClock.route(),
      // Show for those without timeclock as this will show the feature dialog instead
      // Show for employees who can see other employees working
      enabled:
        !companyCanAccess(Feature.ClockInOut) || userCan.seeWhoElseIsWorking(),
    }),
  requests: (total: number): NavItemInterface =>
    attachRouteMeta({
      id: routes.requests.name,
      label: i18n.tc('nav.requests'),
      icon: Icon.Inbox,
      to: routes.requests.route(),
      enabled: userCan.viewRequests(),
      stat: limitStat(total, 9),
    }),
  timesheets: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.timesheets.name,
      label: i18n.tc('nav.timesheets'),
      icon: Icon.ClipboardListCheck,
      to: routes.timesheets.route(),
      enabled: true,
    }),
  team: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.team.name,
      label: i18n.tc('nav.team'),
      icon: Icon.Users,
      to: routes.team.route(),
      enabled: true,
    }),
  myProfile: (employeeId: number): NavItemInterface =>
    attachRouteMeta({
      id: routes.profileSummary.name,
      label: i18n.tc('route.title.profileSummary'),
      to: routes.profileSummary.route(employeeId),
      icon: Icon.IdCard,
      enabled: true,
    }),
  updatePassword: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.updatePassword.name,
      label: i18n.tc('nav.updatePassword'),
      icon: Icon.Key,
      to: routes.updatePassword.route(),
      enabled: true,
    }),
  developerSettings: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.developerSettings.name,
      label: i18n.tc('route.title.developerSettings'),
      icon: Icon.Code,
      to: routes.developerSettings.route(),
      enabled: true,
    }),
  settingsNav: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.settingsNav.name,
      label: i18n.tc('nav.settings.companySettings'),
      icon: Icon.Gear,
      to: routes.settingsNav.route(),
      enabled: userCan.viewSettings(),
    }),
  companySettings: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.generalSettings.name,
      label: i18n.tc('nav.settings.companySettings'),
      icon: Icon.Gear,
      to: routes.generalSettings.route(),
      enabled: userCan.viewSettings(),
    }),
  accountDetails: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.updateAccountDetails.name,
      label: i18n.tc('subHeading.accountDetails'),
      icon: Icon.User,
      to: routes.updateAccountDetails.route(),
      enabled: true,
    }),
  notificationPreferences: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.notificationPreferences.name,
      label: i18n.tc('subHeading.notificationPreferences'),
      icon: Icon.Bell,
      to: routes.notificationPreferences.route(),
      enabled: true,
    }),
  clockingProfile: (
    employee: Employee,
    employeeHasLocationsWithClockingViaPortal: boolean,
  ): NavItemInterface =>
    attachRouteMeta({
      id: routes.clockingProfile.name,
      label: i18n.tc('subHeading.clockingIn'),
      icon: Icon.Mobile,
      to: routes.clockingProfile.route(),
      enabled:
        userCan.viewEmployeeClockingProfile(employee) &&
        employeeHasLocationsWithClockingViaPortal,
    }),
  choosePlan: (isTrialVersion, root): NavItemInterface =>
    attachRouteMeta({
      id: 'choosePlan',
      label: i18n.tc('button.choosePlan'),
      icon: Icon.Star,
      action: () => root.$emit(EventsEnum.TogglePackageDialog),
      enabled: userCan.manageBilling() && isTrialVersion,
    }),
  referAFriend: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.referAFriend.name,
      label: i18n.tc('subHeading.referAFriend'),
      icon: Icon.Heart,
      to: routes.referAFriend.route(),
      enabled: store.getters['billing/allowsReferrals'],
    }),
  integrations: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.digiticketsSettings.name,
      label: i18n.tc('nav.integrations'),
      icon: Icon.PuzzlePiece,
      to: routes.digiticketsSettings.route(),
      enabled: userCan.manageIntegrationSettings(),
    }),
  help: (callback = null): NavItemInterface =>
    attachRouteMeta({
      id: 'help',
      label: i18n.tc('nav.helpPortal'),
      icon: Icon.CircleQuestion,
      enabled: true,
      action: () => {
        openFreshdeskWidget(
          store.getters.loggedInEmployee,
          store.getters.currentCompany,
        )();
        if (callback) callback();
      },
    }),
  supportMenu: (action): NavItemInterface =>
    attachRouteMeta({
      id: 'support',
      label: i18n.tc('nav.helpSupport'),
      icon: Icon.CircleQuestion,
      enabled: true,
      action,
    }),
  desktopMoreMenu: (action): NavItemInterface =>
    attachRouteMeta({
      id: 'desktopMoreMenu',
      label: i18n.tc('nav.more'),
      icon: Icon.Ellipsis,
      enabled: true,
      action,
    }),
  mobileMoreNav: (stat: number): NavItemInterface =>
    attachRouteMeta({
      id: routes.mobileMoreNav.name,
      label: i18n.tc('nav.more'),
      to: routes.mobileMoreNav.route(),
      icon: Icon.Bars,
      stat: limitStat(stat, 9),
      enabled: true,
    }),
  systemStatus: (callback = null): NavItemInterface =>
    attachRouteMeta({
      id: 'systemStatus',
      label: i18n.tc('nav.systemStatus'),
      icon: Icon.MonitorWaveform,
      enabled: true,
      action: () => {
        goToLink(links.systemStatus);
        if (callback) callback();
      },
    }),
  whatsNew: (callback = null): NavItemInterface =>
    attachRouteMeta({
      id: 'whatsNew',
      label: i18n.tc('nav.whatsNew'),
      icon: Icon.Gift,
      enabled: true,
      action: () => {
        goToLink(links.whatsNew);
        if (callback) callback();
      },
    }),
  featuresSuggestions: (callback = null): NavItemInterface =>
    attachRouteMeta({
      id: 'featureSuggestions',
      label: i18n.tc('nav.featuresSuggestions'),
      icon: Icon.LightBulbOn,
      enabled: true,
      action: () => {
        goToLink(links.supportTicket);
        if (callback) callback();
      },
    }),
  sendMessage: (action: Function): NavItemInterface =>
    attachRouteMeta({
      id: 'sendMessage',
      label: i18n.tc('nav.sendMessage'),
      icon: Icon.Envelope,
      enabled: userCan.sendMessage(),
      feature: Feature.TeamMessaging,
      action,
    }),
  logout: (callback = null): NavItemInterface =>
    attachRouteMeta({
      id: 'logout',
      label: i18n.tc('nav.logout'),
      icon: `${Icon.PowerOff} text-red`,
      enabled: true,
      action: () => {
        if (callback) callback();
      },
    }),
  reports: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.shiftDailyTotals.name,
      label: i18n.tc('nav.reports.reports'),
      to: routes.shiftDailyTotals.route(),
      icon: Icon.ChartSimple,
      // show nav item if company is unable to access reports feature to show locked feature dialog on click
      enabled: !companyCanAccess(Feature.Reports) || userCan.viewReports(),
    }),
  reportsNav: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.reportsNav.name,
      label: i18n.tc('nav.reports.reports'),
      icon: Icon.ChartPie,
      to: routes.reportsNav.route(),
      enabled: userCan.viewReports(),
    }),

  //   Settings
  generalSettings: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.generalSettings.name,
      label: i18n.tc('nav.settings.company'),
      to: routes.generalSettings.route(),
      enabled: true,
      keywords: [
        'timezone',
        'time zone',
        'account name',
        'language',
        'time format',
        'start of week',
        'currency',
        'public holiday',
        'bank holiday',
        'account owner',
        'primary contact',
        'logo',
      ],
    }),
  locations: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.locationSettings.name,
      label: i18n.tc('nav.settings.locations'),
      parentOf: [routes.manageLocations.name, routes.editLocation.name],
      to: routes.locationSettings.route(),
      enabled: true,
      keywords: [
        'clocking',
        'clock in',
        'personal devices',
        'clock in portal',
        'gps restrictions',
        'radius',
      ],
    }),
  workPatterns: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.manageWorkPatterns.name,
      label: i18n.tc('nav.settings.workPatterns'),
      to: routes.manageWorkPatterns.route(),
      enabled: true,
      keywords: ['work pattern', 'primary'],
    }),
  notifications: (smsEnabled: boolean): NavItemInterface =>
    attachRouteMeta({
      id: routes.notificationSettings.name,
      label: i18n.tc('nav.settings.notifications'),
      to: routes.notificationSettings.route(),
      hide: !smsEnabled && !userCan.manageTimesheetSettings(),
      enabled: true,
      keywords: ['shift reminders', 'time clock', 'notify', 'overtime'],
    }),
  schedule: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.scheduleSettings.name,
      label: i18n.tc('nav.settings.schedule'),
      to: routes.scheduleSettings.route(),
      hide: !userCan.manageSchedules(),
      enabled: true,
      keywords: [
        'acknowledge',
        'shifts',
        'employees',
        'view shifts',
        'tags',
        'locations',
        'swap shifts',
        'approved',
        'approval',
        'cut off',
        'claim open shifts',
      ],
    }),
  schedules: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.manageSchedules.name,
      label: i18n.tc('nav.settings.schedules'),
      to: routes.manageSchedules.route(),
      hide: !userCan.manageSchedules(),
      enabled: true,
      keywords: ['default location'],
    }),
  shiftTemplates: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.manageShiftTemplates.name,
      label: i18n.tc('nav.settings.shiftTemplates'),
      to: routes.manageShiftTemplates.route(),
      hide: !userCan.manageShifts(),
      enabled: true,
      keywords: ['shift templates', 'job role', 'shift areas', 'location'],
    }),
  shiftAreas: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.manageShiftAreasSettings.name,
      label: i18n.tc('nav.settings.shiftAreas'),
      parentOf: [
        routes.manageShiftAreas.name,
        routes.createShiftArea.name,
        routes.editShiftArea.name,
      ],
      to: routes.manageShiftAreasSettings.route(),
      hide: !userCan.manageShiftAreas(),
      enabled: true,
      keywords: ['code', 'locations', 'colour'],
    }),
  breaks: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.breakSettings.name,
      label: i18n.tc('nav.settings.breaks'),
      to: routes.breakSettings.route(),
      hide: !userCan.manageSchedules(),
      enabled: true,
      keywords: [
        'default break length',
        'scheduled breaks',
        'deduct scheduled breaks',
        'timesheets',
        'unpaid break',
        'paid break',
      ],
    }),
  scheduleEvents: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.manageScheduleEvents.name,
      label: i18n.tc('nav.settings.scheduleEvents'),
      to: routes.manageScheduleEvents.route(),
      hide: !userCan.manageSchedules(),
      enabled: true,
      keywords: [
        'event type',
        'date',
        'runs until',
        'message',
        'announcements',
        'business closed',
        'leave restrictions',
        'schedules',
      ],
    }),
  shiftTags: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.manageTags.name,
      label: i18n.tc('nav.settings.shiftTags'),
      to: routes.manageTags.route(),
      hide: !userCan.manageShifts(),
      enabled: true,
      keywords: ['public', 'visible to employees'],
    }),
  jobRoles: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.manageJobRoles.name,
      label: i18n.tc('nav.settings.jobRoles'),
      to: routes.manageJobRoles.route(),
      enabled: true,
      keywords: ['colour'],
    }),
  employeeGroups: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.manageEmployeeGroups.name,
      label: i18n.tc('nav.settings.groups'),
      to: routes.manageEmployeeGroups.route(),
      enabled: true,
      keywords: ['position', 'order', 'department'],
    }),
  employeeAttributes: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.manageEmployeeAttributes.name,
      label: i18n.tc('entities.employeeAttribute', 0),
      to: routes.manageEmployeeAttributes.route(),
      enabled: true,
      keywords: ['skills', 'qualifications', 'accreditations'],
    }),
  leaveSettings: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.leaveSettings.name,
      label: i18n.tc('nav.settings.leaveSettings'),
      to: routes.leaveSettings.route(),
      hide: !userCan.manageLeaveSettings(),
      enabled: true,
      keywords: [
        'annual leave',
        'default leave approvers',
        'unavailability',
        'notice period',
        'absences',
        'default leave hours per day',
      ],
    }),
  leaveTypeSettings: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.leaveTypeSettings.name,
      label: i18n.tc('nav.settings.leaveTypes'),
      parentOf: [
        routes.leaveTypes.name,
        routes.createLeaveType.name,
        routes.editLeaveType.name,
      ],
      to: routes.leaveTypeSettings.route(),
      hide: !userCan.manageLeaveSettings(),
      enabled: true,
      keywords: [
        'paid',
        'unpaid',
        'colour',
        'icon',
        'entitlement',
        'accrual',
        'pay code',
      ],
    }),
  leavePolicies: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.leavePolicySettings.name,
      label: i18n.tc('nav.settings.leavePolicies'),
      parentOf: [
        routes.leavePolicies.name,
        routes.editLeavePolicy.name,
        routes.createLeavePolicy.name,
      ],
      to: routes.leavePolicySettings.route(),
      hide: !userCan.manageLeaveSettings(),
      enabled: true,
      keywords: [
        'policy details',
        'start date',
        'leave period',
        'leave notice required',
        'public holidays',
        'bank holidays',
        'exceed allowance',
        'entitlements',
        'leave types',
        'default leave allowance',
      ],
    }),
  timesheetSettings: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.timesheetSettings.name,
      label: i18n.tc('nav.timesheets'),
      to: routes.timesheetSettings.route(),
      hide: !userCan.manageTimesheetSettings(),
      enabled: true,
      keywords: [
        'timesheets',
        'auto-approval',
        'auto approve',
        'pay cycle',
        'pay period',
        'payroll',
        'clocking',
        'grace period',
        'early clock in',
        'late clock in',
        'unscheduled shifts',
        'unscheduled timesheets',
        'automatic clock out',
        'employees can edit entries',
      ],
    }),
  clockInPortals: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.manageClockInPortals.name,
      label: i18n.tc('nav.settings.clockInPortals'),
      to: routes.manageClockInPortals.route(),
      hide: !userCan.manageClockInPortals(),
      enabled: true,
      keywords: ['clock-in portals', 'locations', 'launch'],
    }),
  digitickets: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.digiticketsSettings.name,
      label: i18n.tc('nav.settings.digitickets'),
      to: routes.digiticketsSettings.route(),
      enabled: true,
      keywords: [
        'integration',
        'pre-booked',
        'prebooked',
        'visitors',
        'events',
        'branches',
        'schedules',
      ],
    }),
  trekksoft: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.trekksoftSettings.name,
      label: i18n.tc('nav.settings.trekksoft'),
      to: routes.trekksoftSettings.route(),
      enabled: true,
      keywords: ['integration', 'pre-booked', 'prebooked', 'visitors'],
    }),
  account: (alternativeLabel: string = ''): NavItemInterface =>
    attachRouteMeta({
      id: routes.accountSettings.name,
      label: alternativeLabel || i18n.tc('nav.settings.account'),
      icon: Icon.CreditCard,
      to: routes.accountSettings.route(),
      enabled: userHasPermission(Permission.ManageBilling),
      keywords: [
        'account information',
        'plan details',
        'current plan',
        'change plan',
        'included employees',
        'sms notifications',
        'subscription',
        'next billing event',
        'payment',
        'package',
      ],
    }),
  billing: (
    billingSubscription: BillingSubscription,
    billingDetails: BillingDetails | null,
  ): NavItemInterface =>
    attachRouteMeta({
      id: routes.billingSettings.name,
      label: i18n.tc('nav.settings.billingDetails'),
      to: routes.billingSettings.route(),
      hide:
        !billingDetails ||
        (billingSubscription.price === 0 &&
          !billingSubscription.additionalEmployeePrice &&
          !billingSubscription.billingPlan.allowsCreditRenewals),
      enabled: userHasPermission(Permission.ManageBilling),
      keywords: [
        'billing details',
        'billing address',
        'payment method',
        'stripe',
        'card',
        'payment',
        'package',
      ],
    }),
  invoices: (
    billingSubscription: BillingSubscription,
    isTrialVersion: boolean,
  ): NavItemInterface =>
    attachRouteMeta({
      id: routes.invoices.name,
      label: i18n.tc('nav.settings.invoices'),
      to: routes.invoices.route(),
      hide:
        isTrialVersion ||
        (billingSubscription.price === 0 &&
          !billingSubscription.additionalEmployeePrice &&
          !billingSubscription.billingPlan.allowsCreditRenewals),
      enabled: true,
      keywords: [
        'invoice date',
        'billing',
        'reference',
        'download',
        'subscription',
      ],
    }),
  offers: (billingPlan: BillingPlan): NavItemInterface =>
    attachRouteMeta({
      id: routes.offers.name,
      label: i18n.tc('nav.settings.offers'),
      to: routes.offers.route(),
      hide: !billingPlan.allowsOffers,
      enabled: true,
      keywords: ['offer code', 'discounts', 'remaining uses'],
    }),

  //   Reports
  /* shift cost ('hide' prop is being handled by parent item) */
  shiftDailyTotals: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.shiftDailyTotals.name,
      label: i18n.tc('route.title.shiftDailyTotals'),
      to: routes.shiftDailyTotals.route(),
      hide: false,
      enabled: true,
    }),
  shiftTotalsByEmployee: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.shiftTotalsByEmployee.name,
      label: i18n.tc('route.title.shiftTotalsByEmployee'),
      to: routes.shiftTotalsByEmployee.route(),
      hide: false,
      enabled: true,
    }),
  shiftTotalsByJobRole: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.shiftTotalsByJobRole.name,
      label: i18n.tc('route.title.shiftTotalsByJobRole'),
      to: routes.shiftTotalsByJobRole.route(),
      hide: false,
      enabled: true,
    }),
  shiftTotalsByLocation: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.shiftTotalsByLocation.name,
      label: i18n.tc('route.title.shiftTotalsByLocation'),
      to: routes.shiftTotalsByLocation.route(),
      hide: false,
      enabled: true,
    }),
  shiftTotalsByGroup: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.shiftTotalsByGroup.name,
      label: i18n.tc('route.title.shiftTotalsByGroup'),
      to: routes.shiftTotalsByGroup.route(),
      hide: false,
      enabled: true,
    }),
  shiftTotalsByEmploymentType: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.shiftTotalsByEmploymentType.name,
      label: i18n.tc('route.title.shiftTotalsByEmploymentType'),
      to: routes.shiftTotalsByEmploymentType.route(),
      hide: false,
      enabled: true,
    }),
  /* logs */
  messageHistory: (): NavItemInterface =>
    attachRouteMeta({
      id: routes.messageHistory.name,
      label: i18n.tc('route.title.messageHistory'),
      to: routes.messageHistory.route(),
      hide: !userCan.viewMessageLogs(),
      enabled: true,
    }),

  //   Profile
  unavailability: (employeeId: number): NavItemInterface =>
    attachRouteMeta({
      id: routes.unavailability.name,
      label: i18n.tc('nav.unavailability'),
      to: routes.unavailability.route(employeeId),
      icon: Icon.CalendarXmark,
      enabled: userCan.viewUnavailability(employeeId),
    }),
  leave: (employeeId: number): NavItemInterface =>
    attachRouteMeta({
      id: routes.leave.name,
      label: i18n.tc('nav.leave'),
      to: routes.leave.route(employeeId),
      icon: Icon.Plane,
      hide: !userCan.viewLeave(employeeId),
      enabled: userCan.viewLeave(employeeId),
    }),
  absence: (employeeId: number): NavItemInterface =>
    attachRouteMeta({
      id: routes.absence.name,
      label: i18n.tc('nav.absence'),
      to: routes.absence.route(employeeId),
      icon: Icon.TruckMedical,
      hide: !companyCanAccess(Feature.Absence),
      enabled: userCan.viewAbsence(),
    }),
  profileSummary: (employeeId: number, hide: boolean): NavItemInterface =>
    attachRouteMeta({
      id: routes.profileSummary.name,
      label: i18n.tc('nav.profile.profileSummary'),
      to: routes.profileSummary.route(employeeId),
      icon: Icon.User,
      hide,
      enabled: true,
    }),
  employmentDetails: (employeeId: number): NavItemInterface =>
    attachRouteMeta({
      id: routes.employmentDetails.name,
      label: i18n.tc('nav.profile.employmentDetails'),
      to: routes.employmentDetails.route(employeeId),
      icon: Icon.Briefcase,
      hide: !userCan.manageEmployee(employeeId),
      enabled: userCan.manageEmployee(employeeId),
    }),
  whereAndWhen: (employeeId: number): NavItemInterface =>
    attachRouteMeta({
      id: routes.whereAndWhen.name,
      label: i18n.tc('route.title.whereAndWhen'),
      icon: Icon.LocationDot,
      to: routes.whereAndWhen.route(employeeId),
      hide: !userCan.manageEmployee(employeeId),
      enabled: userCan.manageEmployee(employeeId),
    }),
  permissions: (employeeId: number): NavItemInterface =>
    attachRouteMeta({
      id: routes.permissions.name,
      label: i18n.tc('route.title.permissions'),
      to: routes.permissions.route(employeeId),
      icon: Icon.Lock,
      hide: !userCan.manageEmployee(employeeId),
      enabled: true,
    }),
  personalDetails: (employeeId: number): NavItemInterface =>
    attachRouteMeta({
      id: routes.personalDetails.name,
      label: i18n.tc('nav.personalDetails'),
      icon: Icon.IdCard,
      to: routes.personalDetails.route(employeeId),
      enabled: true,
    }),
  wageAndSalary: (employeeId: number): NavItemInterface =>
    attachRouteMeta({
      id: routes.wageAndSalary.name,
      label: i18n.tc('route.title.wageAndSalary'),
      icon: Icon.Wallet,
      to: routes.wageAndSalary.route(employeeId),
      hide: !userCan.managePay(),
      enabled: userCan.managePay(),
    }),
  emergencyDetails: (employeeId: number): NavItemInterface =>
    attachRouteMeta({
      id: routes.emergencyDetails.name,
      label: i18n.tc('route.title.emergencyDetails'),
      icon: Icon.LifeRing,
      to: routes.emergencyDetails.route(employeeId),
      enabled: true,
    }),
  myDocuments: (employeeId: number): NavItemInterface =>
    attachRouteMeta({
      id: routes.myDocuments.name,
      label: i18n.tc('nav.myDocuments'),
      icon: Icon.FileLines,
      to: routes.myDocuments.route(employeeId),
      hide: !userCan.viewDocuments(),
      enabled: companyCanAccess(Feature.HrManagement),
    }),
});
