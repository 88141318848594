import { IconType } from '@/lib/enum/Icon';
import {
  companyCanAccess,
  companyHasFeatureFlag,
} from '@/lib/permission/companyAccessFeatures';
import { OurRouteConfig, routes } from '@/router/routes';
import { stringSearch } from '@/util/stringFunctions';
import { Location, Route } from 'vue-router';
import {
  BillingPlanFeaturesEnum,
  Company,
  CompanyFeatureFlagsEnum,
} from '../../../api/v1';

export interface NavItemInterface {
  id: OurRouteConfig['name'] | string;
  parentOf?: Location['name'][];
  childOf?: Location['name'];
  label: string;
  keywords?: string[];
  icon?: string;
  to?: Location;
  // An alternative to a simple link. Required if 'routeConfig' is not provided.
  action?: Function;
  // Whether the user should be able to see this item (intersection of permissions + plan features)
  enabled: boolean;
  hide?: boolean;
  stat?: number | string;
  featureFlag?: CompanyFeatureFlagsEnum;
  feature?: BillingPlanFeaturesEnum;
}

export interface NavSectionInterface {
  name: string;
  icon: IconType;
  open: (routeName: string) => boolean;
  hide: boolean;
  children: NavItemInterface[];
}

type NavItemObject = { [name: string]: (...args: any[]) => NavItemInterface };
type NavItemSection = {
  [name: string]: (...args: any[]) => NavSectionInterface;
};
/**
 * @see https://stackoverflow.com/a/52157355/921476
 */
export const toTypedMap = <T extends NavItemObject>(o: T): T => o;
export const toSectionMap = <T extends NavItemSection>(o: T): T => o;

export const isNavSectionOpen =
  <T extends NavItemSection>(
    nav: T,
    sectionName: keyof T,
    args: any[] = [],
  ): ((routeName: string) => boolean) =>
  (routeName: string) =>
    nav[sectionName](...args).children.some(
      (child) =>
        child.to.name === routeName || child.parentOf?.includes(routeName),
    );

export const isSidebarItemAdditionallyHighlighted = (
  navItem: NavItemInterface,
  route: Route,
) => {
  const teamPageName = routes.team.name;
  const settingsPageName = routes.generalSettings.name;
  const reportsPageName = routes.shiftDailyTotals.name;
  const sidebarNavItemName = navItem.to?.name;

  if (
    [teamPageName, settingsPageName, reportsPageName].includes(
      sidebarNavItemName,
    )
  ) {
    const firstMatchedRouteName = route.matched?.[0]?.name;
    const additionalHighlightConditions = {
      // team page sidebar item is active if any profile page is active
      [teamPageName]: firstMatchedRouteName === routes.profile.name,
      // settings page sidebar item is active if any settings page is active
      [settingsPageName]: firstMatchedRouteName === routes.settings.name,
      // reports page sidebar item is active if any reports page is active
      [reportsPageName]: firstMatchedRouteName === routes.reports.name,
    };
    return additionalHighlightConditions[sidebarNavItemName];
  }
  return false;
};

export const isFeatureFlagRoute = (navItem: NavItemInterface): boolean =>
  navItem.featureFlag !== undefined;

export const isVisibleRoute =
  (checkFeature: boolean, company: Company) => (item: NavItemInterface) =>
    item.enabled &&
    (!checkFeature || !item.feature || companyCanAccess(item.feature)) &&
    (!isFeatureFlagRoute(item) ||
      companyHasFeatureFlag(company, item.featureFlag));

export const isActiveRoute = (
  navItem: NavItemInterface,
  currentRoute: Route,
  moreNavHasTeam: boolean = false,
): boolean => {
  const routeName = navItem.id;

  if (routeName === currentRoute.name) {
    // Some routes have empty params objects which need comparing against some routes with undefined params
    const standardiseParams = <T extends object | unknown>(params: T): T =>
      Object.values(params || {}).length ? params : undefined;
    const itemParams = standardiseParams(navItem.to.params);
    const currentParams = standardiseParams(currentRoute.params);
    return JSON.stringify(itemParams) === JSON.stringify(currentParams);
  }

  if (currentRoute.matched.some((r) => routeName === r.name)) {
    return true;
  }

  const parentRoute = currentRoute.matched?.length
    ? currentRoute.matched[0].name
    : '';

  if (
    [
      routes.requests.name,
      routes.settingsNav.name,
      routes.reportsNav.name,
      ...(moreNavHasTeam ? [routes.team.name] : []),
    ].includes(currentRoute.name) ||
    [
      routes.settings.name,
      routes.reports.name,
      routes.profile.name,
      ...(moreNavHasTeam ? [routes.profile.name] : []),
    ].includes(parentRoute)
  ) {
    return routeName === routes.mobileMoreNav.name;
  }

  if (parentRoute === routes.profile.name) {
    return routeName === routes.team.name;
  }

  return false;
};

export const searchNavItem =
  (query: string) =>
  <T extends NavItemInterface>(item: T): boolean =>
    stringSearch(item.label, query) ||
    stringSearch(query, item.label) ||
    (!!item.keywords &&
      item.keywords.some(
        (keyword) =>
          stringSearch(keyword, query) || stringSearch(query, keyword),
      ));
