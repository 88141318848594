<template>
  <v-text-field
    ref="search"
    v-model="search"
    class="w-full search-input"
    :placeholder="placeholder"
    clearable
    :clear-icon="`${clearIcon} mb-2 mx-auto w-full text-center`"
    hide-details="auto"
    outlined
    dense
    :color="'#AD1267'"
    :background-color="backgroundColour"
    :height="height"
    @click:clear="$emit('submit', '')"
    @input="emitEvent(search || '')"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  >
    <template #prepend-inner>
      <slot name="prepend-inner">
        <i
          :class="Icon.MagnifyingGlass"
          class="mt-1 text-center mr-2 text-gray-600 flip"
        />
      </slot>
    </template>
    <template #append>
      <slot name="append" />
    </template>
    <template #append-outer>
      <slot name="append-outer" />
    </template>
  </v-text-field>
</template>

<script>
import { Icon } from '@/lib/enum/Icon';

export default {
  name: 'Search',

  props: {
    initialValue: {
      type: String,
      default: '',
      required: false,
    },
    placeholder: {
      type: String,
      required: true,
    },
    clearIcon: {
      type: String,
      default: Icon.Xmark,
      required: false,
    },
    height: {
      type: Number,
      default: 42,
    },
    backgroundColour: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      Icon,
      search: this.initialValue,
      typingTimer: 0,
    };
  },

  watch: {
    initialValue(newValue) {
      this.search = newValue;
    },
  },

  methods: {
    emitEvent(input) {
      this.$emit('loading');
      clearTimeout(this.typingTimer);
      if (input) {
        this.typingTimer = setTimeout(() => {
          this.$emit('submit', input);
        }, 200);
      } else {
        this.$emit('submit', '');
      }
    },
  },
};
</script>

<style lang="scss">
.search-input {
  align-items: center;
  .v-input__slot {
    min-height: 35px !important;
  }
  .v-input__append-inner {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 !important;

    // Since we are using flex for append-inner,
    // we need to override the default margin for Clear button
    .v-input__icon {
      button {
        margin: 0 !important;
      }
    }
  }
  .flip {
    transform: scaleX(-1);
  }
}
</style>
