/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Pagination } from './Pagination';
import {
    PaginationFromJSON,
    PaginationFromJSONTyped,
    PaginationToJSON,
    PaginationToJSONTyped,
} from './Pagination';
import type { DigiticketsRedemptionSummary } from './DigiticketsRedemptionSummary';
import {
    DigiticketsRedemptionSummaryFromJSON,
    DigiticketsRedemptionSummaryFromJSONTyped,
    DigiticketsRedemptionSummaryToJSON,
    DigiticketsRedemptionSummaryToJSONTyped,
} from './DigiticketsRedemptionSummary';
import type { CacheMeta } from './CacheMeta';
import {
    CacheMetaFromJSON,
    CacheMetaFromJSONTyped,
    CacheMetaToJSON,
    CacheMetaToJSONTyped,
} from './CacheMeta';

/**
 * 
 * @export
 * @interface ListDigiticketsRedemptionSummariesResponse
 */
export interface ListDigiticketsRedemptionSummariesResponse {
    /**
     * 
     * @type {CacheMeta}
     * @memberof ListDigiticketsRedemptionSummariesResponse
     */
    cache: CacheMeta;
    /**
     * 
     * @type {Array<DigiticketsRedemptionSummary>}
     * @memberof ListDigiticketsRedemptionSummariesResponse
     */
    data: Array<DigiticketsRedemptionSummary>;
    /**
     * 
     * @type {Pagination}
     * @memberof ListDigiticketsRedemptionSummariesResponse
     */
    pagination: Pagination;
}

/**
 * Check if a given object implements the ListDigiticketsRedemptionSummariesResponse interface.
 */
export function instanceOfListDigiticketsRedemptionSummariesResponse(value: object): value is ListDigiticketsRedemptionSummariesResponse {
    if (!('cache' in value) || value['cache'] === undefined) return false;
    if (!('data' in value) || value['data'] === undefined) return false;
    if (!('pagination' in value) || value['pagination'] === undefined) return false;
    return true;
}

export function ListDigiticketsRedemptionSummariesResponseFromJSON(json: any): ListDigiticketsRedemptionSummariesResponse {
    return ListDigiticketsRedemptionSummariesResponseFromJSONTyped(json, false);
}

export function ListDigiticketsRedemptionSummariesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ListDigiticketsRedemptionSummariesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'cache': CacheMetaFromJSON(json['cache']),
        'data': ((json['data'] as Array<any>).map(DigiticketsRedemptionSummaryFromJSON)),
        'pagination': PaginationFromJSON(json['pagination']),
    };
}

  export function ListDigiticketsRedemptionSummariesResponseToJSON(json: any): ListDigiticketsRedemptionSummariesResponse {
      return ListDigiticketsRedemptionSummariesResponseToJSONTyped(json, false);
  }

  export function ListDigiticketsRedemptionSummariesResponseToJSONTyped(value?: ListDigiticketsRedemptionSummariesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'cache': CacheMetaToJSON(value['cache']),
        'data': ((value['data'] as Array<any>).map(DigiticketsRedemptionSummaryToJSON)),
        'pagination': PaginationToJSON(value['pagination']),
    };
}

