import i18n from '@/i18n';
import { Icon } from '@/lib/enum/Icon';
import {
  isNavSectionOpen,
  NavSectionInterface,
  toSectionMap,
} from '@/lib/navigation/helpers';
import { navItems } from '@/lib/navigation/navItems';
import { userCan } from '@/lib/permission/userCan';
import { BillingDetails, BillingSubscription } from '../../../api/v1';

export const settingsNav = toSectionMap({
  general: (smsEnabled: boolean): NavSectionInterface => ({
    name: i18n.tc('nav.settings.generalSettings'),
    icon: Icon.Gear,
    open: isNavSectionOpen(settingsNav, 'general', [smsEnabled]),
    hide: !userCan.manageSettings(),
    children: [
      navItems.generalSettings(),
      navItems.workPatterns(),
      navItems.notifications(smsEnabled),
    ],
  }),
  scheduling: (): NavSectionInterface => ({
    name: i18n.tc('nav.settings.scheduling'),
    icon: Icon.CalendarDays,
    open: isNavSectionOpen(settingsNav, 'scheduling'),
    hide: !(userCan.manageShifts() || userCan.manageSchedules()),
    children: [
      navItems.schedule(),
      navItems.schedules(),
      navItems.shiftTemplates(),
      navItems.shiftAreas(),
      navItems.breaks(),
      navItems.scheduleEvents(),
      navItems.shiftTags(),
    ],
  }),
  team: (): NavSectionInterface => ({
    name: i18n.tc('nav.settings.team'),
    icon: Icon.Users,
    open: isNavSectionOpen(settingsNav, 'team'),
    hide: !userCan.manageTeamSettings(),
    children: [
      navItems.jobRoles(),
      navItems.employeeGroups(),
      navItems.employeeAttributes(),
    ],
  }),
  timeOff: (): NavSectionInterface => ({
    name: i18n.tc('nav.settings.timeOff'),
    icon: Icon.Plane,
    open: isNavSectionOpen(settingsNav, 'timeOff'),
    hide: !userCan.manageLeaveSettings(),
    children: [
      navItems.leaveSettings(),
      navItems.leaveTypeSettings(),
      navItems.leavePolicies(),
    ],
  }),
  timeAndAttendance: (): NavSectionInterface => ({
    name: i18n.tc('nav.settings.timeAndAttendance'),
    icon: Icon.Clock,
    open: isNavSectionOpen(settingsNav, 'timeAndAttendance'),
    hide: !userCan.manageSettings(),
    children: [
      navItems.locations(),
      navItems.clockInPortals(),
      navItems.timesheetSettings(),
    ],
  }),
  integrations: (): NavSectionInterface => ({
    name: i18n.tc('nav.settings.integrations'),
    icon: Icon.ChartNetwork,
    open: isNavSectionOpen(settingsNav, 'integrations'),
    hide: !userCan.manageIntegrationSettings(),
    children: [navItems.digitickets(), navItems.trekksoft()],
  }),
  billing: (
    currentSubscription: BillingSubscription,
    isTrialVersion: boolean,
    billingDetails: BillingDetails | null,
  ): NavSectionInterface => ({
    name: i18n.tc('nav.settings.billingDetails'),
    icon: Icon.CreditCard,
    open: isNavSectionOpen(settingsNav, 'billing', [
      currentSubscription,
      isTrialVersion,
      billingDetails,
    ]),
    hide: !userCan.manageBilling(),
    children: [
      navItems.account(),
      navItems.billing(currentSubscription, billingDetails),
      navItems.invoices(currentSubscription, isTrialVersion),
      navItems.offers(currentSubscription.billingPlan),
    ],
  }),
});
