/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateTimesheetEntryDataTimesheetBreaksInner
 */
export interface CreateTimesheetEntryDataTimesheetBreaksInner {
    /**
     * 
     * @type {Date}
     * @memberof CreateTimesheetEntryDataTimesheetBreaksInner
     */
    startedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateTimesheetEntryDataTimesheetBreaksInner
     */
    endedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof CreateTimesheetEntryDataTimesheetBreaksInner
     */
    scheduledBreakId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateTimesheetEntryDataTimesheetBreaksInner
     */
    paid: boolean;
}

/**
 * Check if a given object implements the CreateTimesheetEntryDataTimesheetBreaksInner interface.
 */
export function instanceOfCreateTimesheetEntryDataTimesheetBreaksInner(value: object): value is CreateTimesheetEntryDataTimesheetBreaksInner {
    if (!('startedAt' in value) || value['startedAt'] === undefined) return false;
    if (!('endedAt' in value) || value['endedAt'] === undefined) return false;
    if (!('paid' in value) || value['paid'] === undefined) return false;
    return true;
}

export function CreateTimesheetEntryDataTimesheetBreaksInnerFromJSON(json: any): CreateTimesheetEntryDataTimesheetBreaksInner {
    return CreateTimesheetEntryDataTimesheetBreaksInnerFromJSONTyped(json, false);
}

export function CreateTimesheetEntryDataTimesheetBreaksInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTimesheetEntryDataTimesheetBreaksInner {
    if (json == null) {
        return json;
    }
    return {
        
        'startedAt': (new Date(json['startedAt'])),
        'endedAt': (new Date(json['endedAt'])),
        'scheduledBreakId': json['scheduledBreakId'] == null ? undefined : json['scheduledBreakId'],
        'paid': json['paid'],
    };
}

  export function CreateTimesheetEntryDataTimesheetBreaksInnerToJSON(json: any): CreateTimesheetEntryDataTimesheetBreaksInner {
      return CreateTimesheetEntryDataTimesheetBreaksInnerToJSONTyped(json, false);
  }

  export function CreateTimesheetEntryDataTimesheetBreaksInnerToJSONTyped(value?: CreateTimesheetEntryDataTimesheetBreaksInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'startedAt': ((value['startedAt']).toISOString()),
        'endedAt': ((value['endedAt']).toISOString()),
        'scheduledBreakId': value['scheduledBreakId'],
        'paid': value['paid'],
    };
}

