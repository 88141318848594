import { ConditionBuilder } from '@/lib/helpers/ConditionBuilder';
import { entityConfig } from '@/lib/realtime/weak/entityConfig';
import {
  EntityFetcherRequestMap,
  EntityInstance,
  EntityInterfaceMap,
  EntityName,
  GetParameterType,
  WSEntityName,
} from '@/lib/realtime/weak/realtimeTypes';
import {
  ReactiveConstructor,
  WeakEntityRepository,
  WeakEntityRepositoryResponse,
  WeakEntityRepositoryUpdateKind,
  WindowExtensionTag,
} from '@/lib/realtime/weak/WeakEntityRepository';
import { websocketService } from '@/plugins/socket-service/SocketService';
import { makeCancellable } from '@/util/promiseFunctions';
import { reactive, UnwrapRef, watch } from 'vue';
import { ListEventEntitiesResponseDataEnum } from '../../../../api/v1';

export type RealtimeQueryLifetime = {
  apply: (ref: unknown) => void;
  count: () => number;
  clear: () => void;
};

export type FetchOptions<T extends EntityName> = {
  lifetime?: RealtimeQueryLifetime;
  prevValue?: EntityInstance<T>[] | null; // Will be used as prefetched data, also isLoaded true will be set
  forceRefetch?: boolean; // Will trigger force data refetch
};

export const createRealtimeQueryLifetime = (): RealtimeQueryLifetime => {
  const refs = [];
  return {
    apply: (ref: unknown) => refs.push(ref),
    count: () => refs.length,
    clear: () => refs.splice(0, refs.length),
  };
};

const repositoryDebugTag = 'default';
export const weakEntityRepository = new WeakEntityRepository<
  EntityInterfaceMap,
  ListEventEntitiesResponseDataEnum,
  EntityFetcherRequestMap
>(
  entityConfig,
  websocketService,
  reactive as ReactiveConstructor,
  repositoryDebugTag,
);
declare global {
  interface Window
    extends WindowExtensionTag<
      typeof entityConfig,
      typeof repositoryDebugTag
    > {}
}

export class RealtimeQuery<Key extends EntityName> extends ConditionBuilder<
  EntityInstance<Key>
> {
  readonly #entity = undefined as Key;

  readonly #params = {} as NonNullable<GetParameterType<Key>>;

  constructor(entity: Key, params: GetParameterType<Key>) {
    super();
    this.#entity = entity;
    this.#params = params;
    // If params exist, then we need to attach them as a where filter
    Object.keys(params).forEach((key) => {
      this.where(key as keyof EntityInterfaceMap[Key], 'eq', params[key]);
    });
  }

  fetch(
    options: FetchOptions<Key> = {},
  ): WeakEntityRepositoryResponse<EntityInterfaceMap, Key> {
    const result = weakEntityRepository.subscribe(
      this.#entity,
      this.conditions,
      this.#params,
    );

    if (options.lifetime) {
      options.lifetime.apply(result);
    }

    if (options.forceRefetch && !result.isLoading) {
      result.refetch();
    }

    if (options.prevValue && !result.isLoaded) {
      result.data = options.prevValue;
      result.isLoaded = true;
    }

    return result;
  }

  empty(
    loading: boolean = true,
  ): WeakEntityRepositoryResponse<EntityInterfaceMap, Key> {
    return reactive({
      entity: this.#entity,
      refetch: () => makeCancellable(Promise.resolve([])),
      data: [] as EntityInstance<Key>[],
      promise: null,
      error: null,
      isLoading: loading,
      isLoaded: true,
      isFetching: false,
    }) as WeakEntityRepositoryResponse<EntityInterfaceMap, Key>;
  }
}

export const realtimeQuery = <Key extends EntityName>(
  entityName: Key,
  params: GetParameterType<Key> = {} as GetParameterType<Key>,
): RealtimeQuery<Key> => {
  return new RealtimeQuery(entityName, params);
};

export interface SingleWeakEntityRepositoryResponse<Key extends EntityName>
  extends Omit<WeakEntityRepositoryResponse<EntityInterfaceMap, Key>, 'data'> {
  data: EntityInstance<Key> | null;
}

export const singleEntityRealtimeQuery = <T extends EntityName>(
  entityName: T,
  params: GetParameterType<T> = {} as GetParameterType<T>,
  options: FetchOptions<T> = {},
): SingleWeakEntityRepositoryResponse<T> => {
  const query: WeakEntityRepositoryResponse<EntityInterfaceMap, T> =
    realtimeQuery(entityName, params).fetch(options);

  const value = reactive<SingleWeakEntityRepositoryResponse<T>>({
    entity: query.entity,
    data: query.data[0] || null,
    error: query.error,
    isLoading: query.isLoading,
    isLoaded: query.isLoaded,
    isFetching: query.isFetching,
    refetch: () => query.refetch(),
    promise: query.promise,
  });

  watch(
    query,
    () => {
      value.data = (query.data[0] ?? null) as UnwrapRef<
        EntityInstance<T>
      > | null;
      value.isLoading = query.isLoading;
      value.isLoaded = query.isLoaded;
      value.isFetching = query.isFetching;
      value.promise = query.promise;
      value.error = query.error;
    },
    {
      deep: true,
      immediate: true,
    },
  );

  return value as SingleWeakEntityRepositoryResponse<T>;
};

export const getEmptySingleEntityRealtimeQuery = <T extends EntityName>(
  entityName: T,
  loading: boolean = true,
) => {
  return reactive({
    entity: null,
    data: null,
    isLoading: loading,
    isLoaded: false,
    refetch: () => makeCancellable(Promise.resolve([])),
    promise: null,
  }) as SingleWeakEntityRepositoryResponse<T>;
};

export const realtimeFindEntity = async <T extends WSEntityName>(
  entityName: T,
  id: EntityInstance<T>[keyof EntityInstance<T>],
  key: keyof EntityInstance<T> = 'id',
): Promise<EntityInstance<T> | undefined> => {
  const query = realtimeQuery(entityName).where(key, 'eq', id).fetch();
  await query.promise;
  return query.data[0];
};

export const realtimeQueryInstantUpdate = <
  T extends WSEntityName,
  K extends WeakEntityRepositoryUpdateKind,
>(
  entity: T,
  kind: K,
  data: K extends WeakEntityRepositoryUpdateKind.DELETE
    ? number
    : EntityInstance<T>,
) => weakEntityRepository.dispatchUpdate(kind, entity, data);
