<template>
  <SlidePanel
    :value="value"
    side="left"
    @input="close"
  >
    <div
      slot="body"
      class="all:bg-gray-200 h-full w-full relative"
    >
      <div class="all:p-4">
        <i
          :class="Icon.Xmark"
          class="w-5 h-5 all:flex justify-center align-center text-gray-400 text-14 ml-auto cursor-pointer"
          @click="close"
        />
      </div>

      <div class="all:p-4">
        <NavSection
          :heading="$tc('nav.helpSupport')"
          :items="supportItems"
          @click="$event.to ? redirect($event.to) : $event.action()"
        />
      </div>
      <div
        class="absolute bottom-0 left-0 w-full all:p-4 all:pt-0 all:bg-white lg:bg-gray-50"
      >
        <VersionInformation class="border-t-1 border-purple-50 pt-4" />
      </div>
    </div>
  </SlidePanel>
</template>

<script>
import VersionInformation from '@/components/info/VersionInformation.vue';
import SlidePanel from '@/components/interface/SlidePanel.vue';
import NavSection from '@/components/nav/common/NavSection.vue';
import { Icon } from '@/lib/enum/Icon';
import { navItems } from '@/lib/navigation/navItems';
import { redirect } from '@/router/router';

export default {
  name: 'SupportNav',
  components: { NavSection, VersionInformation, SlidePanel },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      Icon,
    };
  },
  computed: {
    supportItems() {
      return [
        navItems.help(this.close),
        navItems.whatsNew(this.close),
        navItems.featuresSuggestions(this.close),
        navItems.integrations(),
        navItems.systemStatus(this.close),
      ].filter((i) => i.enabled);
    },
  },
  methods: {
    redirect,

    onClick(to) {
      redirect(to);
      this.close();
    },

    close() {
      this.$emit('input', false);
    },

    activeRoute(to) {
      return to.name === this.$route.name;
    },
  },
};
</script>

<style scoped>
nav {
  max-height: calc(100vh - calc(75px + 64px));
  overflow: auto;
}
</style>
