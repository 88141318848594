
import Chevron from '@/components/interface/Chevron.vue';
import NavItem from '@/components/nav/common/NavItem.vue';
import {
  NavItemInterface,
  NavSectionInterface,
  searchNavItem,
} from '@/lib/navigation/helpers';
import { redirect } from '@/router/router';
import { routes } from '@/router/routes';
import { isRouteActive } from '@/util/routeFunctions';
import { stringSearch } from '@/util/stringFunctions';
import { PropType } from 'vue';

export default {
  name: 'CollapsibleNav',
  components: { NavItem, Chevron },
  props: {
    nav: {
      type: Array as PropType<NavSectionInterface[]>,
      required: true,
    },
    query: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      navToggles: {} as Record<
        NavSectionInterface['name'],
        ReturnType<NavSectionInterface['open']>
      >,
    };
  },
  watch: {
    nav: {
      immediate: true,
      handler() {
        this.updateNavToggles();
      },
    },
    '$route.name': {
      immediate: true,
      handler() {
        this.updateNavToggles();
      },
    },
  },
  methods: {
    stringSearch,
    searchNavItem,
    redirect,
    updateNavToggles() {
      if (this.nav) {
        this.navToggles = this.nav.reduce((obj, navItem) => {
          const currentRouteName = this.$route.name;
          // we don't wanna open anything when CollapsibleNav being used as the main component on the page
          // e.g as a central nav on /reports and /settings pages
          const navItemIsOpen = [
            routes.reportsNav.name,
            routes.settingsNav.name,
          ].includes(currentRouteName)
            ? false
            : navItem.open(currentRouteName);
          return { ...obj, [navItem.name]: navItemIsOpen };
        }, {});
      }
    },

    selected(item: NavItemInterface) {
      return isRouteActive(this.$route.name, item.to.name);
    },

    toggleSection(key: string, value: boolean) {
      if (value) {
        Object.keys(this.navToggles).forEach((sectionName) => {
          // open target section
          if (sectionName === key) {
            this.navToggles[key] = value;
            return;
          }
          // close other sections
          if (this.navToggles[sectionName]) {
            this.navToggles[sectionName] = false;
          }
        });
        return;
      }
      // if we close target section then it means others already being closed
      this.navToggles[key] = value;
    },
  },
};
