<template>
  <SidebarLayout>
    <template #nav>
      <SettingsNav />
    </template>
    <template #body>
      <slot />
    </template>
  </SidebarLayout>
</template>

<script>
import SettingsNav from '@/components/nav/SettingsNav.vue';
import SidebarLayout from '@/layouts/SidebarLayout';

export default {
  name: 'SettingsLayout',

  components: {
    SidebarLayout,
    SettingsNav,
  },
};
</script>
