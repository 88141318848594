<template>
  <div class="flex h-full bg-white">
    <div
      class="sidebar relative"
      :class="{
        collapsed: isOpen,
        'mr-0': disableSidebarMarginRight,
      }"
    >
      <slot
        v-if="!isOpen"
        name="nav"
      />
      <span
        class="rounded-full bg-white absolute z-10 w-4 h-4 border-1 border-gray-200 top-8 -right-2 all:flex align-center justify-center cursor-pointer"
        @click="isOpen = !isOpen"
      >
        <i
          :class="isOpen ? Icon.ChevronRight : Icon.ChevronLeft"
          class="text-10 text-gray-600"
        />
      </span>
    </div>

    <div
      class="body-wrapper w-full"
      :class="{ 'sidebar-collapsed': isOpen }"
    >
      <div
        class="body-content"
        :class="{ 'fit-main-header': !fixedContentWidth }"
      >
        <slot name="body" />
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from '@/lib/enum/Icon';

export default {
  name: 'SidebarLayout',

  props: {
    disableSidebarMarginRight: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      Icon,
    };
  },

  computed: {
    isOpen: {
      get() {
        if (typeof this.$route.meta.sidebarCollapsed === 'boolean') {
          return this.$route.meta.sidebarCollapsed;
        }
        return this.$route.matched.length
          ? this.$route.matched[0].meta.sidebarCollapsed
          : false;
      },
      set(v) {
        if (typeof this.$route.meta.sidebarCollapsed === 'boolean') {
          this.$route.meta.sidebarCollapsed = v;
          return;
        }
        if (this.$route.matched.length) {
          this.$route.matched[0].meta.sidebarCollapsed = v;
        }
      },
    },

    fixedContentWidth() {
      return this.$route.matched?.[0]?.meta?.fixedContentWidth ?? true;
    },
  },
};
</script>

<style scoped lang="scss">
.sidebar {
  @apply shadow;
  background: #fff;
  width: 100%;
  transition: max-width 0.2s ease;
  z-index: 5;

  @media screen and (max-width: $md - 1px) {
    top: 55px;
    bottom: 85px;
    overflow: auto;
    left: 0;
    right: 0;
  }

  @media screen and (min-width: $lg) {
    max-width: 350px;
    background: $gray-200;
    margin-right: 2rem;
  }

  &.collapsed {
    max-width: 50px;
  }
}

.body {
  &-wrapper {
    padding-right: min(calc(350px + 2rem), calc((100vw - (1600px + 4rem)) / 2));
    &.sidebar-collapsed {
      @media screen and (min-width: calc(1600px + (100px + 8rem))) {
        padding-right: calc(50px + 2rem);
      }
    }
    max-height: calc(100vh - 70px);
    overflow: auto;
  }

  &-content {
    width: 100%;
    height: 100%;
    max-width: 1050px;
    margin: 0 auto;

    &.fit-main-header {
      max-width: 100%;
      padding-right: 32px; // main header padding (use only for right direction as we only match this side)
    }
  }
}
</style>
