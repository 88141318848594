
import EmployeeAvatar from '@/components/employee/EmployeeAvatar.vue';
import BubbleIcon from '@/components/interface/BubbleIcon.vue';
import Chevron from '@/components/interface/Chevron.vue';
import { AvatarSizes } from '@/lib/enum/AvatarSizes';
import { Icon } from '@/lib/enum/Icon';
import { userCan } from '@/lib/permission/userCan';
import { Entity } from '@/lib/realtime/RealtimeEntity';
import { realtimeQuery } from '@/lib/realtime/weak/realtimeFunctions';
import { presentShiftTimes } from '@/lib/schedule-v2/scheduleV2Functions';
import {
  isPendingShiftSwap,
  shiftJobRoleIsVisible,
  tagVisibilityFilter,
  totalShiftBreakDuration,
} from '@/lib/schedule-v2/shiftV2Functions';
import { isAssigned } from '@/lib/schedule-v2/shiftV2Status';
import store from '@/store';
import { getEntity } from '@/util/entityFunctions';
import ViewShiftV2 from '@/views/schedule/dialogs/ViewShiftV2.vue';
import { PropType } from 'vue';
import { Employee, JobRole, Shift, Tag as ShiftTag } from '../../../../api/v1';
import Tag from '../../../components/shift/Tag.vue';
import DateSquareV2 from './DateSquareV2.vue';

export default {
  name: 'ShiftSummaryV2',

  components: {
    BubbleIcon,
    EmployeeAvatar,
    ViewShiftV2,
    Chevron,
    Tag,
    DateSquareV2,
  },

  props: {
    shift: {
      type: Object as PropType<Shift>,
      required: true,
    },
    showAsShift: {
      type: Boolean,
      default: false,
    },
    showBorder: {
      type: Boolean,
      default: true,
    },
    showDate: {
      type: Boolean,
      default: true,
    },
    hideTags: {
      type: Boolean,
      default: false,
    },
    extraDetails: {
      type: Boolean,
      default: false,
    },
    count: {
      type: Number,
      default: 1,
    },
    clickable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'lg',
      validator: (prop) => ['sm', 'md', 'lg', 'xl'].includes(prop),
    },
    showEmployee: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      AvatarSizes,
      Icon,
      showViewShift: false,
      tags: realtimeQuery(Entity.Tag).fetch(),
      locations: realtimeQuery(Entity.Location).fetch(),
      jobRoles: realtimeQuery(Entity.JobRole).fetch(),
      shiftSwaps: realtimeQuery(Entity.ShiftSwap).empty(),
      employees: realtimeQuery(Entity.Employee).empty(),
    };
  },

  computed: {
    scheduleSettings: () => store.state.settings.scheduleSettings,
    initialising(): boolean {
      return (
        this.tags.isLoading ||
        this.locations.isLoading ||
        this.jobRoles.isLoading ||
        this.employees.isLoading
      );
    },
    isBeingSwapped(): boolean {
      return this.shiftSwaps.data.some(
        (ss) => ss.shiftId === this.shift.id && isPendingShiftSwap(ss),
      );
    },
    filteredShiftTags(): ShiftTag[] {
      return this.tags.data.filter(
        (t) =>
          this.shift.tagIds.includes(t.id) &&
          tagVisibilityFilter(userCan.manageShifts())(t),
      );
    },
    jobRole(): JobRole | null {
      return getEntity(this.shift.jobRoleId, this.jobRoles.data);
    },
    employee(): undefined | Employee {
      if (
        !this.showEmployee ||
        !this.shift.employeeId ||
        this.employees.isLoading
      ) {
        return undefined;
      }
      return getEntity(this.shift.employeeId, this.employees.data);
    },
  },

  watch: {
    scheduleSettings() {
      if (this.scheduleSettings.shiftSwappingEnabled) {
        this.shiftSwaps = realtimeQuery(Entity.ShiftSwap)
          .where('shiftId', 'eq', this.shift.id)
          .fetch();
      }
    },
  },

  mounted() {
    if (this.showEmployee && this.shift.employeeId) {
      this.employees = realtimeQuery(Entity.Employee)
        .where('id', 'eq', this.shift.employeeId)
        .fetch();
    } else {
      this.employees = realtimeQuery(Entity.Employee).empty(false);
    }
  },

  methods: {
    isAssigned,
    totalShiftBreakDuration,
    presentShiftTimes,
    getEntity,
    shiftJobRoleIsVisible,

    toggleShowShift() {
      this.showViewShift = !this.showViewShift;
      this.$emit('click');
    },
  },
};
