import {
  EmployeeLeaveSummaryLeaveEntitlementUnitEnum,
  LeavePeriodTypeLeaveAllowanceUnitEnum,
  LeaveTypeDisplayIconEnum,
  LeaveTypeLeaveAllowanceTypeEnum,
  LeaveTypeLeaveAllowanceUnitEnum,
} from '../../../api/v1';

export const LeaveEntitlementUnitEnum = {
  ...EmployeeLeaveSummaryLeaveEntitlementUnitEnum,
  ...LeaveTypeLeaveAllowanceUnitEnum,
  ...LeavePeriodTypeLeaveAllowanceUnitEnum,
} as const;
export type LeaveEntitlementUnitType =
  typeof LeaveEntitlementUnitEnum[keyof typeof LeaveEntitlementUnitEnum];

const LeaveTypeLeaveAllowanceType = LeaveTypeLeaveAllowanceTypeEnum;
export type LeaveTypeLeaveAllowanceTypeType =
  typeof LeaveTypeLeaveAllowanceType[keyof typeof LeaveTypeLeaveAllowanceType];

export const DefaultAccrualRate = 12.07;
export const DefaultAllowance = 25;

export type LeaveTypeIconType = `${LeaveTypeDisplayIconEnum}`;

export const LeaveTypeIconValues: LeaveTypeIconType[] = Object.values(
  LeaveTypeDisplayIconEnum,
);
export const DefaultDisplayIcon = LeaveTypeDisplayIconEnum.Plane;
