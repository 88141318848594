/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LeaveAllowanceChunkData } from './LeaveAllowanceChunkData';
import {
    LeaveAllowanceChunkDataFromJSON,
    LeaveAllowanceChunkDataFromJSONTyped,
    LeaveAllowanceChunkDataToJSON,
    LeaveAllowanceChunkDataToJSONTyped,
} from './LeaveAllowanceChunkData';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * Create a new leave request
 * @export
 * @interface CreateLeaveRequestData
 */
export interface CreateLeaveRequestData {
    /**
     * 
     * @type {number}
     * @memberof CreateLeaveRequestData
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateLeaveRequestData
     */
    leaveTypeId: number;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof CreateLeaveRequestData
     */
    startDate: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof CreateLeaveRequestData
     */
    endDate: ShiftiePlainDate;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeaveRequestData
     */
    startsMidday?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CreateLeaveRequestData
     */
    endsMidday?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CreateLeaveRequestData
     */
    requestNotes?: string;
    /**
     * Optionally specify leave allowance chunks for a leave request. <br><small>Note: If not present in the request, leave allowance chunks will be generated automatically.</small>
     * @type {Array<LeaveAllowanceChunkData>}
     * @memberof CreateLeaveRequestData
     */
    leaveAllowanceChunks?: Array<LeaveAllowanceChunkData>;
}

/**
 * Check if a given object implements the CreateLeaveRequestData interface.
 */
export function instanceOfCreateLeaveRequestData(value: object): value is CreateLeaveRequestData {
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('leaveTypeId' in value) || value['leaveTypeId'] === undefined) return false;
    if (!('startDate' in value) || value['startDate'] === undefined) return false;
    if (!('endDate' in value) || value['endDate'] === undefined) return false;
    return true;
}

export function CreateLeaveRequestDataFromJSON(json: any): CreateLeaveRequestData {
    return CreateLeaveRequestDataFromJSONTyped(json, false);
}

export function CreateLeaveRequestDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateLeaveRequestData {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'leaveTypeId': json['leaveTypeId'],
        'startDate': ShiftiePlainDateFromJSON(json['startDate']),
        'endDate': ShiftiePlainDateFromJSON(json['endDate']),
        'startsMidday': json['startsMidday'] == null ? undefined : json['startsMidday'],
        'endsMidday': json['endsMidday'] == null ? undefined : json['endsMidday'],
        'requestNotes': json['requestNotes'] == null ? undefined : json['requestNotes'],
        'leaveAllowanceChunks': json['leaveAllowanceChunks'] == null ? undefined : ((json['leaveAllowanceChunks'] as Array<any>).map(LeaveAllowanceChunkDataFromJSON)),
    };
}

  export function CreateLeaveRequestDataToJSON(json: any): CreateLeaveRequestData {
      return CreateLeaveRequestDataToJSONTyped(json, false);
  }

  export function CreateLeaveRequestDataToJSONTyped(value?: CreateLeaveRequestData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'employeeId': value['employeeId'],
        'leaveTypeId': value['leaveTypeId'],
        'startDate': ShiftiePlainDateToJSON(value['startDate']),
        'endDate': ShiftiePlainDateToJSON(value['endDate']),
        'startsMidday': value['startsMidday'],
        'endsMidday': value['endsMidday'],
        'requestNotes': value['requestNotes'],
        'leaveAllowanceChunks': value['leaveAllowanceChunks'] == null ? undefined : ((value['leaveAllowanceChunks'] as Array<any>).map(LeaveAllowanceChunkDataToJSON)),
    };
}

