<template>
  <div
    v-if="!initialising"
    class="flex flex-center flex-column p-4 text-center"
  >
    <BubbleIcon
      class="pb-2"
      :icon="data.icon.class"
      :background-class="data.icon.color"
      :foreground-class="data.icon.color"
      :size="'xxlarge'"
    />
    <h2 class="pb-2">
      {{ $t(data.header) }}
    </h2>
    <p class="text-14 text-gray-600">
      {{ $t(data.subHeader) }}
    </p>
    <Button
      color="pink"
      :disabled="loading"
      @click="data.buttonAction()"
    >
      {{ $t(data.buttonText) }}
    </Button>
    <a
      v-if="data.subAction"
      class="mt-4"
      @click="!loading ? data.subAction() : null"
    >
      {{ $t(data.subActionText) }}
    </a>
  </div>
</template>

<script>
import Button from '@/components/buttons/Button';
import BubbleIcon from '@/components/interface/BubbleIcon';
import { links } from '@/lang/urlLinks';
import { EventsEnum } from '@/lib/enum/events';
import { Icon } from '@/lib/enum/Icon';
import { userCan } from '@/lib/permission/userCan';
import { Entity } from '@/lib/realtime/RealtimeEntity';
import { realtimeFindEntity } from '@/lib/realtime/weak/realtimeFunctions';
import { goToLink, redirect } from '@/router/router';
import { routes } from '@/router/routes';
import store from '@/store';
import {
  BillingSubscriptionTypeEnum,
  CompanyStatusEnum,
} from '../../../../api/v1';

export default {
  name: 'CompanyStatus',

  components: {
    BubbleIcon,
    Button,
  },

  data() {
    return {
      Icon,
      loading: false,
      initialising: true,
      company: null,
    };
  },

  computed: {
    currentSubscription: () => store.state.billing.currentSubscription,
    permissions: () => store.state.permissions,

    data() {
      if (this.company.status === CompanyStatusEnum.Suspended) {
        return {
          icon: {
            class: Icon.UserXmark,
            color: 'red',
          },
          header: 'heading.companyStatus.suspended',
          subHeader: userCan.manageBilling()
            ? 'subHeading.companyStatus.suspendedOwner'
            : 'subHeading.companyStatus.suspended',
          buttonText: userCan.manageBilling()
            ? 'button.contactUs'
            : 'button.gotIt',
          buttonAction: userCan.manageBilling()
            ? this.supportTicket
            : this.logout,
          subAction: userCan.manageBilling() ? this.logout : null,
          subActionText: userCan.manageBilling() ? 'button.logout' : null,
        };
      }

      if (
        this.currentSubscription?.type === BillingSubscriptionTypeEnum.Trial
      ) {
        return {
          icon: {
            class: Icon.Hourglass,
            color: 'blue',
          },
          header: 'heading.companyStatus.trialEnded',
          subHeader: userCan.manageBilling()
            ? 'subHeading.companyStatus.trialEndedOwner'
            : 'subHeading.companyStatus.trialEnded',
          buttonText: userCan.manageBilling()
            ? 'button.addBilling'
            : 'button.gotIt',
          buttonAction: userCan.manageBilling()
            ? this.restoreAccount
            : this.logout,
          subAction: userCan.manageBilling() ? this.logout : null,
          subActionText: userCan.manageBilling() ? 'button.logout' : null,
        };
      }

      return {
        icon: {
          class: Icon.UserLock,
          color: 'red',
        },
        header: 'heading.companyStatus.locked',
        subHeader: userCan.manageBilling()
          ? 'subHeading.companyStatus.lockedOwner'
          : 'subHeading.companyStatus.locked',
        buttonText: userCan.manageBilling()
          ? 'button.billingDetails'
          : 'button.gotIt',
        buttonAction: userCan.manageBilling()
          ? this.restoreAccount
          : this.logout,
        subAction: userCan.manageBilling() ? this.logout : null,
        subActionText: userCan.manageBilling() ? 'button.logout' : null,
      };
    },
  },

  async mounted() {
    this.company = await realtimeFindEntity(Entity.Company, this.$companyId);
    this.initialising = false;
  },

  methods: {
    logout() {
      redirect(routes.logout.route());
      this.closeDialog();
    },

    supportTicket() {
      goToLink(links.supportTicket);
    },

    restoreAccount() {
      this.$root.$emit(EventsEnum.ToggleRestoreDialog);
      this.closeDialog();
    },

    closeDialog() {
      this.$store.commit('SET_COMPANY_STATUS_MODAL', false);
    },
  },
};
</script>
