
import CollapsibleNav from '@/components/nav/common/CollapsibleNav.vue';
import { StorageEnum } from '@/lib/enum/StorageEnum';
import { NavSectionInterface } from '@/lib/navigation/helpers';
import { reportsNav } from '@/lib/navigation/reportsNav';
import { setLocalStorageItem } from '@/util/storageFunctions';

export default {
  name: 'ReportsNav',
  components: { CollapsibleNav },
  beforeRouteEnter(to, from, next) {
    setLocalStorageItem(StorageEnum.FromRoute, from.name);
    next();
  },
  computed: {
    nav(): NavSectionInterface[] {
      return [reportsNav.shiftCost(), reportsNav.logs()];
    },
  },
};
