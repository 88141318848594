import { LeaveEntitlementUnitType } from '@/lib/leave/leaveTypeTypes';
import spacetime from 'spacetime';
import { LeavePeriod, LeavePeriodType } from '../../../api/v1';

export const leavePeriodIncludesDate = (
  leavePeriod: LeavePeriod,
  date: Date | string,
  timezone: string,
): boolean => {
  if (
    !leavePeriod ||
    !Number.isFinite(date instanceof Date ? +date : Date.parse(date))
  ) {
    return false;
  }
  return spacetime(date, timezone).isBetween(
    leavePeriod.startDate.toDate(timezone),
    leavePeriod.endDate.toDate(timezone),
    true,
  );
};

export const findLeavePeriodByDate = (
  leavePeriods: LeavePeriod[],
  date: Date | string,
  timezone: string,
): LeavePeriod | null => {
  if (!Number.isFinite(date instanceof Date ? +date : Date.parse(date))) {
    return null;
  }

  return (
    leavePeriods.find((period) =>
      leavePeriodIncludesDate(
        period,
        spacetime(date, timezone).toNativeDate(),
        timezone,
      ),
    ) ?? null
  );
};

export const getLeavePeriodTypeAllowanceUnit = (
  leavePeriod: LeavePeriod,
  leaveTypeId: number,
): LeaveEntitlementUnitType =>
  leavePeriod.leavePeriodTypes.find(
    (t) => t.originalLeaveTypeId === leaveTypeId,
  )?.leaveAllowanceUnit;

export const leavePeriodHasType = (
  leavePeriod: LeavePeriod,
  leaveTypeId: number,
): boolean =>
  !!leavePeriod.leavePeriodTypes.find(
    (lpt) => lpt.originalLeaveTypeId === leaveTypeId,
  );

export const getLeavePeriodType = (
  leavePeriod: LeavePeriod,
  leaveTypeId: number,
): LeavePeriodType | undefined => {
  if (!leaveTypeId || !leavePeriod?.leavePeriodTypes.length) {
    return undefined;
  }

  return leavePeriod.leavePeriodTypes.find(
    (periodType) => periodType.originalLeaveTypeId === leaveTypeId,
  );
};
