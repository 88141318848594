/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface CreateScheduleTemplateData
 */
export interface CreateScheduleTemplateData {
    /**
     * 
     * @type {number}
     * @memberof CreateScheduleTemplateData
     */
    scheduleId: number;
    /**
     * 
     * @type {string}
     * @memberof CreateScheduleTemplateData
     */
    name: string;
    /**
     * The start of the period represented by the template
     * @type {ShiftiePlainDate}
     * @memberof CreateScheduleTemplateData
     */
    startDate: ShiftiePlainDate;
    /**
     * The end of the period represented by the template (inclusive)
     * @type {ShiftiePlainDate}
     * @memberof CreateScheduleTemplateData
     */
    endDate: ShiftiePlainDate;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateScheduleTemplateData
     */
    shiftIds: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateScheduleTemplateData
     */
    workPatternOverrideIds?: Array<number>;
}

/**
 * Check if a given object implements the CreateScheduleTemplateData interface.
 */
export function instanceOfCreateScheduleTemplateData(value: object): value is CreateScheduleTemplateData {
    if (!('scheduleId' in value) || value['scheduleId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('startDate' in value) || value['startDate'] === undefined) return false;
    if (!('endDate' in value) || value['endDate'] === undefined) return false;
    if (!('shiftIds' in value) || value['shiftIds'] === undefined) return false;
    return true;
}

export function CreateScheduleTemplateDataFromJSON(json: any): CreateScheduleTemplateData {
    return CreateScheduleTemplateDataFromJSONTyped(json, false);
}

export function CreateScheduleTemplateDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateScheduleTemplateData {
    if (json == null) {
        return json;
    }
    return {
        
        'scheduleId': json['scheduleId'],
        'name': json['name'],
        'startDate': ShiftiePlainDateFromJSON(json['startDate']),
        'endDate': ShiftiePlainDateFromJSON(json['endDate']),
        'shiftIds': json['shiftIds'],
        'workPatternOverrideIds': json['workPatternOverrideIds'] == null ? undefined : json['workPatternOverrideIds'],
    };
}

  export function CreateScheduleTemplateDataToJSON(json: any): CreateScheduleTemplateData {
      return CreateScheduleTemplateDataToJSONTyped(json, false);
  }

  export function CreateScheduleTemplateDataToJSONTyped(value?: CreateScheduleTemplateData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'scheduleId': value['scheduleId'],
        'name': value['name'],
        'startDate': ShiftiePlainDateToJSON(value['startDate']),
        'endDate': ShiftiePlainDateToJSON(value['endDate']),
        'shiftIds': value['shiftIds'],
        'workPatternOverrideIds': value['workPatternOverrideIds'],
    };
}

