import { entityConfig as weakEntityConfig } from '@/lib/realtime/weak/entityConfig';
import { hardCodedHandlers } from '@/lib/store/otherEntities';
import { SocketService } from '@/plugins/socket-service/SocketService';
import {
  SocketServiceEventType,
  WebsocketControlEvent,
} from '@/plugins/socket-service/socketTypes';
import { Store } from 'vuex';

export const initialiseWebsocket = (
  store: Store<any>,
  socket: SocketService,
) => {
  socket.init(store);

  // Register all our entity handlers
  Object.keys(hardCodedHandlers).forEach((key) => {
    socket.registerHandler(key, `hardCoded-${key}`, hardCodedHandlers[key]);
  });

  socket.addEventListener(SocketServiceEventType.CONTROL, ({ event }) => {
    if (event === WebsocketControlEvent.NewDeployment) {
      store.dispatch('reloadRequired', true);
    } else if (event === WebsocketControlEvent.ClientAuthenticationSuccessful) {
      socket.subscribeToChannels([
        ...socket.getChannels(),
        ...Array.from(new Set([...Object.keys(weakEntityConfig)])),
      ]);
    }
  });
};
