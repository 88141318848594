
import NavItem from '@/components/nav/common/NavItem.vue';
import { Icon } from '@/lib/enum/Icon';
import { NavItemInterface } from '@/lib/navigation/helpers';
import { PropType } from 'vue';

export default {
  name: 'NavSection',
  components: { NavItem },
  props: {
    heading: {
      type: String,
      default: '',
    },
    headingStyle: {
      type: String as PropType<'light' | 'strong'>,
      default: 'light',
    },
    icon: {
      type: String as () => Icon,
      default: null,
    },
    items: {
      type: Array as () => NavItemInterface[],
      required: true,
    },
  },
  methods: {
    activeRoute(item: NavItemInterface): boolean {
      if (!item.to) {
        return false;
      }
      return item.to.name === this.$route.name;
    },
  },
};
