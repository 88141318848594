/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface LeaveAllowanceChunkData
 */
export interface LeaveAllowanceChunkData {
    /**
     * Which date this leave allowance chunk covers. <br><small>Note: dates should be unique for each chunk of a leave request.</small>
     * @type {ShiftiePlainDate}
     * @memberof LeaveAllowanceChunkData
     */
    date: ShiftiePlainDate;
    /**
     * Required when <code>allowanceUsedInHours</code> is not supplied.
     * @type {number}
     * @memberof LeaveAllowanceChunkData
     */
    allowanceUsedInDays?: number;
    /**
     * Required when <code>allowanceUsedInDays</code> is not supplied.
     * @type {number}
     * @memberof LeaveAllowanceChunkData
     */
    allowanceUsedInHours?: number;
    /**
     * Whether or not this leave allowance chunk affects the employee's leave allowance.
     * @type {boolean}
     * @memberof LeaveAllowanceChunkData
     */
    allowanceAffected: boolean;
}

/**
 * Check if a given object implements the LeaveAllowanceChunkData interface.
 */
export function instanceOfLeaveAllowanceChunkData(value: object): value is LeaveAllowanceChunkData {
    if (!('date' in value) || value['date'] === undefined) return false;
    if (!('allowanceAffected' in value) || value['allowanceAffected'] === undefined) return false;
    return true;
}

export function LeaveAllowanceChunkDataFromJSON(json: any): LeaveAllowanceChunkData {
    return LeaveAllowanceChunkDataFromJSONTyped(json, false);
}

export function LeaveAllowanceChunkDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): LeaveAllowanceChunkData {
    if (json == null) {
        return json;
    }
    return {
        
        'date': ShiftiePlainDateFromJSON(json['date']),
        'allowanceUsedInDays': json['allowanceUsedInDays'] == null ? undefined : json['allowanceUsedInDays'],
        'allowanceUsedInHours': json['allowanceUsedInHours'] == null ? undefined : json['allowanceUsedInHours'],
        'allowanceAffected': json['allowanceAffected'],
    };
}

  export function LeaveAllowanceChunkDataToJSON(json: any): LeaveAllowanceChunkData {
      return LeaveAllowanceChunkDataToJSONTyped(json, false);
  }

  export function LeaveAllowanceChunkDataToJSONTyped(value?: LeaveAllowanceChunkData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'date': ShiftiePlainDateToJSON(value['date']),
        'allowanceUsedInDays': value['allowanceUsedInDays'],
        'allowanceUsedInHours': value['allowanceUsedInHours'],
        'allowanceAffected': value['allowanceAffected'],
    };
}

