<template>
  <div class="contents">
    <div
      ref="mobileHeaderAlternative"
      class="contents"
    >
      <slot name="mobileHeaderAlternative" />
    </div>
    <div
      ref="mobileHeaderAppend"
      class="contents"
    >
      <slot name="mobileHeaderAppend" />
    </div>
  </div>
</template>

<script>
import MobileHeader from '@/lib/mobileHeader';

/**
 * This component allows you to automatically customise the MobileHeader component from within the template of the component
 *
 * Use the mobileHeaderAlternative slot to replace the heading with something custom
 *
 * Use the mobileHeaderAppend to add a subcomponent below the heading
 *
 */
export default {
  name: 'MobileHeaderInner',
  watch: {
    $slots: {
      deep: true,
      immediate: true,
      handler() {
        this.setUseAlternative();
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setUseAlternative();
      document
        .getElementById('mobile-header')
        .prepend(this.$refs.mobileHeaderAlternative);
      document
        .getElementById('mobile-header')
        .appendChild(this.$refs.mobileHeaderAppend);
    });
  },
  beforeDestroy() {
    this.$refs.mobileHeaderAppend.parentNode.removeChild(
      this.$refs.mobileHeaderAppend,
    );
    this.$refs.mobileHeaderAlternative.parentNode.removeChild(
      this.$refs.mobileHeaderAlternative,
    );
  },
  methods: {
    setUseAlternative() {
      if (this.$slots.mobileHeaderAlternative) {
        MobileHeader.useAlternative(true);
      } else {
        MobileHeader.useAlternative(false);
      }
    },
  },
};
</script>
