
import PinkShiftieCutOutSquareSvg from '@/assets/PinkShiftieCutOutSquareSvg.vue';
import LockedFeatureDialog from '@/components/dialog/LockedFeatureDialog.vue';
import EmployeeAvatar from '@/components/employee/EmployeeAvatar.vue';
import SidebarItem from '@/components/nav/common/SidebarItem.vue';
import DesktopMoreNav from '@/components/nav/DesktopMoreNav.vue';
import SupportNav from '@/components/nav/SupportNav.vue';
import UserNav from '@/components/nav/UserNav.vue';
import StatBadge from '@/components/stats/StatBadge.vue';
import {
  isFeatureFlagRoute,
  isSidebarItemAdditionallyHighlighted,
  isVisibleRoute,
  NavItemInterface,
} from '@/lib/navigation/helpers';
import { navItems } from '@/lib/navigation/navItems';
import { companyCanAccess } from '@/lib/permission/companyAccessFeatures';
import { redirectDebounced } from '@/router/router';
import { routes } from '@/router/routes';
import store from '@/store';
import { windowSize } from '@/util/domFunctions';
import { limitStat } from '@/util/presenters';
import { BillingPlanFeaturesEnum } from '../../../api/v1';

const Nav = {
  Support: 'support',
  User: 'user',
  More: 'more',
} as const;
type NavValue = typeof Nav[keyof typeof Nav];

// Shiftie logo (56px + 16px) + nav section gap (32px) + user menu toggle (72px)
const SIDEBAR_HEIGHT = 56 + 16 + 32 + 72;
// Item height (40px) + margin (8px)
const NAV_ITEM_HEIGHT = 40 + 8;

export default {
  components: {
    StatBadge,
    SidebarItem,
    DesktopMoreNav,
    LockedFeatureDialog,
    SupportNav,
    UserNav,
    EmployeeAvatar,
    PinkShiftieCutOutSquareSvg,
  },

  data() {
    return {
      Nav,
      routes,

      showNav: null as NavValue,
      lockedFeatureDialog: null as BillingPlanFeaturesEnum,
    };
  },

  computed: {
    viewHeight: () => windowSize.height,
    loggedInEmployee: () => store.getters.loggedInEmployee,
    currentCompany: () => store.getters.currentCompany,
    totalRequests: () => store.getters['requests/totalRequests'],
    authenticatedUser: () => store.getters.user,

    notificationItems(): NavItemInterface[] {
      return [navItems.requests(this.totalRequests)];
    },

    notificationStat(): string {
      return `${limitStat(this.totalRequests, 9)}`;
    },

    generalItems(): NavItemInterface[] {
      return (
        [
          navItems.dashboardV2(),
          navItems.scheduleV2(),
          navItems.timeClock(),
          navItems.timesheets(),
          navItems.team(),
          navItems.reports(),
        ] as NavItemInterface[]
      ).filter(isVisibleRoute(false, this.currentCompany));
    },

    toolItems(): NavItemInterface[] {
      return [navItems.companySettings(), ...this.notificationItems].filter(
        isVisibleRoute(false, this.currentCompany),
      );
    },

    staticItems(): NavItemInterface[] {
      return [navItems.supportMenu(() => this.toggleNav(Nav.Support))].filter(
        isVisibleRoute(false, this.currentCompany),
      );
    },

    moreNavItem(): NavItemInterface {
      return navItems.desktopMoreMenu(() => this.toggleNav(Nav.More));
    },

    minHeight(): number {
      return (
        SIDEBAR_HEIGHT +
        NAV_ITEM_HEIGHT * (this.generalItems.length + this.staticItems.length)
      );
    },

    visibleGeneralItems(): NavItemInterface[] {
      if (!this.loggedInEmployee) return [];

      return this.viewHeight < this.minHeight
        ? this.generalItems.slice(
            0,
            Math.max(
              -Math.ceil((this.minHeight - this.viewHeight) / NAV_ITEM_HEIGHT) -
                1,
              2 - this.generalItems.length, // Keep at least 2 items
            ),
          )
        : this.generalItems;
    },

    visibleToolItems(): NavItemInterface[] {
      if (!this.loggedInEmployee) return [];

      return this.viewHeight <
        this.minHeight + NAV_ITEM_HEIGHT * this.toolItems.length
        ? []
        : this.toolItems;
    },

    moreItems(): NavItemInterface[] {
      if (!this.loggedInEmployee) return [];

      const visibleGeneralItemsIds = this.visibleGeneralItems.map((i) => i.id);

      const visibleToolItemsIds = this.visibleToolItems.map((i) => i.id);
      const notificationItemsIds = this.notificationItems.map((i) => i.id);

      return [
        ...this.generalItems.filter(
          (i) => !visibleGeneralItemsIds.includes(i.id),
        ),
        ...this.toolItems.filter(
          (i) =>
            !notificationItemsIds.includes(i.id) &&
            !visibleToolItemsIds.includes(i.id),
        ),
      ];
    },

    isMoreNavHighlighted(): boolean {
      return this.moreItems.some((moreNavItem) => {
        const isMoreItemActive = moreNavItem.to?.name === this.$route.name;
        const isMoreItemAnyInnerPageActive =
          isSidebarItemAdditionallyHighlighted(moreNavItem, this.$route);
        return isMoreItemActive || isMoreItemAnyInnerPageActive;
      });
    },
  },

  methods: {
    isFeatureFlagRoute,
    redirectDebounced,
    companyCanAccess,

    openLockedFeatureDialog(feature: BillingPlanFeaturesEnum) {
      this.lockedFeatureDialog = feature;
    },

    toggleNav(nav: NavValue | null = null) {
      if (this.showNav === nav || !nav) {
        this.showNav = null;
        return;
      }
      this.showNav = nav;
    },

    navClick(item: NavItemInterface) {
      if (item.action) {
        return item.action();
      }

      this.toggleNav();
      if (item.feature && !companyCanAccess(item.feature)) {
        return this.openLockedFeatureDialog(item.feature);
      }
      return redirectDebounced(item.to, this.$route);
    },
  },
};
