/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { CreateTimesheetEntryDataTimesheetBreaksInner } from './CreateTimesheetEntryDataTimesheetBreaksInner';
import {
    CreateTimesheetEntryDataTimesheetBreaksInnerFromJSON,
    CreateTimesheetEntryDataTimesheetBreaksInnerFromJSONTyped,
    CreateTimesheetEntryDataTimesheetBreaksInnerToJSON,
    CreateTimesheetEntryDataTimesheetBreaksInnerToJSONTyped,
} from './CreateTimesheetEntryDataTimesheetBreaksInner';

/**
 * 
 * @export
 * @interface CreateTimesheetEntryData
 */
export interface CreateTimesheetEntryData {
    /**
     * 
     * @type {number}
     * @memberof CreateTimesheetEntryData
     */
    employeeId: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTimesheetEntryData
     */
    shiftId?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTimesheetEntryData
     */
    locationId?: number;
    /**
     * 
     * @type {number}
     * @memberof CreateTimesheetEntryData
     */
    jobRoleId?: number;
    /**
     * 
     * @type {Date}
     * @memberof CreateTimesheetEntryData
     */
    startedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof CreateTimesheetEntryData
     */
    endedAt: Date;
    /**
     * 
     * @type {Array<number>}
     * @memberof CreateTimesheetEntryData
     */
    tags?: Array<number> | null;
    /**
     * 
     * @type {string}
     * @memberof CreateTimesheetEntryData
     */
    notes?: string | null;
    /**
     * 
     * @type {Array<CreateTimesheetEntryDataTimesheetBreaksInner>}
     * @memberof CreateTimesheetEntryData
     */
    timesheetBreaks?: Array<CreateTimesheetEntryDataTimesheetBreaksInner>;
}

/**
 * Check if a given object implements the CreateTimesheetEntryData interface.
 */
export function instanceOfCreateTimesheetEntryData(value: object): value is CreateTimesheetEntryData {
    if (!('employeeId' in value) || value['employeeId'] === undefined) return false;
    if (!('startedAt' in value) || value['startedAt'] === undefined) return false;
    if (!('endedAt' in value) || value['endedAt'] === undefined) return false;
    return true;
}

export function CreateTimesheetEntryDataFromJSON(json: any): CreateTimesheetEntryData {
    return CreateTimesheetEntryDataFromJSONTyped(json, false);
}

export function CreateTimesheetEntryDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateTimesheetEntryData {
    if (json == null) {
        return json;
    }
    return {
        
        'employeeId': json['employeeId'],
        'shiftId': json['shiftId'] == null ? undefined : json['shiftId'],
        'locationId': json['locationId'] == null ? undefined : json['locationId'],
        'jobRoleId': json['jobRoleId'] == null ? undefined : json['jobRoleId'],
        'startedAt': (new Date(json['startedAt'])),
        'endedAt': (new Date(json['endedAt'])),
        'tags': json['tags'] == null ? undefined : json['tags'],
        'notes': json['notes'] == null ? undefined : json['notes'],
        'timesheetBreaks': json['timesheetBreaks'] == null ? undefined : ((json['timesheetBreaks'] as Array<any>).map(CreateTimesheetEntryDataTimesheetBreaksInnerFromJSON)),
    };
}

  export function CreateTimesheetEntryDataToJSON(json: any): CreateTimesheetEntryData {
      return CreateTimesheetEntryDataToJSONTyped(json, false);
  }

  export function CreateTimesheetEntryDataToJSONTyped(value?: CreateTimesheetEntryData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'employeeId': value['employeeId'],
        'shiftId': value['shiftId'],
        'locationId': value['locationId'],
        'jobRoleId': value['jobRoleId'],
        'startedAt': ((value['startedAt']).toISOString()),
        'endedAt': ((value['endedAt']).toISOString()),
        'tags': value['tags'],
        'notes': value['notes'],
        'timesheetBreaks': value['timesheetBreaks'] == null ? undefined : ((value['timesheetBreaks'] as Array<any>).map(CreateTimesheetEntryDataTimesheetBreaksInnerToJSON)),
    };
}

