var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',[_vm._l((_vm.nav),function(section,key){return [(!section.hide)?_c('ul',{key:key,staticClass:"pl-0"},[_c('li',{staticClass:"all:flex items-center cursor-pointer all:py-4 all:pr-4 text-16",on:{"click":function($event){return _vm.toggleSection(section.name, !_vm.navToggles[section.name])}}},[(section.icon)?_c('i',{staticClass:"mr-4 w-[20px] all:flex items-center justify-center text-gray-700",class:section.icon}):_vm._e(),_c('span',{staticClass:"text-gray-900",class:{
            'font-bold': _vm.navToggles[section.name],
            'opacity-50':
              !section.children.some(_vm.searchNavItem(_vm.query)) &&
              !_vm.stringSearch(section.name, _vm.query),
          }},[_vm._v(" "+_vm._s(section.name)+" ")]),_c('Chevron',{staticClass:"ml-auto font-light text-14 text-gray-700",attrs:{"toggled":_vm.navToggles[section.name]}})],1),(_vm.navToggles[section.name])?_c('li',{staticClass:"all:pl-[10px]"},[_c('ul',{staticClass:"all:flex flex-col gap-2 pl-[10px] border-l-2 border-purple-100"},[_vm._l((section.children),function(item,index){return [(!item.hide)?_c('NavItem',{key:index,attrs:{"item":item,"show-icon":false,"nav-click":(navItem) => (!navItem.enabled ? null : _vm.redirect(navItem.to)),"included-in-search":_vm.searchNavItem(_vm.query)(item) ||
                _vm.stringSearch(section.name, _vm.query)}}):_vm._e()]})],2)]):_vm._e()]):_vm._e()]})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }