/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateTimesheetEntryDataTimesheetBreaksInner
 */
export interface UpdateTimesheetEntryDataTimesheetBreaksInner {
    /**
     * An optional identifier for the break. Supply this to update an existing break.
     * @type {number}
     * @memberof UpdateTimesheetEntryDataTimesheetBreaksInner
     */
    id?: number;
    /**
     * 
     * @type {Date}
     * @memberof UpdateTimesheetEntryDataTimesheetBreaksInner
     */
    startedAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof UpdateTimesheetEntryDataTimesheetBreaksInner
     */
    endedAt: Date;
    /**
     * 
     * @type {number}
     * @memberof UpdateTimesheetEntryDataTimesheetBreaksInner
     */
    scheduledBreakId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateTimesheetEntryDataTimesheetBreaksInner
     */
    paid: boolean;
}

/**
 * Check if a given object implements the UpdateTimesheetEntryDataTimesheetBreaksInner interface.
 */
export function instanceOfUpdateTimesheetEntryDataTimesheetBreaksInner(value: object): value is UpdateTimesheetEntryDataTimesheetBreaksInner {
    if (!('startedAt' in value) || value['startedAt'] === undefined) return false;
    if (!('endedAt' in value) || value['endedAt'] === undefined) return false;
    if (!('paid' in value) || value['paid'] === undefined) return false;
    return true;
}

export function UpdateTimesheetEntryDataTimesheetBreaksInnerFromJSON(json: any): UpdateTimesheetEntryDataTimesheetBreaksInner {
    return UpdateTimesheetEntryDataTimesheetBreaksInnerFromJSONTyped(json, false);
}

export function UpdateTimesheetEntryDataTimesheetBreaksInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateTimesheetEntryDataTimesheetBreaksInner {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'] == null ? undefined : json['id'],
        'startedAt': (new Date(json['startedAt'])),
        'endedAt': (new Date(json['endedAt'])),
        'scheduledBreakId': json['scheduledBreakId'] == null ? undefined : json['scheduledBreakId'],
        'paid': json['paid'],
    };
}

  export function UpdateTimesheetEntryDataTimesheetBreaksInnerToJSON(json: any): UpdateTimesheetEntryDataTimesheetBreaksInner {
      return UpdateTimesheetEntryDataTimesheetBreaksInnerToJSONTyped(json, false);
  }

  export function UpdateTimesheetEntryDataTimesheetBreaksInnerToJSONTyped(value?: UpdateTimesheetEntryDataTimesheetBreaksInner | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'startedAt': ((value['startedAt']).toISOString()),
        'endedAt': ((value['endedAt']).toISOString()),
        'scheduledBreakId': value['scheduledBreakId'],
        'paid': value['paid'],
    };
}

