var render = function render(){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"menu-item relative",class:{
    'opacity-50': _vm.disabled,
    'router-link-active': _vm.isActive,
  }},[_c('v-tooltip',{attrs:{"right":"","content-class":"arrow-left"},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('span',_vm._g({staticClass:"relative",on:{"click":function($event){return _vm.$emit('click', _vm.item)}}},on),[(_vm.item.stat)?_c('StatBadge',{staticClass:"right-3 top-1"},[_vm._v(" "+_vm._s(_vm.item.stat)+" ")]):_vm._e(),_c('i',{class:_vm.item.icon}),(_vm.isFeatureFlagRoute(_vm.item))?_c('span',{staticClass:"bg-blue text-white text-8 leading-3 rounded-xl all:px-1 font-weight-bold absolute bottom-2 right-2"},[_vm._v(" "+_vm._s(_vm.$tc('label.beta').toUpperCase())+" ")]):_vm._e(),(_vm.item.to && !_vm.disabled)?_c('router-link',{staticClass:"absolute top-0 bottom-0 left-0 right-0",class:{
            'router-link-active': _vm.isSidebarItemAdditionallyHighlighted(
              _vm.item,
              _vm.$route,
            ),
          },attrs:{"event":"","to":_vm.item.to?.name === _vm.$route.name
              ? { ..._vm.item.to, query: { ..._vm.$route.query } }
              : _vm.item.to}}):_vm._e()],1)]}}])},[_vm._v(" "+_vm._s(_vm.item.label)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }