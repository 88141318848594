/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { PushSubscription } from './PushSubscription';
import {
    PushSubscriptionFromJSON,
    PushSubscriptionFromJSONTyped,
    PushSubscriptionToJSON,
    PushSubscriptionToJSONTyped,
} from './PushSubscription';

/**
 * 
 * @export
 * @interface CreatePushSubscriptionResponse
 */
export interface CreatePushSubscriptionResponse {
    /**
     * 
     * @type {PushSubscription}
     * @memberof CreatePushSubscriptionResponse
     */
    data: PushSubscription;
}

/**
 * Check if a given object implements the CreatePushSubscriptionResponse interface.
 */
export function instanceOfCreatePushSubscriptionResponse(value: object): value is CreatePushSubscriptionResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreatePushSubscriptionResponseFromJSON(json: any): CreatePushSubscriptionResponse {
    return CreatePushSubscriptionResponseFromJSONTyped(json, false);
}

export function CreatePushSubscriptionResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreatePushSubscriptionResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': PushSubscriptionFromJSON(json['data']),
    };
}

  export function CreatePushSubscriptionResponseToJSON(json: any): CreatePushSubscriptionResponse {
      return CreatePushSubscriptionResponseToJSONTyped(json, false);
  }

  export function CreatePushSubscriptionResponseToJSONTyped(value?: CreatePushSubscriptionResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': PushSubscriptionToJSON(value['data']),
    };
}

