/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Upload } from './Upload';
import {
    UploadFromJSON,
    UploadFromJSONTyped,
    UploadToJSON,
    UploadToJSONTyped,
} from './Upload';

/**
 * 
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    id: number;
    /**
     * 
     * @type {number}
     * @memberof Document
     */
    companyId: number;
    /**
     * The ID of the employee who uploaded this document
     * @type {number}
     * @memberof Document
     */
    uploaderId: number | null;
    /**
     * The ID of the Upload entity, previously created via the /uploads endpoint
     * @type {number}
     * @memberof Document
     */
    uploadId: number | null;
    /**
     * 
     * @type {string}
     * @memberof Document
     */
    name: string;
    /**
     * Whether the document should be visible to all employees
     * @type {boolean}
     * @memberof Document
     */
    visibleToAll: boolean;
    /**
     * Any specific employees who should be able to view this document
     * @type {Array<number>}
     * @memberof Document
     */
    employeeIds: Array<number>;
    /**
     * Any employees with these job roles should be able to view this document
     * @type {Array<number>}
     * @memberof Document
     */
    jobRoleIds: Array<number>;
    /**
     * Indicates whether the document must be read by employees, and if so, by when
     * @type {Date}
     * @memberof Document
     */
    readDeadline: Date | null;
    /**
     * The number of employees who have marked this document as 'read'.
     * @type {number}
     * @memberof Document
     */
    readCount: number;
    /**
     * 
     * @type {Date}
     * @memberof Document
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof Document
     */
    updatedAt: Date | null;
    /**
     * 
     * @type {Upload}
     * @memberof Document
     */
    upload: Upload;
}

/**
 * Check if a given object implements the Document interface.
 */
export function instanceOfDocument(value: object): value is Document {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('companyId' in value) || value['companyId'] === undefined) return false;
    if (!('uploaderId' in value) || value['uploaderId'] === undefined) return false;
    if (!('uploadId' in value) || value['uploadId'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('visibleToAll' in value) || value['visibleToAll'] === undefined) return false;
    if (!('employeeIds' in value) || value['employeeIds'] === undefined) return false;
    if (!('jobRoleIds' in value) || value['jobRoleIds'] === undefined) return false;
    if (!('readDeadline' in value) || value['readDeadline'] === undefined) return false;
    if (!('readCount' in value) || value['readCount'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    if (!('upload' in value) || value['upload'] === undefined) return false;
    return true;
}

export function DocumentFromJSON(json: any): Document {
    return DocumentFromJSONTyped(json, false);
}

export function DocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Document {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'companyId': json['companyId'],
        'uploaderId': json['uploaderId'],
        'uploadId': json['uploadId'],
        'name': json['name'],
        'visibleToAll': json['visibleToAll'],
        'employeeIds': json['employeeIds'],
        'jobRoleIds': json['jobRoleIds'],
        'readDeadline': (json['readDeadline'] == null ? null : new Date(json['readDeadline'])),
        'readCount': json['readCount'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
        'upload': UploadFromJSON(json['upload']),
    };
}

  export function DocumentToJSON(json: any): Document {
      return DocumentToJSONTyped(json, false);
  }

  export function DocumentToJSONTyped(value?: Document | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'companyId': value['companyId'],
        'uploaderId': value['uploaderId'],
        'uploadId': value['uploadId'],
        'name': value['name'],
        'visibleToAll': value['visibleToAll'],
        'employeeIds': value['employeeIds'],
        'jobRoleIds': value['jobRoleIds'],
        'readDeadline': (value['readDeadline'] == null ? null : (value['readDeadline'] as any).toISOString()),
        'readCount': value['readCount'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
        'upload': UploadToJSON(value['upload']),
    };
}

