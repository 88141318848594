import i18n from '@/i18n';
import { PlainDate } from '@/lib/date-time/PlainDate';
import { MonthsLong } from '@/lib/enum/MonthsLong';
import { earliestDate, isMidnight, latestDate } from '@/util/dateArithmetic';
import spacetime from 'spacetime';
// eslint-disable-next-line import/no-unresolved
import { TimeUnit } from 'spacetime/types/constraints';
// Do not import from '../../api/v1' as it will cause a circular dependency
import { CompanySettingStartOfWeekEnum } from '../../api/v1/models/CompanySetting';

/**
 * Converts a native Date object into an ISO short date string, in the format YYYY-MM-DD
 *
 * @return string In the format YYYY-MM-DD
 */
export const toISOShortString = (date: Date, timezone: string): string => {
  return spacetime(date, timezone).format('iso-short');
};

export const parseStringToDate = (dateString: string, timezone: string): Date =>
  PlainDate.from(dateString).toDate(timezone);

/**
 * Conditionally show the end date of an entity, where the end date is set
 */
export const presentPeriod = (start: string, end: string) =>
  end === start ? start : `${start} - ${end}`;

// The Shiftie API uses specific indexes to represent days for things like Work Patterns,
// so keep a static map of them here. It's important that Sunday = 0.
export const indexToDayMap = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

// Get a translated list of days of week options formatted for use in select elements
export const dayOfWeekOptions = (
  short: boolean = false,
): { id: string; name: string }[] => {
  const length = short ? 'short' : 'long';
  return Object.values(indexToDayMap).map((name) => ({
    id: name,
    name: i18n.tc(`day.${length}.${name.toLowerCase()}`),
  }));
};

// A list of week day options suitable for using in a recurrence rule
export const recurrenceRuleDayOfWeekOptions = () =>
  Object.values(indexToDayMap).map((name) => ({
    id: name.substr(0, 2).toUpperCase(),
    name: i18n.t(`day.short.${name.toLowerCase()}`),
  }));

export const convertPatternToDays = (
  pattern: Array<boolean>,
): Array<string> => {
  if (pattern.length !== 7) {
    throw new Error(
      `Work Pattern pattern is invalid: ${JSON.stringify(pattern)} (${
        pattern.length
      })`,
    );
  }
  return pattern.reduce(
    (acc, enabled, i) => (enabled === true ? [...acc, indexToDayMap[i]] : acc),
    [],
  );
};

export const convertDaysToPattern = (days: Array<string>): Array<boolean> =>
  Object.values(indexToDayMap).map((day) => days.includes(day));

export const convertDayNameToDayNumber = (weekday: string): number => {
  if (!Object.values(indexToDayMap).includes(weekday)) {
    throw Error(`Could not convert weekday: ${weekday}, to number`);
  }
  return Number(
    Object.keys(indexToDayMap).find((key) => indexToDayMap[key] === weekday),
  );
};

/**
 * For a given day, and a 'visible' period that might include part of the previous or next days,
 * and a series of intersecting events, find all the empty periods.
 * (ps. See the test for this function for a better understanding of what it produces)
 */
export const emptyPeriodsForDay = (
  dayStart: Date,
  dayEnd: Date,
  visibleStart: Date, // Could be earlier than the start of the day
  visibleEnd: Date, // Could be later than the start of the day
  events: [Date, Date][],
): [Date, Date][] => {
  // Discard any events that end before the visible period, or start after the visible period
  const eventsFiltered = events.filter(
    ([start, end]) => end > visibleStart && start < visibleEnd,
  );

  // Sort the events in order of start time
  const eventsSorted = eventsFiltered.sort(([startA], [startB]) => {
    if (startA < startB) return -1;
    if (startA > startB) return 1;
    return 0;
  });

  const emptyPeriods = [];
  let cursor = visibleStart;

  const overlapFilter =
    (d: Date) =>
    ([eventStart, eventEnd]: [Date, Date]) =>
      eventStart <= d && eventEnd > d;
  const futureFilter =
    (d: Date) =>
    ([eventStart]: [Date, Date]) =>
      eventStart > d;

  // Move through the given date range until we reach the end
  while (cursor < visibleEnd) {
    const overlappingEvent = eventsSorted.find(overlapFilter(cursor));
    if (overlappingEvent) {
      // move the cursor to the end of the overlapping event
      [, cursor] = overlappingEvent;
    } else {
      // Find the next event
      const nextEvent = eventsSorted.find(futureFilter(cursor));
      if (nextEvent) {
        const [eventStart, eventEnd] = nextEvent;

        if (cursor < dayStart && eventStart < dayStart) {
          emptyPeriods.push([cursor, eventStart]);
        }
        if (cursor < dayStart && eventStart >= dayStart) {
          emptyPeriods.push([cursor, dayStart]);
          emptyPeriods.push([dayStart, eventStart]);
        }
        if (cursor >= dayStart && eventStart < dayEnd) {
          emptyPeriods.push([cursor, eventStart]);
        }
        if (cursor >= dayStart && eventStart >= dayEnd) {
          emptyPeriods.push([cursor, dayEnd]);
          emptyPeriods.push([dayEnd, eventStart]);
        }
        // move the cursor to the end of the next event
        cursor = eventEnd;
      } else {
        if (cursor < dayStart) {
          emptyPeriods.push([cursor, dayStart]);
        }
        if (cursor < dayStart && dayEnd > dayStart) {
          emptyPeriods.push([dayStart, dayEnd]);
        }
        if (cursor >= dayStart && cursor < dayEnd) {
          emptyPeriods.push([cursor, dayEnd]);
        }
        if (cursor < dayEnd && visibleEnd > dayEnd) {
          emptyPeriods.push([dayEnd, visibleEnd]);
        }
        if (cursor >= dayEnd && visibleEnd > dayEnd) {
          emptyPeriods.push([cursor, visibleEnd]);
        }

        // move the cursor to the end of the range, effectively exiting at this point
        cursor = visibleEnd;
      }
    }
  }

  return emptyPeriods;
};

export const isValidIsoDateString = (input: any) =>
  typeof input === 'string' &&
  !!input.match(/\d{4}-\d{2}-\d{2}/) &&
  // Timezone note: This use of Date.getTime() is deliberate, and doesn't need to be timezone-aware.
  // It's just to check that the input can be constructed as a valid date.
  !Number.isNaN(new Date(input).getTime()) &&
  // Check if the current environment's implementation of Date has converted an invalid date like 30th Feb
  // For instance, NodeJS will convert 30th Feb to 1st March.
  // Timezone note: This use of the date constructor is fine, as date-only strings are treated as UTC, and we're
  // comparing the constructed date object with its UTC string.
  new Date(input).toISOString().substring(0, 10) === input;

export const setTime = (
  date: Date,
  timezone: string,
  hour: number,
  minute: number = 0,
): Date =>
  spacetime(date, timezone)
    .startOf('day')
    .hour(hour)
    .minute(minute)
    .toNativeDate();

export const formatDatePeriod = (
  startDate: Date,
  endDate: Date,
  timezone: string,
  options: { day: string; month: string; year: string } = {
    day: '{date-ordinal}',
    month: '{month}',
    year: '{year}',
  },
) => {
  const { day, month, year } = options;

  const spaceStart = spacetime(startDate, timezone);
  const spaceEnd = spacetime(endDate, timezone);
  if (spaceStart.isSame(spaceEnd, 'day'))
    return spaceStart.format(`${day} ${month} ${year}`);
  if (spaceStart.isSame(spaceEnd, 'month')) {
    return `${spaceStart.format(`${day}`)} - ${spaceEnd.format(
      `${day} ${month} ${year}`,
    )}`;
  }
  if (spaceStart.isSame(spaceEnd, 'year')) {
    return `${spaceStart.format(`${day} ${month}`)} - ${spaceEnd.format(
      `${day} ${month} ${year}`,
    )}`;
  }
  return `${spaceStart.format(`${day} ${month} ${year}`)} - ${spaceEnd.format(
    `${day} ${month} ${year}`,
  )}`;
};

/**
 * Determine an exclusive date's display date - checks the exact date provided
 *
 * Useful for entities like Unavailability where the dates are 2022-07-12 00:00 - 2022-07-13 00:00
 * which represents an unavailability for 2022-07-12 only
 */
export const exclusiveDisplayDate = (date: Date, timezone: string) =>
  // isMidnight takes a utc iso date string so we need to convert it first for direct check
  isMidnight(timezone, date.toISOString())
    ? spacetime(date, timezone).subtract(1, 'day').endOf('day').toNativeDate()
    : date;

/**
 *
 * @param date
 * @param holidayStartMonth
 * @param timezone
 *
 * Returns Object with startDate as 00:00 first day of holiday start month
 *  exclusive endDate as 00:00 day after end month of holiday year
 */
export const getHolidayYearRange = (
  date: Date,
  holidayStartMonth: MonthsLong,
  timezone: string,
): { startDate: Date; endDate: Date } => {
  const spaceDate = spacetime(date, timezone);
  let startDate = spaceDate.month(holidayStartMonth).startOf('month');
  if (startDate.isAfter(spaceDate)) {
    startDate = startDate.subtract(1, 'year');
  }
  const endDate = startDate.add(12, 'month');
  return {
    endDate: endDate.toNativeDate(),
    startDate: startDate.toNativeDate(),
  };
};

// Display a human-readably friendly string for roughly how long ago a thing happened
export const timeAgo = (when: Date): string => {
  const now = new Date();
  const elapsedSeconds = Math.abs((now.getTime() - when.getTime()) / 1000);

  const secondsInMinute = 60;
  const secondsInHour = 60 * secondsInMinute;
  const secondsInDay = 24 * secondsInHour;

  if (elapsedSeconds < secondsInHour) {
    const n = Math.floor(elapsedSeconds / secondsInMinute);
    return i18n.tc('unitWithAmount.minutesAgo', n, { n });
  }
  if (elapsedSeconds < secondsInDay) {
    const n = Math.floor(elapsedSeconds / secondsInHour);
    return i18n.tc('unitWithAmount.hoursAgo', n, { n });
  }
  const n = Math.floor(elapsedSeconds / secondsInDay);
  return i18n.tc('unitWithAmount.daysAgo', n, { n });
};

export const getFutureIsoShortDatesInRange = (
  startDate: Date,
  endDate: Date,
  firstDate: Date,
  lastDate: Date,
  timezone: string,
): string[] => {
  const start = spacetime(
    latestDate([startDate, firstDate, new Date()]),
    timezone,
  ).startOf('day');
  const end = spacetime(earliestDate([endDate, lastDate]), timezone);

  if (start.isSame(end, 'day')) {
    return [toISOShortString(start.toNativeDate(), timezone)];
  }

  return start
    .every('day', end, 1)
    .map((d) => toISOShortString(d.toNativeDate(), timezone));
};

export const startDateToDateTime = (
  date: PlainDate,
  midday: boolean,
  timezone: string,
): Date => {
  return spacetime(date.toString(), timezone)
    .time(midday ? '12:00' : '00:00')
    .toNativeDate();
};

export const endDateToDateTime = (
  date: PlainDate,
  midday: boolean,
  timezone: string,
): Date => {
  return spacetime(date.toString(), timezone)
    .add(midday ? 0 : 1, 'day')
    .time(midday ? '12:00' : '00:00')
    .toNativeDate();
};

export const getTimezoneOffset = (date: Date, timezone: string): number => {
  return spacetime(date, timezone).timezone().current.offset;
};

export const presentMinutesInTimeFormat = (
  minutes: number,
  hourPadStart: boolean = true,
) =>
  `${`${Math.trunc(minutes / 60)}`.padStart(hourPadStart ? 2 : 1, '0')}:${`${
    minutes % 60
  }`.padStart(2, '0')}`;

export const normaliseTimezoneName = (timezone: string): string =>
  ({
    'Africa/Asmara': 'Africa/Asmera',
    'Asia/Calcutta': 'Asia/Kolkata',
    'Asia/Dacca': 'Asia/Dhaka',
    'Asia/Saigon': 'Asia/Ho_Chi_Minh',
    'Asia/Yangon': 'Asia/Rangoon',
    'Europe/Kiev': 'Europe/Kyiv',
    'Europe/Chișinău': 'Europe/Chisinau',
    'Europe/Zaporozhye': 'Europe/Zaporizhzhia',
  }[timezone] ?? timezone);

export const getJsDate = (
  date: Date | PlainDate | string,
  timezone: string,
): Date => {
  if (date instanceof Date) return date;
  if (typeof date === 'string') return PlainDate.from(date).toDate(timezone);
  return date.toDate(timezone);
};

export const getDateRange = (
  start: Date,
  end: Date,
  timezone: string,
  unit: TimeUnit = 'day',
): Date[] =>
  spacetime(start, timezone)
    .every(unit, spacetime(end, timezone), 1)
    .map((date) => date.toNativeDate());

export const getDateSiblingFromRange = (
  range: Date[],
  date: Date,
  timezone: string,
): Date | null => {
  const spacetimeRange = range.map((date) => spacetime(date, timezone));
  const spacetimeDate = spacetime(date, timezone);
  const result = spacetimeRange.find((date) => {
    return (
      spacetimeDate.date() === date.date() &&
      spacetimeDate.month() === date.month()
    );
  });
  return result ? result.toNativeDate() : null;
};

export const setTimeOnDate = (
  time: Date | null,
  timezone: string,
  anchorDate: Date,
  targetDate: Date | null = null,
  allowEqual: boolean = false,
): Date | null => {
  if (!time) return null;
  const timeString = spacetime(time, timezone).time();
  const date = spacetime(targetDate ?? time, timezone).time(timeString);
  return date.isBefore(anchorDate) ||
    (allowEqual ? false : date.isEqual(anchorDate))
    ? date.add(1, 'day').toNativeDate()
    : date.toNativeDate();
};

/*
 * Get the current week period, based on the start of the week and the current date
 * End date is inclusive
 */
export const getCurrentWeekPeriod = (
  timezone: string,
  startOfWeek: CompanySettingStartOfWeekEnum = CompanySettingStartOfWeekEnum.Monday,
  anchorDate: Date = new Date(),
): [Date, Date] => {
  const date = spacetime(anchorDate, timezone);
  return [
    date.weekStart(startOfWeek).startOf('week').toNativeDate(),
    date.weekStart(startOfWeek).endOf('week').toNativeDate(),
  ];
};

/*
 * Get the month period, based on the anchor date; returns start and end dates
 * End date is inclusive
 */
export const getMonthPeriod = (
  timezone: string,
  anchorDate: Date = new Date(),
): [Date, Date] => {
  const date = spacetime(anchorDate, timezone);
  return [
    date.startOf('month').toNativeDate(),
    date.endOf('month').toNativeDate(),
  ];
};

export const isSameTime = (
  dateA: Date,
  dateB: Date,
  timezone: string,
): boolean => {
  const spaceTimeA = spacetime(dateA, timezone);
  const spaceTimeB = spacetime(dateB, timezone);
  return (
    spaceTimeA.isSame(spaceTimeB, 'date') &&
    spaceTimeA.isSame(spaceTimeB, 'hour') &&
    spaceTimeA.isSame(spaceTimeB, 'minute')
  );
};
