/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UpdateDigiticketsConnectionData
 */
export interface UpdateDigiticketsConnectionData {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDigiticketsConnectionData
     */
    isDefault?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateDigiticketsConnectionData
     */
    apiKey?: string;
}

/**
 * Check if a given object implements the UpdateDigiticketsConnectionData interface.
 */
export function instanceOfUpdateDigiticketsConnectionData(value: object): value is UpdateDigiticketsConnectionData {
    return true;
}

export function UpdateDigiticketsConnectionDataFromJSON(json: any): UpdateDigiticketsConnectionData {
    return UpdateDigiticketsConnectionDataFromJSONTyped(json, false);
}

export function UpdateDigiticketsConnectionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateDigiticketsConnectionData {
    if (json == null) {
        return json;
    }
    return {
        
        'isDefault': json['isDefault'] == null ? undefined : json['isDefault'],
        'apiKey': json['apiKey'] == null ? undefined : json['apiKey'],
    };
}

  export function UpdateDigiticketsConnectionDataToJSON(json: any): UpdateDigiticketsConnectionData {
      return UpdateDigiticketsConnectionDataToJSONTyped(json, false);
  }

  export function UpdateDigiticketsConnectionDataToJSONTyped(value?: UpdateDigiticketsConnectionData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'isDefault': value['isDefault'],
        'apiKey': value['apiKey'],
    };
}

