/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreateCalendarFeedResponse,
  CreateIcalFeedData,
  ListCalendarFeedsResponse,
} from '../models/index';
import {
    CreateCalendarFeedResponseFromJSON,
    CreateCalendarFeedResponseToJSON,
    CreateIcalFeedDataFromJSON,
    CreateIcalFeedDataToJSON,
    ListCalendarFeedsResponseFromJSON,
    ListCalendarFeedsResponseToJSON,
} from '../models/index';

export interface CreateCalendarFeedRequest {
    createIcalFeedData?: CreateIcalFeedData;
}

export interface DeleteCalendarFeedRequest {
    id: string;
}

/**
 * 
 */
export class CalendarApi extends runtime.BaseAPI {

    /**
     * Create a new calendar feed in iCal (RFC 5545, with RFC 7986 extensions) format.
     * Create Calendar Feed
     */
    async createCalendarFeedRaw(requestParameters: CreateCalendarFeedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreateCalendarFeedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.ical-feed.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ical-feeds`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateIcalFeedDataToJSON(requestParameters['createIcalFeedData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCalendarFeedResponseFromJSON(jsonValue));
    }

    /**
     * Create a new calendar feed in iCal (RFC 5545, with RFC 7986 extensions) format.
     * Create Calendar Feed
     */
    async createCalendarFeed(requestParameters: CreateCalendarFeedRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreateCalendarFeedResponse> {
        const response = await this.createCalendarFeedRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete Calendar Feed
     */
    async deleteCalendarFeedRaw(requestParameters: DeleteCalendarFeedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteCalendarFeed().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.ical-feed.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ical-feeds/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete Calendar Feed
     */
    async deleteCalendarFeed(requestParameters: DeleteCalendarFeedRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteCalendarFeedRaw(requestParameters, initOverrides);
    }

    /**
     * List all iCal calendar feeds
     * List Calendar Feeds
     */
    async listCalendarFeedsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListCalendarFeedsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.ical-feed.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ical-feeds`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListCalendarFeedsResponseFromJSON(jsonValue));
    }

    /**
     * List all iCal calendar feeds
     * List Calendar Feeds
     */
    async listCalendarFeeds(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListCalendarFeedsResponse> {
        const response = await this.listCalendarFeedsRaw(initOverrides);
        return await response.value();
    }

}
