/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  CreatePushSubscriptionData,
  CreatePushSubscriptionResponse,
  DeletePushSubscriptionDataByEndpoint,
  ListPushSubscriptionsResponse,
  ShowVAPIDKeyResponse,
} from '../models/index';
import {
    CreatePushSubscriptionDataFromJSON,
    CreatePushSubscriptionDataToJSON,
    CreatePushSubscriptionResponseFromJSON,
    CreatePushSubscriptionResponseToJSON,
    DeletePushSubscriptionDataByEndpointFromJSON,
    DeletePushSubscriptionDataByEndpointToJSON,
    ListPushSubscriptionsResponseFromJSON,
    ListPushSubscriptionsResponseToJSON,
    ShowVAPIDKeyResponseFromJSON,
    ShowVAPIDKeyResponseToJSON,
} from '../models/index';

export interface CreatePushSubscriptionRequest {
    createPushSubscriptionData?: CreatePushSubscriptionData;
}

export interface DeletePushSubscriptionByEndpointRequest {
    deletePushSubscriptionDataByEndpoint?: DeletePushSubscriptionDataByEndpoint;
}

export interface DeletePushSubscriptionByIdRequest {
    id: string;
}

/**
 * 
 */
export class PushApi extends runtime.BaseAPI {

    /**
     * Create a new push subscription.
     * Create Push Subscription
     */
    async createPushSubscriptionRaw(requestParameters: CreatePushSubscriptionRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<CreatePushSubscriptionResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.push-subscription.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/push/subscriptions`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreatePushSubscriptionDataToJSON(requestParameters['createPushSubscriptionData']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CreatePushSubscriptionResponseFromJSON(jsonValue));
    }

    /**
     * Create a new push subscription.
     * Create Push Subscription
     */
    async createPushSubscription(requestParameters: CreatePushSubscriptionRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<CreatePushSubscriptionResponse> {
        const response = await this.createPushSubscriptionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete a push subscription by the endpoint address.
     * Delete Push Subscription
     */
    async deletePushSubscriptionByEndpointRaw(requestParameters: DeletePushSubscriptionByEndpointRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.push-subscription.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/push/subscriptions`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
            body: DeletePushSubscriptionDataByEndpointToJSON(requestParameters['deletePushSubscriptionDataByEndpoint']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a push subscription by the endpoint address.
     * Delete Push Subscription
     */
    async deletePushSubscriptionByEndpoint(requestParameters: DeletePushSubscriptionByEndpointRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePushSubscriptionByEndpointRaw(requestParameters, initOverrides);
    }

    /**
     * Delete a push subscription by the given Id.
     * Delete Push Subscription
     */
    async deletePushSubscriptionByIdRaw(requestParameters: DeletePushSubscriptionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deletePushSubscriptionById().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.push-subscription.write"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/push/subscriptions/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete a push subscription by the given Id.
     * Delete Push Subscription
     */
    async deletePushSubscriptionById(requestParameters: DeletePushSubscriptionByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deletePushSubscriptionByIdRaw(requestParameters, initOverrides);
    }

    /**
     * List all devices subscribed to push notifications.
     * List Push Subscriptions
     */
    async listPushSubscriptionsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ListPushSubscriptionsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.push-subscription.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/push/subscriptions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ListPushSubscriptionsResponseFromJSON(jsonValue));
    }

    /**
     * List all devices subscribed to push notifications.
     * List Push Subscriptions
     */
    async listPushSubscriptions(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ListPushSubscriptionsResponse> {
        const response = await this.listPushSubscriptionsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Show the VAPID public key.
     * Show VAPID Key
     */
    async showVAPIDKeyRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ShowVAPIDKeyResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("JWT", ["api.v1.push-subscription.read"]);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/push/vapid`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ShowVAPIDKeyResponseFromJSON(jsonValue));
    }

    /**
     * Show the VAPID public key.
     * Show VAPID Key
     */
    async showVAPIDKey(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ShowVAPIDKeyResponse> {
        const response = await this.showVAPIDKeyRaw(initOverrides);
        return await response.value();
    }

}
