import { ExternalLink } from '@/lang/urlLinks';
import { OurRouteConfig, OurRouteConfigObject, routes } from '@/router/routes';
import debounce from 'lodash.debounce';
import Vue from 'vue';
import Router from 'vue-router';
// eslint-disable-next-line import/no-unresolved
import { Location } from 'vue-router/types/router';

Vue.use(Router);

// Make a copy of the routes that we can start to form into the shape that vue-router wants
export const copyOfRoutes: OurRouteConfigObject = Object.keys(routes).reduce(
  (copy, routeName) => {
    copy[routeName] = { ...routes[routeName] };
    return copy;
  },
  {},
);

// Export a basic router by default, with no routes / hooks etc.
const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export const configureRouter = (
  globalHooksFn: Function,
  routeHookFn: Function,
) => {
  // Loop through each entry and if it is a child, make a copy in the parent's children property
  Object.values(copyOfRoutes).forEach((v: OurRouteConfig) => {
    if ('childOf' in v) {
      const parentName = v.childOf();
      if (!copyOfRoutes[parentName].children) {
        copyOfRoutes[parentName].children = [];
      }
      copyOfRoutes[parentName].children.push(v);
    }
  });

  // Apply per-route and global hooks
  routeHookFn(copyOfRoutes);
  globalHooksFn(router);

  // Remove all root entries that are children
  // It's now in a format that is acceptable to vue-router
  const routesForRouter = Object.values(copyOfRoutes).filter(
    (v) => !('childOf' in v),
  );

  routesForRouter.forEach((r) => router.addRoute(r));
};

export const redirect = (location: Location) => router.push(location);
export const redirectDebounced = debounce((to, currentRoute) => {
  if (to.name === currentRoute.name) {
    return;
  }
  redirect(to).then();
}, 300);

export const goToLink = (url: ExternalLink) => window.open(url, '_blank');

// When the app first loads, mark the current point in the history stack as the initial page.
// This means that if we return to this point in the stack (via back/forward browser buttons, or in-app back buttons)
// we can navigate to our fallback route, rather than navigating away from the app itself.
window.history.replaceState({ ...window.history.state, isFirstPage: true }, '');

// If we've been redirected from login page, make a note of this so that any back buttons don't send us back
// to the login page (they should use the fallback route instead).
router.afterEach((to, from) => {
  if (from.name === routes.login.name) {
    window.history.replaceState(
      { ...window.history.state, cameFromLogin: true },
      '',
    );
  }
});

export const back = (fallbackRoute: Location = routes.dashboardV2.route()) => {
  // Avoid going back to an external site/page if they've clicked something from a webmail email, etc.
  // Also use the supplied fallback route when they've loaded an address directly, eg entered the URL manually.
  const { isFirstPage, cameFromLogin } = window.history.state ?? {
    isFirstPage: true,
  };
  if (!isFirstPage && !cameFromLogin) {
    return router.back();
  }
  return router.push(fallbackRoute);
};

export default router;
