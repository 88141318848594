/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VAPIDKeys
 */
export interface VAPIDKeys {
    /**
     * 
     * @type {string}
     * @memberof VAPIDKeys
     */
    publicKey: string;
}

/**
 * Check if a given object implements the VAPIDKeys interface.
 */
export function instanceOfVAPIDKeys(value: object): value is VAPIDKeys {
    if (!('publicKey' in value) || value['publicKey'] === undefined) return false;
    return true;
}

export function VAPIDKeysFromJSON(json: any): VAPIDKeys {
    return VAPIDKeysFromJSONTyped(json, false);
}

export function VAPIDKeysFromJSONTyped(json: any, ignoreDiscriminator: boolean): VAPIDKeys {
    if (json == null) {
        return json;
    }
    return {
        
        'publicKey': json['publicKey'],
    };
}

  export function VAPIDKeysToJSON(json: any): VAPIDKeys {
      return VAPIDKeysToJSONTyped(json, false);
  }

  export function VAPIDKeysToJSONTyped(value?: VAPIDKeys | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'publicKey': value['publicKey'],
    };
}

