var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('UserNav',{attrs:{"value":_vm.showNav === _vm.Nav.User,"show-profile-items":"","show-developer-settings":_vm.authenticatedUser.developerSettings,"extra-items":_vm.visibleToolItems.length ? [] : _vm.notificationItems},on:{"input":function($event){return _vm.toggleNav(null)}}}),_c('SupportNav',{attrs:{"value":_vm.showNav === _vm.Nav.Support},on:{"input":function($event){return _vm.toggleNav(null)}}}),(_vm.moreItems.length)?_c('DesktopMoreNav',{attrs:{"value":_vm.showNav === _vm.Nav.More,"nav-items":_vm.moreItems,"nav-click":_vm.navClick},on:{"input":function($event){return _vm.toggleNav(null)}}}):_vm._e(),(!!_vm.lockedFeatureDialog)?_c('LockedFeatureDialog',{attrs:{"value":!!_vm.lockedFeatureDialog,"feature":_vm.lockedFeatureDialog},on:{"close":function($event){_vm.lockedFeatureDialog = null}}}):_vm._e(),_c('aside',{staticClass:"relative shadow-md sidebar"},[_c('div',{staticClass:"all:flex align-center justify-center mb-4 cursor-pointer flex-shrink-0",staticStyle:{"height":"56px"},on:{"click":function($event){_vm.redirectDebounced(_vm.routes.dashboardV2.route(), _vm.$route)}}},[_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[_c('PinkShiftieCutOutSquareSvg')],1)],1),_c('div',{staticClass:"h-full flex flex-col justify-between"},[_c('ul',{staticClass:"pl-0"},[_vm._l((_vm.visibleGeneralItems),function(item,i){return _c('SidebarItem',{key:`general-${i}`,class:{
            'opacity-50': item.feature && !_vm.companyCanAccess(item.feature),
          },attrs:{"id":`nav-item-sidebar-${item.to ? item.to.name : item.id}`,"item":item},on:{"click":_vm.navClick}})}),(_vm.moreItems.length)?_c('SidebarItem',{key:"more-menu",attrs:{"id":`nav-item-sidebar-${_vm.moreNavItem.to}`,"item":_vm.moreNavItem,"is-active":_vm.isMoreNavHighlighted},on:{"click":_vm.moreNavItem.action}}):_vm._e()],2),_c('ul',{staticClass:"pl-0"},[_vm._l((_vm.staticItems),function(item,i){return _c('SidebarItem',{key:`static-${i}`,class:{
            'opacity-50': item.feature && !_vm.companyCanAccess(item.feature),
          },attrs:{"id":`nav-item-sidebar-${item.to ? item.to.name : item.id}`,"item":item},on:{"click":_vm.navClick}})}),_vm._l((_vm.visibleToolItems),function(item,i){return _c('SidebarItem',{key:`tool-${i}`,class:{
            'opacity-50': item.feature && !_vm.companyCanAccess(item.feature),
          },attrs:{"id":`nav-item-sidebar-${item.to ? item.to.name : item.id}`,"item":item},on:{"click":_vm.navClick}})})],2)]),(_vm.loggedInEmployee)?_c('div',{staticClass:"bg-purple-800 all:py-4 all:px-2 h-18 w-full all:flex align-center justify-center"},[_c('EmployeeAvatar',{attrs:{"size":"small","employee":_vm.loggedInEmployee,"interaction":"","highlight":"","highlight-colour":"gray-50"},on:{"click":function($event){return _vm.toggleNav(_vm.Nav.User)}},scopedSlots:_vm._u([(!_vm.visibleToolItems.length)?{key:"dot",fn:function(){return [(_vm.notificationStat)?_c('StatBadge',{staticClass:"-right-1 -top-1"},[_vm._v(" "+_vm._s(_vm.notificationStat)+" ")]):_vm._e()]},proxy:true}:null],null,true)})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }