/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * Breaks down the number of redemptions by the sales channel each item was originally sold through.
 * @export
 * @interface DigiticketsRedemptionSummary
 */
export interface DigiticketsRedemptionSummary {
    /**
     * 
     * @type {Date}
     * @memberof DigiticketsRedemptionSummary
     */
    intervalStart: Date;
    /**
     * 
     * @type {Date}
     * @memberof DigiticketsRedemptionSummary
     */
    intervalEnd: Date;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsRedemptionSummary
     */
    onlineRedemptions: number;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsRedemptionSummary
     */
    backOfficeRedemptions: number;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsRedemptionSummary
     */
    proPointRedemptions: number;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsRedemptionSummary
     */
    proKioskRedemptions: number;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsRedemptionSummary
     */
    memberRedemptions: number;
    /**
     * 
     * @type {number}
     * @memberof DigiticketsRedemptionSummary
     */
    otherRedemptions: number;
}

/**
 * Check if a given object implements the DigiticketsRedemptionSummary interface.
 */
export function instanceOfDigiticketsRedemptionSummary(value: object): value is DigiticketsRedemptionSummary {
    if (!('intervalStart' in value) || value['intervalStart'] === undefined) return false;
    if (!('intervalEnd' in value) || value['intervalEnd'] === undefined) return false;
    if (!('onlineRedemptions' in value) || value['onlineRedemptions'] === undefined) return false;
    if (!('backOfficeRedemptions' in value) || value['backOfficeRedemptions'] === undefined) return false;
    if (!('proPointRedemptions' in value) || value['proPointRedemptions'] === undefined) return false;
    if (!('proKioskRedemptions' in value) || value['proKioskRedemptions'] === undefined) return false;
    if (!('memberRedemptions' in value) || value['memberRedemptions'] === undefined) return false;
    if (!('otherRedemptions' in value) || value['otherRedemptions'] === undefined) return false;
    return true;
}

export function DigiticketsRedemptionSummaryFromJSON(json: any): DigiticketsRedemptionSummary {
    return DigiticketsRedemptionSummaryFromJSONTyped(json, false);
}

export function DigiticketsRedemptionSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigiticketsRedemptionSummary {
    if (json == null) {
        return json;
    }
    return {
        
        'intervalStart': (new Date(json['intervalStart'])),
        'intervalEnd': (new Date(json['intervalEnd'])),
        'onlineRedemptions': json['onlineRedemptions'],
        'backOfficeRedemptions': json['backOfficeRedemptions'],
        'proPointRedemptions': json['proPointRedemptions'],
        'proKioskRedemptions': json['proKioskRedemptions'],
        'memberRedemptions': json['memberRedemptions'],
        'otherRedemptions': json['otherRedemptions'],
    };
}

  export function DigiticketsRedemptionSummaryToJSON(json: any): DigiticketsRedemptionSummary {
      return DigiticketsRedemptionSummaryToJSONTyped(json, false);
  }

  export function DigiticketsRedemptionSummaryToJSONTyped(value?: DigiticketsRedemptionSummary | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'intervalStart': ((value['intervalStart']).toISOString()),
        'intervalEnd': ((value['intervalEnd']).toISOString()),
        'onlineRedemptions': value['onlineRedemptions'],
        'backOfficeRedemptions': value['backOfficeRedemptions'],
        'proPointRedemptions': value['proPointRedemptions'],
        'proKioskRedemptions': value['proKioskRedemptions'],
        'memberRedemptions': value['memberRedemptions'],
        'otherRedemptions': value['otherRedemptions'],
    };
}

