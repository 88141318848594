/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IcalFeed } from './IcalFeed';
import {
    IcalFeedFromJSON,
    IcalFeedFromJSONTyped,
    IcalFeedToJSON,
    IcalFeedToJSONTyped,
} from './IcalFeed';

/**
 * 
 * @export
 * @interface CreateCalendarFeedResponse
 */
export interface CreateCalendarFeedResponse {
    /**
     * 
     * @type {IcalFeed}
     * @memberof CreateCalendarFeedResponse
     */
    data: IcalFeed;
}

/**
 * Check if a given object implements the CreateCalendarFeedResponse interface.
 */
export function instanceOfCreateCalendarFeedResponse(value: object): value is CreateCalendarFeedResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function CreateCalendarFeedResponseFromJSON(json: any): CreateCalendarFeedResponse {
    return CreateCalendarFeedResponseFromJSONTyped(json, false);
}

export function CreateCalendarFeedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateCalendarFeedResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': IcalFeedFromJSON(json['data']),
    };
}

  export function CreateCalendarFeedResponseToJSON(json: any): CreateCalendarFeedResponse {
      return CreateCalendarFeedResponseToJSONTyped(json, false);
  }

  export function CreateCalendarFeedResponseToJSONTyped(value?: CreateCalendarFeedResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': IcalFeedToJSON(value['data']),
    };
}

