
import Checkbox from '@/components/inputs/Checkbox.vue';
import { Icon } from '@/lib/enum/Icon';
import store from '@/store';
import { showFixedElement } from '@/util/displayFunctions';
import { viewport } from '@/util/windowFunctions';
import VueI18n from 'vue-i18n';
import TranslateResult = VueI18n.TranslateResult;

/**
 * The base `SelectInput` component.
 *
 * `value` allows you to prefill the component
 *
 * `items` property can be an array of any data. `itemValue` allows you to set what key is used to identify the data
 * and `itemText` allows you to specify what is shown within the component.
 *
 * The events emitted are `change` and `input` to allow you to update the data within the context of the parent.
 */
export default {
  name: 'SelectInput',
  components: { Checkbox },

  props: {
    value: {
      type: [String, Number, Array, Object, Boolean],
      default: '',
    },

    items: {
      type: Array,
      required: true,
    },

    itemValue: {
      type: String,
      default: 'id',
    },

    itemText: {
      type: [String, Function],
      default: 'name',
    },

    itemColourIndicator: {
      type: String,
      default: '',
    },

    itemIconProp: {
      type: String,
      default: '',
    },

    clearable: {
      type: Boolean,
      default: false,
    },

    noDataText: {
      type: String,
      default: 'No data available',
    },

    multiple: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    employee: {
      type: Boolean,
      default: false,
    },

    labelText: {
      type: [String, TranslateResult],
      default: '',
    },

    // i18n path
    placeholder: {
      type: String,
      default: '',
    },
    // string
    placeholderString: {
      type: String,
      default: '',
    },

    required: {
      type: Boolean,
      default: false,
    },

    hasError: {
      type: Boolean,
      default: false,
    },

    error: {
      type: [Array, String],
      default: null,
    },

    readOnly: {
      type: Boolean,
      default: false,
    },

    // Marks this input as sensitive so hides data when impersonating and forces readonly
    sensitive: {
      type: Boolean,
      default: false,
    },

    customLayout: {
      type: Boolean,
      default: false,
    },

    height: {
      type: [Number, String],
      default: 56,
    },

    fixedWidth: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      Icon,
      selectWidth: null,
    };
  },

  computed: {
    isImpersonationSession: (): boolean => store.state.isImpersonationSession,
    isEnhancedImpersonationSession: (): boolean =>
      store.state.isEnhancedImpersonationSession,

    isSecured(): boolean {
      return (
        this.sensitive &&
        this.isImpersonationSession &&
        !this.isEnhancedImpersonationSession
      );
    },

    getValue() {
      return Array.isArray(this.value)
        ? this.items.filter((item) => this.value.includes(item[this.itemValue]))
        : this.value;
    },

    getPlaceholder() {
      if (this.placeholder) return this.$t(`placeholder.${this.placeholder}`);
      if (this.placeholderString) return this.placeholderString;
      return null;
    },

    getListeners() {
      const { ...listeners } = this.$listeners;
      delete listeners.input;
      return listeners;
    },
  },

  mounted() {
    // for smaller devices, there is a special styling when a select menu being in the center of the screen
    if (this.fixedWidth && viewport.lg) {
      this.selectWidth = this.$refs.selectInputContainer?.clientWidth;
    }
  },

  methods: {
    handleInput(event) {
      this.$emit('input', event ?? null);
    },

    blur() {
      this.$emit('blur');
      showFixedElement('#fixed-button');
    },
  },
};
