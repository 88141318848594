var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"header-search-wrapper px-4 pb-2.5 pt-3.5 flex justify-between relative",class:{
      'header-search-wrapper--input-focused': _setup.inputFocused,
      'has-filters': _setup.shouldShowAppendButton,
    },style:(`--button-width: ${_vm.appendButtonWidth}px`)},[_c(_setup.Search,{ref:"inputRef",staticClass:"header-search-input z-[1] transition-all ease-in-out",attrs:{"placeholder":_vm.placeholder,"clear-icon":"","initial-value":_vm.query},on:{"submit":function($event){return _vm.$emit('queryChange', $event)},"focus":function($event){_setup.inputFocused = true},"blur":function($event){_setup.inputFocused = false}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_setup.inputFocused)?_c(_setup.Button,{staticClass:"hover:!bg-transparent header-search-input-close",class:_setup.inputFocused ? 'opacity-100' : 'opacity-0',attrs:{"color":"transparent","icon-left":_setup.Icon.CircleXmarkSolid},on:{"click":function($event){_setup.inputRef.$refs.search.blur();
            _vm.$emit('queryChange', '');}}}):_vm._e()]},proxy:true}])}),(_setup.shouldShowAppendButton)?_c('div',{staticClass:"header-search-menu-button"},[_vm._t("append-button",function(){return [_c(_setup.Button,{staticClass:"!rounded-[12px]",attrs:{"color":"purple-300","border":false,"icon-left":_setup.Icon.ArrowDownWideShort,"size":"lg"},on:{"click":_setup.showMenu}})]})],2):_vm._e()],1),(_setup.menuVisible)?_c('div',{staticClass:"absolute w-full p-2 bg-white z-[2]"},[(_vm.sortOptions.length)?_c('div',{staticClass:"text-14 font-bold border-b-1 mt-1 pb-2"},[_vm._v(" "+_vm._s(_vm.$t('label.sortedBy'))+": ")]):_vm._e(),_c(_setup.RadioButtons,{staticClass:"py-2",attrs:{"value":_vm.selectedSortOption?.id,"list":_vm.sortOptions.map((sort) => ({
          value: sort.id,
          name: sort.name,
        })),"radio-group":"sortProp"},on:{"input":function($event){_vm.$emit(
          'sortChange',
          _vm.sortOptions.find((sort) => sort.id === $event),
        )}}}),(_vm.filterOptions.length)?_c('div',{staticClass:"text-14 font-bold border-b-1 mt-1 pb-2"},[_vm._v(" "+_vm._s(_vm.$t('label.filter'))+": ")]):_vm._e(),_vm._l((_vm.filterOptions),function(filter){return _c(_setup.Checkbox,{key:filter.id ?? filter.name,staticClass:"p-2 pt-4",attrs:{"label-text":filter.name,"value":_vm.selectedFilters[filter.id] ?? false},on:{"input":function($event){return _vm.$emit('filterChange', {
          ..._vm.selectedFilters,
          [filter.id]: !_vm.selectedFilters[filter.id],
        })}}})})],2):_vm._e(),_c('v-overlay',{attrs:{"z-index":"-1"},on:{"click":function($event){_setup.menuVisible = false}},model:{value:(_setup.menuVisible),callback:function ($$v) {_setup.menuVisible=$$v},expression:"menuVisible"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }