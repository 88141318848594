/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface ApplyScheduleTemplateData
 */
export interface ApplyScheduleTemplateData {
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof ApplyScheduleTemplateData
     */
    startDate: ShiftiePlainDate;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof ApplyScheduleTemplateData
     */
    endDate: ShiftiePlainDate;
    /**
     * 
     * @type {string}
     * @memberof ApplyScheduleTemplateData
     */
    conflictMode: ApplyScheduleTemplateDataConflictModeEnum;
    /**
     * 
     * @type {string}
     * @memberof ApplyScheduleTemplateData
     */
    shiftConflictUnassignCondition: ApplyScheduleTemplateDataShiftConflictUnassignConditionEnum;
}

/**
* @export
* @enum {string}
*/
export enum ApplyScheduleTemplateDataConflictModeEnum {
    Omit = 'Omit',
    Overwrite = 'Overwrite',
    Unassign = 'Unassign',
    UnassignAll = 'Unassign All'
}
/**
* @export
* @enum {string}
*/
export enum ApplyScheduleTemplateDataShiftConflictUnassignConditionEnum {
    WhenShiftsConflict = 'WhenShiftsConflict',
    WhenShiftsOrWorkPatternsConflict = 'WhenShiftsOrWorkPatternsConflict'
}


/**
 * Check if a given object implements the ApplyScheduleTemplateData interface.
 */
export function instanceOfApplyScheduleTemplateData(value: object): value is ApplyScheduleTemplateData {
    if (!('startDate' in value) || value['startDate'] === undefined) return false;
    if (!('endDate' in value) || value['endDate'] === undefined) return false;
    if (!('conflictMode' in value) || value['conflictMode'] === undefined) return false;
    if (!('shiftConflictUnassignCondition' in value) || value['shiftConflictUnassignCondition'] === undefined) return false;
    return true;
}

export function ApplyScheduleTemplateDataFromJSON(json: any): ApplyScheduleTemplateData {
    return ApplyScheduleTemplateDataFromJSONTyped(json, false);
}

export function ApplyScheduleTemplateDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApplyScheduleTemplateData {
    if (json == null) {
        return json;
    }
    return {
        
        'startDate': ShiftiePlainDateFromJSON(json['startDate']),
        'endDate': ShiftiePlainDateFromJSON(json['endDate']),
        'conflictMode': json['conflictMode'],
        'shiftConflictUnassignCondition': json['shiftConflictUnassignCondition'],
    };
}

  export function ApplyScheduleTemplateDataToJSON(json: any): ApplyScheduleTemplateData {
      return ApplyScheduleTemplateDataToJSONTyped(json, false);
  }

  export function ApplyScheduleTemplateDataToJSONTyped(value?: ApplyScheduleTemplateData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'startDate': ShiftiePlainDateToJSON(value['startDate']),
        'endDate': ShiftiePlainDateToJSON(value['endDate']),
        'conflictMode': value['conflictMode'],
        'shiftConflictUnassignCondition': value['shiftConflictUnassignCondition'],
    };
}

