<template>
  <Dialog
    v-model="open"
    :title="$t('label.switchWorkplace').toString()"
    :fullscreen="!viewport.lg"
    @input="open = false"
  >
    <template #header>
      <DialogHeader
        tooltip
        :title="$t('label.switchWorkplace').toString()"
        :tooltip-title="$t('info.heading.switchCompanies').toString()"
        :tooltip-message="$t('info.companies').toString()"
        @click="open = false"
      />
    </template>
    <div
      v-if="loading"
      class="all:flex justify-center all:p-4"
    >
      <LoadingDot :iteration="3" />
    </div>
    <template v-else>
      <ul class="pl-4 all:p-4 all:flex flex-col gap-2">
        <li
          v-for="company in companies.data"
          :key="company.id"
          class="company-item all:flex gap-4 cursor-pointer justify-between all:px-2 md:px-4 all:p-4 rounded hover:bg-gray-200"
          @click="updateCompany(company)"
        >
          <div class="all:flex align-center text-overflow-ellipsis">
            <CompanyLogo
              v-if="company.companySetting"
              class="all:mr-4"
              :size="50"
              :company-setting="company.companySetting"
            />
            <div class="all:flex flex-column text-overflow-ellipsis">
              <span class="text-16 font-bold text-overflow-ellipsis">
                {{ company.name }}
              </span>
              <span class="text-gray-600 text-12 text-overflow-ellipsis">
                {{ getCompanyAccessRole(company.id) }}
              </span>
            </div>
          </div>
          <div class="all:flex align-center gap-4">
            <i
              v-if="!isCurrentCompany(company)"
              class="switch-icon text-gray-500"
              :class="Icon.Repeat"
            />
            <Chip
              v-if="companyProhibited(company)"
              :text="companyProhibited(company)"
              :status="company.status"
              display="inline-flex"
            />
            <BubbleIcon
              v-if="isCurrentCompany(company)"
              :icon="Icon.Check"
              size="small"
              foreground-class="white"
              background-class="pink-400"
            />
          </div>
        </li>
      </ul>
    </template>

    <template #footer>
      <div class="all:flex w-full justify-center align-center">
        <Button
          color="link"
          class="all:m-auto"
          sm
          :button-text="$t('button.addNewWorkplace').toString()"
          :icon-right="Icon.Plus"
          @click="redirect(routes.signUp.route())"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { employeeApi } from '@/api';
import Button from '@/components/buttons/Button';
import Dialog from '@/components/dialog/Dialog';
import BubbleIcon from '@/components/interface/BubbleIcon';
import Chip from '@/components/interface/Chip.vue';
import CompanyLogo from '@/components/interface/CompanyLogo';
import LoadingDot from '@/components/loaders/LoadingDot';
import { Icon } from '@/lib/enum/Icon';
import { Entity } from '@/lib/realtime/RealtimeEntity';
import { realtimeQuery } from '@/lib/realtime/weak/realtimeFunctions';
import { redirect } from '@/router/router';
import { routes } from '@/router/routes';
import store from '@/store';
import { setCompanyId } from '@/util/globalFunctions';
import { capitalise } from '@/util/stringFunctions';
import { viewport } from '@/util/windowFunctions';
import DialogHeader from '@/views/settings/modals/DialogHeader';
import { CompanyStatusEnum } from '../../../api/v1';

export default {
  name: 'CompanySwitcher',

  components: {
    Button,
    LoadingDot,
    DialogHeader,
    Dialog,
    BubbleIcon,
    CompanyLogo,
    Chip,
  },

  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      Icon,
      CompanyStatusEnum,
      viewport,
      routes,
      loading: true,
      userEmployees: [],
      companies: realtimeQuery(Entity.Company).fetch(),
      accessRoles: realtimeQuery(Entity.AccessRole).fetch(),
    };
  },

  computed: {
    currentCompany: () => store.getters.currentCompany,

    open: {
      get() {
        return this.value;
      },
      set(v) {
        if (!v) this.$emit('close');
      },
    },
  },

  async mounted() {
    this.loading = true;
    await this.getUserEmployees();
    this.loading = false;
  },

  methods: {
    dispatch: store.dispatch,

    capitalise,
    redirect,

    async getUserEmployees() {
      const { data: employees } =
        await employeeApi.listEmployeesBelongingToUser();
      this.userEmployees = employees?.length ? employees : [];
    },

    isCurrentCompany(company) {
      return this.currentCompany.id === company.id;
    },

    companyProhibited(company) {
      const prohibitedStatus = [
        CompanyStatusEnum.Locked,
        CompanyStatusEnum.Suspended,
      ];
      if (prohibitedStatus.includes(company.status))
        return this.$t(`enum.companyStatus.${company.status}`);
      return false;
    },

    async updateCompany(company) {
      this.open = false;
      if (this.isCurrentCompany(company)) return;

      setCompanyId(company.id);
      // Use async so that any page route update doesn't overwrite the dashboard redirect
      // E.g. Timesheets updates the url with date which overwrites the dashboard redirect
      const callback = async () => {
        if (this.$route.name === routes.dashboardV2.name) {
          return;
        }
        await redirect(routes.dashboardV2.route());
      };
      await this.dispatch('bootstrapCurrentCompany', callback);
    },

    getCompanyAccessRole(companyId) {
      if (!this.userEmployees.length) {
        return '';
      }

      const employee = this.userEmployees.find(
        (e) => e.companyId === companyId,
      );
      const accessRole = this.accessRoles.data.find(
        (a) => a.id === employee.accessRoleIds[0],
      );
      return accessRole?.name || '';
    },
  },
};
</script>

<style scoped lang="scss">
.switch-icon {
  display: none;
}
.company-item:hover {
  .switch-icon {
    display: block;
  }
}
</style>
