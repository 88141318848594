import { companyApi } from '@/api';
import Auth from '@/Auth';
import i18n from '@/i18n';
import { LogoutReason } from '@/lib/auth/logoutReason';
import { isActiveCompany } from '@/lib/company/companyFunctions';
import { AppWarningEnum } from '@/lib/enum/AppWarningEnum';
import { StorageEnum } from '@/lib/enum/StorageEnum';
import MobileHeader from '@/lib/mobileHeader';
import {
  companyCanAccess,
  companyHasFeatureFlag,
} from '@/lib/permission/companyAccessFeatures';
import { showWarning } from '@/plugins/ToastNotifications';
import { routes } from '@/router/routes';
import store from '@/store';
import { getGlobal, setGlobal } from '@/util/globalFunctions';
import {
  getLocalStorageItem,
  setLocalStorageItem,
  validateLocalStorageAccess,
} from '@/util/storageFunctions';
import spacetime from 'spacetime';
import Router from 'vue-router';

export const applyGlobalHooks = (router: Router) => {
  router.onError(() => {
    if (navigator.onLine) {
      store.dispatch('appWarnings/setWarning', AppWarningEnum.Refresh).then();
    }
  }); // onError

  router.beforeEach(async (to, from, next) => {
    // Save the previous route name to restore it if needed
    const previousTitle = document.title;
    // Set the HTML document title to whatever title we have for this route
    document.title = String(i18n.t(`route.title.${to.name}`));

    // If the URI contains companyId in the query (and the route allows manual
    // setting of companyId), then update the localstore value to match.
    // This should only be used in circumstances
    // where the normal company switching cannot occur,
    // e.g. when navigating to the app from a notification email's CTA.
    if (to.query.companyId && to.meta.allowsCompanySetting) {
      setLocalStorageItem(StorageEnum.CompanyId, String(to.query.companyId));
    }

    // If from a fresh load, get authentication data
    if (to.meta.allowsGuest) {
      // On abort and logout we should end the loading
      // We can't call it inside fetchAbort or the middleware as the store hasn't been initialised at that point
      if (to.name === routes.logout.name) {
        store.commit('app/END_LOADING');
      }
      return next();
    }

    if (
      !(await Auth.isAuthenticated()) ||
      (await Auth.tokenExpiresAfter(spacetime.now().add(2, 'minute')))
    ) {
      await Auth.refreshCredentials();
    }

    const storedCompanyId = from.name
      ? getGlobal('$companyId')
      : getLocalStorageItem(StorageEnum.CompanyId);

    if (!storedCompanyId) {
      return next(
        routes.logout.route({
          redirect: to.fullPath,
          ...(!validateLocalStorageAccess() && {
            reason: LogoutReason.NoCookieAccess,
          }),
        }),
      );
    }

    // Prevent double bootstrap so don't try again from login
    if (
      !from.name ||
      (from.meta.allowsGuest &&
        ![routes.login.name, routes.signUp.name].includes(from.name))
    ) {
      // This is the only place $companyId is set without using the setCompanyId function
      // from globalFunctions.ts. This is because we don't need to override the value
      // since we've just pulled it from the localStore.
      setGlobal('$companyId', Number(storedCompanyId));

      await store.dispatch('authorizeApp');
    }

    // Make a direct API call instead of relying on WebSocket update messages as
    // we may not have received the message about the updated company status.
    const { data: company } = await companyApi.showCompany({
      id: getGlobal('$companyId'),
    });

    if (
      routes[to.name].meta?.featureFlag &&
      !companyHasFeatureFlag(company, routes[to.name].meta.featureFlag)
    ) {
      // Router won't redirect if the route is the same,
      // so we need to restore the title
      if (from.name === routes.dashboardV2.name) document.title = previousTitle;
      return next(routes.dashboardV2.route());
    }

    if (!isActiveCompany(company)) {
      store.commit('SET_COMPANY_STATUS_MODAL', true);
    }

    const feature = to.matched[0]?.meta.featureName;
    const childFeature = to.meta.featureName;

    // If parent has no feature and the child view has no feature restrictions, continue
    if (!feature && !childFeature) return next();

    // If the child has a feature restriction, do a check
    if (childFeature && companyCanAccess(childFeature)) return next();

    // Do a check on the parent feature restriction
    if (feature && companyCanAccess(feature)) return next();

    // If no from route (fresh navigation from URL) and company cannot
    // access this feature, redirect to the dashboard
    if (!from.name) {
      showWarning(
        i18n.t('warningMessage.preventRouteAccess', {
          feature: i18n.t(`enum.feature.${childFeature || feature}`),
        }),
      );
      return next(routes.dashboardV2.route());
    }

    // If somehow someone can click in to a feature,
    // they're not allowed to access
    // send them to 404
    next(routes.pageNotFound.route());
  });

  router.afterEach((to, from) => {
    // Reset the mobile header icons on entering the new component
    if (to.name !== from.name) MobileHeader.resetState();

    // disable redirect loading if we were redirected via navigation guard hook before
    if (store.getters['app/isRedirecting']) {
      store.commit('app/END_REDIRECT_LOADING');
    }
  });
};
