
import StatBadge from '@/components/stats/StatBadge.vue';
import {
  isFeatureFlagRoute,
  isSidebarItemAdditionallyHighlighted,
  NavItemInterface,
} from '@/lib/navigation/helpers';
import { companyCanAccess } from '@/lib/permission/companyAccessFeatures';
import { PropType } from 'vue';

export default {
  name: 'SidebarItem',
  components: { StatBadge },
  props: {
    item: {
      type: Object as PropType<NavItemInterface>,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  computed: {
    disabled(): boolean {
      return this.item.feature && !companyCanAccess(this.item.feature);
    },
  },
  methods: {
    isFeatureFlagRoute,
    companyCanAccess,
    isSidebarItemAdditionallyHighlighted,
  },
};
