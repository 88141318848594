/**
 * This file contains generic date filters
 */
import { PlainDate } from '@/lib/date-time/PlainDate';
import { getJsDate } from '@/util/dateFunctions';
import { AnyGetter, getFromObject } from '@/util/objectFunctions';
import spacetime from 'spacetime';

export const startsInPeriod =
  (start: Date, end: Date, startProp: string = 'startsAt') =>
  (entity: object) =>
    entity[startProp] < end && entity[startProp] >= start;

export const isSameDate = (date: Date, timezone: string, prop: AnyGetter) => {
  const spacetimeDate = spacetime(date, timezone);
  return (entity: object) =>
    spacetime(
      getJsDate(getFromObject(entity, prop) as Date, timezone),
      timezone,
    ).isSame(spacetimeDate, 'day', true);
};

export const isSamePlainDate =
  (targetDate: PlainDate, prop: string = 'date') =>
  (entity: object): boolean =>
    targetDate.toString() === entity[prop].toString();

export const plainDateInPeriod = (
  start: Date,
  end: Date,
  date: PlainDate,
  timezone: string,
) => date.toDate(timezone) < end && date.toDate(timezone) >= start;

// Checks dates overlap
export const isInPeriod =
  <Start extends string, End extends string>(
    start: Date,
    end: Date,
    startProp: Start,
    endProp: End,
  ) =>
  (entity: Record<Start | End, Date>): boolean =>
    entity[startProp] < end && entity[endProp] > start;

// Checks a provided date range fully covers a period
export const periodInRange =
  <Start extends string, End extends string>(
    start: Date,
    end: Date,
    startProp: Start,
    endProp: End,
  ) =>
  (entity: Record<Start | End, Date>): boolean =>
    entity[startProp] >= start && entity[endProp] <= end;
