/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface DigiticketsConnection
 */
export interface DigiticketsConnection {
    /**
     * 
     * @type {number}
     * @memberof DigiticketsConnection
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof DigiticketsConnection
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof DigiticketsConnection
     */
    status: DigiticketsConnectionStatusEnum;
    /**
     * 
     * @type {boolean}
     * @memberof DigiticketsConnection
     */
    isDefault: boolean;
    /**
     * 
     * @type {string}
     * @memberof DigiticketsConnection
     */
    username: string;
    /**
     * 
     * @type {Date}
     * @memberof DigiticketsConnection
     */
    createdAt: Date;
    /**
     * 
     * @type {Date}
     * @memberof DigiticketsConnection
     */
    updatedAt: Date | null;
}

/**
* @export
* @enum {string}
*/
export enum DigiticketsConnectionStatusEnum {
    Active = 'Active',
    Broken = 'Broken'
}


/**
 * Check if a given object implements the DigiticketsConnection interface.
 */
export function instanceOfDigiticketsConnection(value: object): value is DigiticketsConnection {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('isDefault' in value) || value['isDefault'] === undefined) return false;
    if (!('username' in value) || value['username'] === undefined) return false;
    if (!('createdAt' in value) || value['createdAt'] === undefined) return false;
    if (!('updatedAt' in value) || value['updatedAt'] === undefined) return false;
    return true;
}

export function DigiticketsConnectionFromJSON(json: any): DigiticketsConnection {
    return DigiticketsConnectionFromJSONTyped(json, false);
}

export function DigiticketsConnectionFromJSONTyped(json: any, ignoreDiscriminator: boolean): DigiticketsConnection {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'status': json['status'],
        'isDefault': json['isDefault'],
        'username': json['username'],
        'createdAt': (new Date(json['createdAt'])),
        'updatedAt': (json['updatedAt'] == null ? null : new Date(json['updatedAt'])),
    };
}

  export function DigiticketsConnectionToJSON(json: any): DigiticketsConnection {
      return DigiticketsConnectionToJSONTyped(json, false);
  }

  export function DigiticketsConnectionToJSONTyped(value?: DigiticketsConnection | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'status': value['status'],
        'isDefault': value['isDefault'],
        'username': value['username'],
        'createdAt': ((value['createdAt']).toISOString()),
        'updatedAt': (value['updatedAt'] == null ? null : (value['updatedAt'] as any).toISOString()),
    };
}

