
import CompanyLogo from '@/components/interface/CompanyLogo.vue';
import CompanySwitcher from '@/components/modal/CompanySwitcher.vue';
import { Icon } from '@/lib/enum/Icon';
import { Entity } from '@/lib/realtime/RealtimeEntity';
import { realtimeQuery } from '@/lib/realtime/weak/realtimeFunctions';
import store from '@/store';
import { Company } from '../../../../api/v1';

/**
 * `CompanyToggle` takes care of all the heavy lifting when it comes to toggling the `CompanySwitcher` component.
 *  It uses the store to grab the companies assigned to a user and registers the `CompanySwitcher` dialog
 */

export default {
  name: 'CompanyToggle',

  components: {
    CompanySwitcher,
    CompanyLogo,
  },

  data() {
    return {
      Icon,
      showCompanySwitcher: false,
      companies: realtimeQuery(Entity.Company).fetch(),
    };
  },

  computed: {
    companySettings: () => store.state.settings.companySettings,
    currentCompany: () => store.getters.currentCompany,
    loggedInEmployee: () => store.getters.loggedInEmployee,

    companyList(): Company[] {
      return this.companies.data;
    },
    accessRoleName(): string {
      if (!this.loggedInEmployee) return '';
      return this.loggedInEmployee.accessRoles[0].name;
    },
  },

  methods: {
    toggleCompanySwitcher() {
      this.$emit('toggle');
      this.showCompanySwitcher = !this.showCompanySwitcher;
    },
  },
};
