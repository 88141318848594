
import StatBadge from '@/components/stats/StatBadge.vue';
import {
  isActiveRoute,
  isFeatureFlagRoute,
  NavItemInterface,
} from '@/lib/navigation/helpers';
import { companyCanAccess } from '@/lib/permission/companyAccessFeatures';
import { PropType } from 'vue';

export default {
  name: 'NavItem',
  components: { StatBadge },
  props: {
    item: {
      type: Object as PropType<NavItemInterface>,
      required: true,
    },
    navClick: {
      type: Function as PropType<(item: NavItemInterface) => void>,
      required: true,
    },
    disableActive: {
      type: Boolean,
      default: true,
    },
    showIcon: {
      type: Boolean,
      default: true,
    },
    includedInSearch: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    active(): boolean {
      return this.item.to && isActiveRoute(this.item, this.$route);
    },
    disabled(): boolean {
      return this.item.feature && !companyCanAccess(this.item.feature);
    },
  },
  methods: {
    isFeatureFlagRoute,
    companyCanAccess,
  },
};
