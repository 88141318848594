import { Entity } from '@/lib/realtime/RealtimeEntity';
import { realtimeQuery } from '@/lib/realtime/weak/realtimeFunctions';
import { EntityInterfaceMap } from '@/lib/realtime/weak/realtimeTypes';
import { WeakEntityRepositoryResponse } from '@/lib/realtime/weak/WeakEntityRepository';
import { Location } from '../../api/v1';

export default {
  namespaced: true,

  state: {
    // as now we have clocking settings on location level, locations would be used much inside non component files
    activeLocations: realtimeQuery(Entity.Location).empty(),
  },

  getters: {
    locationsWithClockInEnabled({ activeLocations }): Location[] {
      return activeLocations.data.filter((l) => l.enableClockIn);
    },

    clockInEnabled(_, getters): boolean {
      return !!getters.locationsWithClockInEnabled.length;
    },

    locationsWithClockInFromPersonalDevicesEnabled(_, getters): Location[] {
      return getters.locationsWithClockInEnabled.filter(
        (l) => l.allowClockInFromPersonalDevices,
      );
    },

    locationsWithClockInViaPortalEnabled(_, getters): Location[] {
      return getters.locationsWithClockInEnabled.filter(
        (l) => l.allowClockInFromPortals,
      );
    },
  },

  mutations: {
    SET_LOCATIONS(
      state,
      // @ts-ignore
      locationsResponse: WeakEntityRepositoryResponse<
        EntityInterfaceMap,
        typeof Entity.Employee
      >,
    ) {
      state.activeLocations = locationsResponse;
    },
    LOCATIONS_CLEAN_UP(state) {
      state.activeLocations = realtimeQuery(Entity.Location).empty();
    },
  },

  actions: {
    async fetchLocations({ commit }) {
      commit(
        'SET_LOCATIONS',
        realtimeQuery(Entity.Location).whereActive().fetch(),
      );
    },
  },
};
