
import SlidePanel from '@/components/interface/SlidePanel.vue';
import NavSection from '@/components/nav/common/NavSection.vue';
import { Icon } from '@/lib/enum/Icon';
import { NavItemInterface } from '@/lib/navigation/helpers';
import { PropType } from 'vue';

export default {
  name: 'DesktopMoreNav',
  components: { NavSection, SlidePanel },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    navItems: {
      type: Array as PropType<NavItemInterface[]>,
      required: true,
    },
    navClick: {
      type: Function as PropType<(item: NavItemInterface) => void>,
      required: true,
    },
  },
  data() {
    return {
      Icon,
    };
  },
  methods: {
    close() {
      this.$emit('input', false);
    },
  },
};
