import { AnyGetter, getFromObject } from '@/util/objectFunctions';

/**
 * Can be replaced by Array.prototype.flat() in the future (currently Stage 3)
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/flat
 * This version below doesn't accept a depth parameter - it's only designed to go one level deep
 */
export const flattenArray = <T extends unknown>(
  arr: Array<Array<T>>,
): Array<T> => arr.reduce((acc, val) => acc.concat(val), []);

export const chunkArray = <T extends unknown>(
  arr: Array<T>,
  maxLength: number,
): Array<Array<T>> =>
  arr.reduce((arr: T[][], curr: T) => {
    const [lastChunkedArray] = arr.slice(-1); // get last chunked array;
    // if there is no chunked arrays yet or the last one exceeded maxLength limit then create new one with current value
    if (!lastChunkedArray || lastChunkedArray.length >= maxLength) {
      arr.push([curr]);
    } else {
      arr[arr.length - 1].push(curr);
    }
    return arr;
  }, []);

/**
 * From an array of objects, given a single property name, finds the greatest value of that property
 */
export const greatestProp = <T extends object, K extends keyof T>(
  arr: Array<T>,
  key: K,
): T[K] =>
  arr.reduce(
    (greatest: T[K], obj) =>
      greatest === null || obj[key] > greatest ? obj[key] : greatest,
    null,
  );

/**
 * From an array of objects, given a single property name, finds the least (lowest) value of that property
 */
export const leastProp = <T extends object, K extends keyof T>(
  arr: Array<T>,
  key: K,
): T[K] =>
  arr.reduce(
    (least: T[K], obj) =>
      least === null || obj[key] < least ? obj[key] : least,
    null,
  );

/**
 * Sorts an array of objects by a given property. A descending sort can be triggered by prefixing the property name with
 * a dash. Based on Lodash's sortBy function.
 *
 * Possible future todo - support multiple properties (remember to update unit test!)
 */
type SortProp<T> =
  | Exclude<keyof T, symbol | number>
  | `-${Exclude<keyof T, symbol | number>}`
  | string;
export function sortBy<T>(
  arr: Array<T> | any[],
  prop: SortProp<T> | SortProp<T>[],
): Array<T> {
  if (!arr.length) {
    return arr;
  }

  if (prop.length === 0) {
    return [...arr];
  }
  let filterKeys = typeof prop === 'string' ? [prop] : prop;

  const reverseSort = filterKeys[0][0] === '-';
  filterKeys = filterKeys.map((p) => (p[0] === '-' ? p.substring(1) : p));

  const valuesAreString =
    typeof arr[0][
      Object.keys(arr[0]).find((key) => filterKeys.includes(key))
    ] === 'string';
  let comparator;

  if (valuesAreString) {
    // values are strings
    comparator = (a, b) => a.localeCompare(b);
  } else {
    // We'll assume values are numbers
    comparator = (a, b) => {
      if (a < b) return -1;
      if (a > b) return 1;
      return 0;
    };
  }

  const multiplier = reverseSort ? -1 : 1;

  // Check which of the props keys exists in the object
  return [...arr].sort(
    (a, b) =>
      comparator(
        a[Object.keys(a).find((key) => filterKeys.includes(key))],
        b[Object.keys(b).find((key) => filterKeys.includes(key))],
      ) * multiplier,
  );
}

export const sortNestedObject = (prop, arr) => {
  prop = prop.split('.');
  const len = prop.length;

  const sortedArray = arr.slice();

  sortedArray.sort((a, b) => {
    let i = 0;
    while (i < len) {
      if (!a[prop[i]] || !b[prop[i]]) {
        return -1;
      }
      a = a[prop[i]];
      b = b[prop[i]];
      i += 1;
    }
    if (a < b) {
      return -1;
    }
    if (a > b) {
      return 1;
    }
    return 0;
  });
  return sortedArray;
};

export const arrayLengthSort = (arrayA: Array<any>, arrayB: Array<any>) =>
  arrayA.length - arrayB.length;

export const range = (start, end, step = 1) =>
  Array((end - start) / step + 1)
    .fill(1)
    .map((v, i) => start + i * step);

export const addUpNestedArrays = (array): number =>
  array.reduce((acc, arr) => {
    acc += arr.length;
    return acc;
  }, 0);

export const toIdName = (value: string, index: number) => ({
  id: index,
  name: value,
});

export const filterByCharacter = (
  value: string,
  character: string,
  startsAt: number,
  remove: boolean = true,
) => {
  if (remove) {
    return value.charAt(startsAt) !== character;
  }
  return value.charAt(startsAt) === character;
};

export const addUpNumbers = (numbersArray: number[]): number =>
  numbersArray.reduce((acc, number) => {
    acc += number;
    return acc;
  }, 0);

export const groupBy = <
  T extends object,
  K extends keyof T & string,
  V extends T[K] & string,
>(
  prop: K,
  array: T[],
): Record<V, T[]> =>
  array.reduce((acc, obj) => {
    const identifier = obj[prop] as V;
    const record = acc[identifier] ?? [];
    record.push(obj);
    return {
      ...acc,
      [identifier]: record,
    };
  }, {} as Record<V, T[]>);

/**
 * Takes an array of objects with specified start and end properties
 * and merges them if start of next is end value of previous
 * @param entries array of objects
 * @param startProp start property of this object
 * @param endProp end property of this object
 */
export const mergeRangeEntries = <
  S extends string,
  E extends string,
  T extends Record<S | E, unknown>,
>(
  entries: T[],
  startProp: S,
  endProp: E,
): T[] => {
  const grouped: T[] = [];
  for (let i = 0; i < entries.length; i += 1) {
    const index = grouped.length - 1;
    const entry = entries[i];

    if (grouped[index] && +grouped[index][endProp] === +entry[startProp]) {
      grouped[index][endProp] = entry[endProp];
    } else {
      grouped.push({ ...entry });
    }
  }
  return grouped;
};

/**
 * Takes an array of objects of the same type and a getter
 * Returns a unique array of objects with the defined property
 */
export const uniqueObjectsByGetter = <T extends object>(
  array: T[],
  getter: AnyGetter<T>,
): T[] =>
  array.reduce((acc: Array<T>, obj: T) => {
    if (
      !acc.find((o) => getFromObject(o, getter) === getFromObject(obj, getter))
    ) {
      acc.push(obj);
    }
    return acc;
  }, [] as Array<T>);

/**
 * Can be replaced by Array.prototype.findLast() in the future
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findLast
 */
export const findLast = <T>(
  array: T[],
  callbackFn: (e: T, i: number, arr: T[]) => unknown,
): T | undefined => {
  for (let i = array.length - 1; i >= 0; i -= 1) {
    if (callbackFn(array[i], i, array)) {
      return array[i];
    }
  }
  return undefined;
};

export const arraysContainSameValues = <T extends unknown>(
  arr1: T[],
  arr2: T[],
): boolean =>
  arr1.every((v) => arr2.includes(v)) && arr2.every((v) => arr1.includes(v));
