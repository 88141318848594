
import CloseButton from '@/components/buttons/dedicated/CloseButton.vue';
import EmployeeAvatar from '@/components/employee/EmployeeAvatar.vue';
import BubbleIcon from '@/components/interface/BubbleIcon.vue';
import { Icon } from '@/lib/enum/Icon';
import { viewport } from '@/util/windowFunctions';
import { PropType } from 'vue';
import { TranslateResult } from 'vue-i18n';
import { Employee } from '../../../../../api/v1';

export default {
  name: 'ShiftDialogHeader',
  components: {
    CloseButton,
    EmployeeAvatar,
    BubbleIcon,
  },
  props: {
    title: {
      type: String as PropType<string | TranslateResult>,
      required: true,
    },
    subHeading: {
      type: String as PropType<string | TranslateResult>,
      default: '',
    },
    invertHeading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String as () => Icon,
      default: Icon.User,
    },
    employee: {
      type: Object as PropType<Employee | undefined | null>,
      default: null,
    },
  },

  data() {
    return {
      Icon,
      viewport,
    };
  },
};
