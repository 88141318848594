/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CreateDigiticketsConnectionData
 */
export interface CreateDigiticketsConnectionData {
    /**
     * 
     * @type {string}
     * @memberof CreateDigiticketsConnectionData
     */
    apiKey: string;
    /**
     * 
     * @type {boolean}
     * @memberof CreateDigiticketsConnectionData
     */
    isDefault?: boolean;
}

/**
 * Check if a given object implements the CreateDigiticketsConnectionData interface.
 */
export function instanceOfCreateDigiticketsConnectionData(value: object): value is CreateDigiticketsConnectionData {
    if (!('apiKey' in value) || value['apiKey'] === undefined) return false;
    return true;
}

export function CreateDigiticketsConnectionDataFromJSON(json: any): CreateDigiticketsConnectionData {
    return CreateDigiticketsConnectionDataFromJSONTyped(json, false);
}

export function CreateDigiticketsConnectionDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDigiticketsConnectionData {
    if (json == null) {
        return json;
    }
    return {
        
        'apiKey': json['apiKey'],
        'isDefault': json['isDefault'] == null ? undefined : json['isDefault'],
    };
}

  export function CreateDigiticketsConnectionDataToJSON(json: any): CreateDigiticketsConnectionData {
      return CreateDigiticketsConnectionDataToJSONTyped(json, false);
  }

  export function CreateDigiticketsConnectionDataToJSONTyped(value?: CreateDigiticketsConnectionData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'apiKey': value['apiKey'],
        'isDefault': value['isDefault'],
    };
}

