import i18n from '@/i18n';
import { dateTimeFormats } from '@/lang/dateTimeFormats';

export const getNullableNumber = (value: unknown): number | null => {
  if (value == null) return null;

  const valueAsNumber: number = +value;
  return Number.isNaN(valueAsNumber) ? null : valueAsNumber;
};

export const getNumericValueRepresentation = (value: number | Date): string =>
  typeof value === 'number'
    ? `${value}`
    : i18n.d(value, dateTimeFormats.dayMonthYearHourMinute);

export const formatBytes = (
  bytes: number,
  format: string = null,
  decimals: number = 2,
): string => {
  const formats = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  /**
   * If a custom format has been supplied that isn't supported, then just default
   * to displaying bytes.
   */
  if (format && !formats.includes(format)) {
    return `${bytes}B`;
  }

  /**
   * Javascript doesn't let you use a custom log base, so instead we'll just
   * use the change of base formula to achieve the same.
   */
  const formatIndex = format
    ? formats.indexOf(format)
    : Math.floor(Math.log(bytes) / Math.log(1024));

  return (
    Number.parseFloat((bytes / 1024 ** formatIndex).toFixed(decimals)) +
    formats[formatIndex]
  );
};

export const isANumber = (input: unknown): boolean =>
  ['string', 'number'].includes(typeof input) && !Number.isNaN(Number(input));
