/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BulkDeleteWorkPatternOverridesData
 */
export interface BulkDeleteWorkPatternOverridesData {
    /**
     * The ids of models to delete
     * @type {Array<number>}
     * @memberof BulkDeleteWorkPatternOverridesData
     */
    ids: Array<number>;
}

/**
 * Check if a given object implements the BulkDeleteWorkPatternOverridesData interface.
 */
export function instanceOfBulkDeleteWorkPatternOverridesData(value: object): value is BulkDeleteWorkPatternOverridesData {
    if (!('ids' in value) || value['ids'] === undefined) return false;
    return true;
}

export function BulkDeleteWorkPatternOverridesDataFromJSON(json: any): BulkDeleteWorkPatternOverridesData {
    return BulkDeleteWorkPatternOverridesDataFromJSONTyped(json, false);
}

export function BulkDeleteWorkPatternOverridesDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): BulkDeleteWorkPatternOverridesData {
    if (json == null) {
        return json;
    }
    return {
        
        'ids': json['ids'],
    };
}

  export function BulkDeleteWorkPatternOverridesDataToJSON(json: any): BulkDeleteWorkPatternOverridesData {
      return BulkDeleteWorkPatternOverridesDataToJSONTyped(json, false);
  }

  export function BulkDeleteWorkPatternOverridesDataToJSONTyped(value?: BulkDeleteWorkPatternOverridesData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ids': value['ids'],
    };
}

