/* tslint:disable */
/* eslint-disable */
/**
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ShiftiePlainDate } from './ShiftiePlainDate';
import {
    ShiftiePlainDateFromJSON,
    ShiftiePlainDateFromJSONTyped,
    ShiftiePlainDateToJSON,
    ShiftiePlainDateToJSONTyped,
} from './ShiftiePlainDate';

/**
 * 
 * @export
 * @interface EmployeeLeaveRequestsReport
 */
export interface EmployeeLeaveRequestsReport {
    /**
     * 
     * @type {string}
     * @memberof EmployeeLeaveRequestsReport
     */
    reference: string | null;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLeaveRequestsReport
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLeaveRequestsReport
     */
    payrollId: string | null;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof EmployeeLeaveRequestsReport
     */
    startDate: ShiftiePlainDate;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeLeaveRequestsReport
     */
    startsMidday: boolean;
    /**
     * 
     * @type {ShiftiePlainDate}
     * @memberof EmployeeLeaveRequestsReport
     */
    endDate: ShiftiePlainDate;
    /**
     * 
     * @type {boolean}
     * @memberof EmployeeLeaveRequestsReport
     */
    endsMidday: boolean;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLeaveRequestsReport
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLeaveRequestsReport
     */
    leaveTypeName: string;
    /**
     * 
     * @type {string}
     * @memberof EmployeeLeaveRequestsReport
     */
    leaveTypeColour: string;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLeaveRequestsReport
     */
    allowanceUsedInHours: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLeaveRequestsReport
     */
    allowanceUsedInDays: number;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLeaveRequestsReport
     */
    paidHours: number | null;
    /**
     * 
     * @type {number}
     * @memberof EmployeeLeaveRequestsReport
     */
    paidDays: number | null;
    /**
     * 
     * @type {Array<number>}
     * @memberof EmployeeLeaveRequestsReport
     */
    assignedLocationIds: Array<number>;
}

/**
 * Check if a given object implements the EmployeeLeaveRequestsReport interface.
 */
export function instanceOfEmployeeLeaveRequestsReport(value: object): value is EmployeeLeaveRequestsReport {
    if (!('reference' in value) || value['reference'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('payrollId' in value) || value['payrollId'] === undefined) return false;
    if (!('startDate' in value) || value['startDate'] === undefined) return false;
    if (!('startsMidday' in value) || value['startsMidday'] === undefined) return false;
    if (!('endDate' in value) || value['endDate'] === undefined) return false;
    if (!('endsMidday' in value) || value['endsMidday'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('leaveTypeName' in value) || value['leaveTypeName'] === undefined) return false;
    if (!('leaveTypeColour' in value) || value['leaveTypeColour'] === undefined) return false;
    if (!('allowanceUsedInHours' in value) || value['allowanceUsedInHours'] === undefined) return false;
    if (!('allowanceUsedInDays' in value) || value['allowanceUsedInDays'] === undefined) return false;
    if (!('paidHours' in value) || value['paidHours'] === undefined) return false;
    if (!('paidDays' in value) || value['paidDays'] === undefined) return false;
    if (!('assignedLocationIds' in value) || value['assignedLocationIds'] === undefined) return false;
    return true;
}

export function EmployeeLeaveRequestsReportFromJSON(json: any): EmployeeLeaveRequestsReport {
    return EmployeeLeaveRequestsReportFromJSONTyped(json, false);
}

export function EmployeeLeaveRequestsReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeLeaveRequestsReport {
    if (json == null) {
        return json;
    }
    return {
        
        'reference': json['reference'],
        'name': json['name'],
        'payrollId': json['payrollId'],
        'startDate': ShiftiePlainDateFromJSON(json['startDate']),
        'startsMidday': json['startsMidday'],
        'endDate': ShiftiePlainDateFromJSON(json['endDate']),
        'endsMidday': json['endsMidday'],
        'status': json['status'],
        'leaveTypeName': json['leaveTypeName'],
        'leaveTypeColour': json['leaveTypeColour'],
        'allowanceUsedInHours': json['allowanceUsedInHours'],
        'allowanceUsedInDays': json['allowanceUsedInDays'],
        'paidHours': json['paidHours'],
        'paidDays': json['paidDays'],
        'assignedLocationIds': json['assignedLocationIds'],
    };
}

  export function EmployeeLeaveRequestsReportToJSON(json: any): EmployeeLeaveRequestsReport {
      return EmployeeLeaveRequestsReportToJSONTyped(json, false);
  }

  export function EmployeeLeaveRequestsReportToJSONTyped(value?: EmployeeLeaveRequestsReport | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'reference': value['reference'],
        'name': value['name'],
        'payrollId': value['payrollId'],
        'startDate': ShiftiePlainDateToJSON(value['startDate']),
        'startsMidday': value['startsMidday'],
        'endDate': ShiftiePlainDateToJSON(value['endDate']),
        'endsMidday': value['endsMidday'],
        'status': value['status'],
        'leaveTypeName': value['leaveTypeName'],
        'leaveTypeColour': value['leaveTypeColour'],
        'allowanceUsedInHours': value['allowanceUsedInHours'],
        'allowanceUsedInDays': value['allowanceUsedInDays'],
        'paidHours': value['paidHours'],
        'paidDays': value['paidDays'],
        'assignedLocationIds': value['assignedLocationIds'],
    };
}

